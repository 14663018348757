import { Action, Module, Mutation } from 'vuex-module-decorators';
import WidgetBaseStore from '@/store/widget/WidgetBaseStore';
import GqlComposeQueryDefinitionParams from '@/utils/types/gql/GqlComposeQueryDefinitionParams';
import { Data } from '@/utils/types/WidgetData';
import EntityFilterToggle from '@/utils/types/entity-search/EntityFilterToggle';
import { EntitySearchType } from '@/utils/enums/EntitySearchType';
import EntityFilterList from '@/utils/types/entity-search/EntityFilterList';
import SelectedListParams from '@/utils/types/SelectedListParams';
import { deepGet, deepSearch, mergeDeep } from '@/utils/ObjectHelpers';
import { BasicTypes } from '@/utils/types/BasicTypes';
import WidgetBaseRepository from '@/repositories/widget/WidgetBaseRepository';
import GqlEntityFilterType from '@/utils/enums/gql/GqlEntityFilterType';
import GqlEntityOrderingType from '@/utils/enums/gql/GqlEntityOrderingType';
import { buildQueryDefinition } from '@/graphql/_Tools/GqlQueryDefinition';
import EntitySearchHelper from '@/utils/helpers/widgets/EntitySearchHelper';
import ClientStorage from '@/utils/ClientStore';
import * as StringHelper from '@/utils/helpers/StringHelper';
import { EntitySearchFilterType } from '@/utils/enums/EntitySearchFilterType';
import { GQL_ENTITY_SEARCH_ALIAS } from '@/utils/constants/Regex';
import parse from 'date-fns/parse';
import { format } from 'date-fns';
import RootState from '@/store/states/RootState';
import GraphqlQueryHelper from '@/utils/helpers/GraphqlQueryHelper';
import EntityFilterRange from '@/utils/types/entity-search/EntityFilterRange';
import Exhibitor from '@/models/graphql/Exhibitor';

/* eslint-disable @typescript-eslint/camelcase,no-underscore-dangle, prefer-const */
@Module({
  namespaced: true,
  stateFactory: true,
})
export default class EntitySearchWidgetStore extends WidgetBaseStore {
  private filter: Data = {};

  private gqlCountConfigs: GqlComposeQueryDefinitionParams | null = null;

  private gqlDataConfigs: GqlComposeQueryDefinitionParams | null = null;

  private elements: Data[] = [];

  private toggleConfigs: EntityFilterToggle[] = [];

  private listConfigs: EntityFilterList[] = [];

  private rangeConfigs: EntityFilterRange[] = [];

  private itemsPerPage = 20;

  private page = 1;

  private nbElements = 0;

  private filtersQuery: Record<string, { query: string; loaded: boolean }> = {};

  private filtersData: Record<string, Data[]> = {};

  private readonly repository = new WidgetBaseRepository();

  get paginatedItems(): Data[] {
    return this.elements;
  }

  get first(): number {
    return this.itemsPerPage;
  }

  get pageValue(): number {
    return this.page;
  }

  get nbItems(): number {
    return this.nbElements;
  }

  get appliedFilter(): object {
    return this.filter;
  }

  get timeZoneName(): string {
    return this.context.rootState.selectedTzName;
  }

  @Action
  setDataConfigs(): void {
    this.context.commit('setListConfigs');
    this.context.commit('setToggleConfigs');
    this.context.commit('setRangeConfigs');
    const args: Record<string, BasicTypes> = this.context.rootGetters['WidgetDispatcherStore/fetchMagicArgs'];
    const exhibitor: Exhibitor = this.context.rootGetters['ExhibitorStore/fetchAdminPanelExhibitor'];
    const { communityCode } = this.context.rootGetters;
    this.context.commit('constructFilter', {
      ...args,
      schemaCode: communityCode,
      exhibitor,
      timezone: this.timeZoneName,
    });
    const filterKeysToLoad: string[] = [];
    if (args && 'page' in args && args.page) {
      this.context.commit('setPage', parseInt(args.page as string, 10));
    }
    if (args && 'filters' in args && args.filters && Object.keys(args.filters).length > 0) {
      filterKeysToLoad.push(...Object.keys(args.filters));
    }
    if (this.widget && this.widget.subWidgets && this.widget.subWidgets.length > 0) {
      let queries = '';
      this.widget.subWidgets.forEach((sub) => {
        let {
          graphql,
          type,
          key,
          graphQlFilter,
          orderBy,
        } = JSON.parse(sub.payload || '{}');
        if (type && type.toLowerCase() === EntitySearchFilterType.DATE) {
          return;
        }
        if (type && type.toLowerCase() === EntitySearchFilterType.SESSION_DATE) {
          const sortBy = orderBy && orderBy !== 'default' ? `orderBy: [${orderBy}]` : '';
          const parsedGraphQlFilter = GraphqlQueryHelper.parseJSONToGraphQLFilter(graphQlFilter);
          // eslint-disable-next-line max-len
          graphql = `{ session(_cachable: true ${parsedGraphQlFilter} ${sortBy}) { _startDate(timezone: "${this.timeZoneName}") } }`;
        }
        if (type && graphql && graphql.trim().length > 0) {
          const matches = graphql.trim()
            .replace(/^\{/, '')
            .replace(/\}$/, '')
            .match(GQL_ENTITY_SEARCH_ALIAS);
          if (matches && matches.length === 4 && matches[3].length > 0) {
            if (!matches[3].includes('_cachable')) {
              matches[3] = GraphqlQueryHelper.appendArgumentToQuery(
                matches[3],
                '_cachable',
                'BooleanValue',
                !deepSearch(GraphqlQueryHelper.extractFilterFromQuery(matches[3]), '%authUser%'),
              );
            }
            const query = `${this.widgetStoreName}_${key}: ${matches[3].trim()}`;
            if (filterKeysToLoad.includes(key)) {
              queries = `${queries} ${query}`;
            }
            this.context.commit(
              'setFilterQueries',
              {
                key,
                query,
                loaded: filterKeysToLoad.includes(key),
              },
            );
          }
        }
      });
      if (queries) {
        this.context.commit(
          'WidgetDispatcherStore/setGqlQuery',
          {
            key: this.widgetStoreName,
            value: queries,
          },
          { root: true },
        );
      }
    }
    if (this.widget && this.payload && this.payload.cardType) {
      const {
        cardType,
        amount,
        orderBy,
        displayExtraProperties,
      } = JSON.parse(this.widget.payload || '{}');
      let properties: string[] = [];
      if (displayExtraProperties) {
        properties = displayExtraProperties
          ? displayExtraProperties.map((ext: { id: number; key: string }) => ext.key)
          : [];
      }

      const variables = { schemaCode: this.context.rootGetters.communityCode };
      let filterType = '';
      let orderByType = '';
      let operation = '';
      let fragmentName = '';
      let fragmentCountName = '';
      let magicArgs: Record<string, string> = {};
      switch (cardType) {
        case 'member':
          filterType = GqlEntityFilterType.COMMUNITY_USER_FILTER;
          orderByType = GqlEntityOrderingType.COMMUNITY_USER_ORDERING;
          fragmentName = 'communityUserCardFragment';
          fragmentCountName = 'communityUserCountFragment';
          operation = 'communityUser';
          break;
        case 'exhibitor':
          filterType = GqlEntityFilterType.EXHIBITOR_FILTER;
          orderByType = GqlEntityOrderingType.EXHIBITOR_ORDERING;
          fragmentName = 'exhibitorCardWithEditionFragment';
          fragmentCountName = 'exhibitorCountFragment';
          operation = 'exhibitor';
          break;
        case 'company':
          filterType = GqlEntityFilterType.EXHIBITOR_FILTER;
          orderByType = GqlEntityOrderingType.EXHIBITOR_ORDERING;
          fragmentName = 'exhibitorCardFragment';
          fragmentCountName = 'exhibitorCountFragment';
          operation = 'exhibitor';
          break;
        case 'session':
          filterType = GqlEntityFilterType.SESSION_FILTER;
          orderByType = GqlEntityOrderingType.SESSION_ORDERING;
          fragmentName = 'sessionCardFragment';
          fragmentCountName = 'sessionCountFragment';
          operation = 'session';
          break;
        case 'product':
          filterType = GqlEntityFilterType.PRODUCT_FILTER;
          orderByType = GqlEntityOrderingType.PRODUCT_ORDERING;
          fragmentName = 'productCardFragment';
          fragmentCountName = 'productCountFragment';
          operation = 'product';
          break;
        case 'largeProduct':
          filterType = GqlEntityFilterType.LARGE_PRODUCT_FILTER;
          orderByType = GqlEntityOrderingType.LARGE_PRODUCT_ORDERING;
          fragmentName = 'largeProductCardFragment';
          fragmentCountName = 'largeProductCountFragment';
          operation = 'largeProduct';
          break;
        case 'boatProduct':
          filterType = GqlEntityFilterType.LARGE_PRODUCT_FILTER;
          orderByType = GqlEntityOrderingType.LARGE_PRODUCT_ORDERING;
          fragmentName = properties && properties.length
            ? 'largeProductBoatCardWithExtraPropertiesFragment' : 'largeProductBoatCardFragment';
          fragmentCountName = 'largeProductCountFragment';
          operation = 'largeProduct';
          break;
        case 'deal':
          filterType = GqlEntityFilterType.DEAL_FILTER;
          orderByType = GqlEntityOrderingType.DEAL_ORDERING;
          fragmentName = 'dealCardFragment';
          fragmentCountName = 'dealCountFragment';
          operation = 'deal';
          break;
        case 'speaker':
          filterType = GqlEntityFilterType.SPEAKER_FILTER;
          orderByType = GqlEntityOrderingType.SPEAKER_ORDERING;
          fragmentName = 'speakerCardFragment';
          fragmentCountName = 'speakerCountFragment';
          operation = 'speaker';
          break;
        case 'events':
          filterType = GqlEntityFilterType.SUB_EDITION_FILTER;
          orderByType = GqlEntityOrderingType.SUB_EDITION_ORDERING;
          fragmentName = 'subEditionCardFragment';
          fragmentCountName = 'subEditionCountFragment';
          operation = 'subEdition';
          break;
        case 'article':
          filterType = GqlEntityFilterType.ARTICLE_FILTER;
          orderByType = GqlEntityOrderingType.ARTICLE_ORDERING;
          fragmentName = 'articleCardFragment';
          fragmentCountName = 'articleCountFragment';
          operation = 'article';
          break;
        case 'topic':
          filterType = GqlEntityFilterType.TOPIC_FILTER;
          orderByType = GqlEntityOrderingType.TOPIC_ORDERING;
          fragmentName = 'topicCardFragment';
          fragmentCountName = 'topicCountFragment';
          operation = 'topic';
          break;
        case 'exhibitorVideo':
          filterType = GqlEntityFilterType.EXHIBITOR_VIDEO_FILTER;
          orderByType = GqlEntityOrderingType.EXHIBITOR_VIDEO_ORDERING;
          fragmentName = 'exhibitorVideoBaseFragment';
          fragmentCountName = 'exhibitorVideoCountFragment';
          operation = 'exhibitorVideo';
          break;
        case 'salesPackage':
        case 'package':
          filterType = GqlEntityFilterType.SALES_PACKAGE_FILTER;
          orderByType = GqlEntityOrderingType.SALES_PACKAGE_ORDERING;
          fragmentName = 'salesPackageCardFragment';
          fragmentCountName = 'salesPackageCountFragment';
          operation = 'salesPackage';
          if ('companyId' in args && args.companyId) {
            magicArgs = { filter: `(exhibitorUid: "${args.companyId}")` };
          }
          break;
        case 'crmUser':
          filterType = GqlEntityFilterType.COMMUNITY_USER_FILTER;
          orderByType = GqlEntityOrderingType.COMMUNITY_USER_ORDERING;
          fragmentName = 'communityCrmUserCardFragment';
          fragmentCountName = 'communityUserCountFragment';
          operation = 'communityUser';
          if ('companyId' in args && args.companyId) {
            magicArgs = {
              companyUid: `${args.companyId || ''}`,
            };
          }
          break;
        default:
          break;
      }
      let first = {};
      let offset = {};
      if (amount && amount > 0) {
        this.context.commit('setItemsPerPage', parseInt(amount, 10));
        first = { first: parseInt(amount, 10) };
        offset = { offset: (this.page - 1) * this.itemsPerPage };
      }
      let order = {};
      if (orderBy
        && orderBy.length > 0
        && !orderBy.includes('default')) {
        order = {
          orderBy: {
            value: orderBy.split(','),
            type: orderByType,
          },
        };
      }
      if (args
        && 'sortBy' in args
        && args.sortBy) {
        order = {
          orderBy: {
            value: [args.sortBy],
            type: orderByType,
          },
        };
      }

      if (properties && properties.length) {
        this.context.commit(
          'WidgetDispatcherStore/setMagicArgs',
          { extraProperties: properties },
          { root: true },
        );
      }

      const gqlDefinition = buildQueryDefinition({
        cacheable: true,
        filter: {
          value: mergeDeep(variables, this.filter),
          type: filterType,
        },
        magicArgs,
        ...order,
        ...first,
        ...offset,
      });

      const gqlCountDefinition = buildQueryDefinition({
        cacheable: false,
        filter: {
          value: mergeDeep(variables, this.filter),
          type: filterType,
        },
      });

      const configs = [{
        gqlDefinition,
        operation,
        fragmentName,
        alias: `${this.widgetStoreName}_data`,
      },
      {
        gqlDefinition: gqlCountDefinition,
        operation,
        fragmentName: fragmentCountName,
        alias: `${this.widgetStoreName}_count`,
      }];

      this.context.commit('setGqlQueryConfig', configs);
      this.context.commit('setGqlDataConfig', {
        gqlDefinition,
        operation,
        fragmentName,
        alias: `${this.widgetStoreName}_data`,
      });
      this.context.commit('setGqlCountConfig', {
        gqlDefinition: gqlCountDefinition,
        operation,
        fragmentName: fragmentCountName,
        alias: `${this.widgetStoreName}_count`,
      });
      if (!(ClientStorage.getItem('entity-search-view')
        && ClientStorage.getItem('entity-search-view') === 'CALENDAR'
        && cardType === 'session')) {
        this.context.commit(
          'WidgetDispatcherStore/setGqlQueryConfigs',
          {
            key: this.widgetStoreName,
            values: configs,
          },
          { root: true },
        );
      }
    }
  }

  @Action
  loadPaginatedData(payload: { page: number; queries: object }): Promise<void> {
    this.context.commit('load', true);
    const args: Record<string, BasicTypes> = this.context.rootGetters['WidgetDispatcherStore/fetchMagicArgs'];
    const exhibitor: Exhibitor = this.context.rootGetters['ExhibitorStore/fetchAdminPanelExhibitor'];
    const { communityCode } = this.context.rootGetters;
    this.context.commit('setPage', payload.page);
    delete args.filters;
    delete args.search;
    delete args.toggles;
    delete args.ranges;
    delete args.dates;
    this.context.commit('constructFilter', {
      ...args,
      ...payload.queries,
      schemaCode: communityCode,
      exhibitor,
      timezone: this.timeZoneName,
    });
    if (this.gqlDataConfigs && this.gqlCountConfigs) {
      return this.repository.load({
        params: {
          operationName: 'LoadPaginatedData',
          definitions: [this.gqlDataConfigs, ...(payload.page === 1 ? [this.gqlCountConfigs] : [])],
          magicArgs: args,
        },
        queries: '',
      })
        .then((response) => {
          Object.keys(response)
            .forEach((k) => {
              this.context.commit(
                'setData',
                {
                  key: k,
                  values: response[k],
                  rootState: this.context.rootState,
                },
              );
            });
        })
        .then(() => {
          this.context.commit('load', false);
        });
    }
    this.context.commit('load', false);
    return Promise.resolve();
  }

  @Action
  loadFilterData(code: string): void {
    if (Object.keys(this.filtersQuery)
      .includes(code)
      && !this.filtersQuery[code].loaded) {
      const args: Record<string, BasicTypes> = this.context.rootGetters['WidgetDispatcherStore/fetchMagicArgs'];
      this.repository.load({
        params: {
          operationName: `Load${StringHelper.snakeCaseToPascalCase(code)}FilterData`,
          definitions: [],
          magicArgs: args,
        },
        queries: this.filtersQuery[code].query,
      })
        .then((response) => {
          Object.keys(response)
            .forEach((k) => {
              this.context.commit(
                'setData',
                {
                  key: k,
                  values: response[k],
                  rootState: this.context.rootState,
                },
              );
            });
        });
    }
  }

  @Mutation
  setGqlCountConfig(config: GqlComposeQueryDefinitionParams): void {
    this.gqlCountConfigs = config;
  }

  @Mutation
  setGqlDataConfig(config: GqlComposeQueryDefinitionParams): void {
    this.gqlDataConfigs = config;
  }

  @Mutation
  constructFilter(payload: Data): void {
    const {
      search,
      filters,
      toggles,
      ranges,
      schemaCode,
      exhibitor,
      timezone,
    } = payload;
    this.filter = { schemaCode };
    if (this.payload && this.payload.filter) {
      Object.assign(this.filter, JSON.parse(this.payload.filter as string || '{}'));
    }
    if (this.payload && this.payload.schemaCode && this.payload.cardType === 'exhibitor') {
      Object.assign(this.filter, {
        editionMappings: {
          editionExhibitor: { schemaCode: this.payload.schemaCode },
        },
      });
    }
    if (this.payload && ['crmUser'].includes(this.payload.cardType as string)) {
      Object.assign(this.filter, { _isNotBlocked: '%authUser%' });
    }

    const e = exhibitor as Exhibitor;
    if (this.payload && ['salesPackage', 'package'].includes(this.payload.cardType as string)
      && e && e._maxSalesPackageRank && e._maxSalesPackageRank >= 0) {
      Object.assign(this.filter, { OR: [{ rank_gt: e._maxSalesPackageRank }, { rank: null }, { rank: 0 }] });
      Object.assign(this.filter, { parentSalesPackage: null });
    }

    const AND = [];
    if ('AND' in this.filter && this.filter.AND && (this.filter.AND as Array<Data>).length > 0) {
      AND.push(...this.filter.AND as []);
    }

    const OR = [];
    if ('OR' in this.filter && this.filter.OR && (this.filter.OR as Array<Data>).length > 0) {
      OR.push(...this.filter.OR as []);
    }

    if (search && (search as string).length > 0) {
      Object.assign(this.filter, { entityFts: search });
    } else {
      delete this.filter.entityFts;
    }

    if (filters && Object.keys(filters).length > 0) {
      AND.push(...EntitySearchHelper.constructListFilters(
        this.listConfigs,
        filters as Data,
        {
          authUser: payload.authUser,
          schemaCode: payload.schemaCode,
          locale: payload.locale,
        },
      ));
      const sessionDateFilter = EntitySearchHelper.constructSessionDateFilter(
        this.listConfigs,
        filters as Data,
        timezone as string,
      );
      if (sessionDateFilter) {
        Object.assign(this.filter, sessionDateFilter);
      }
      OR.push(...EntitySearchHelper.constructDateFilter(
        this.listConfigs,
        filters as Data,
      ));
    } else {
      delete this.filter.filters;
    }

    if (toggles && Object.keys(toggles).length > 0) {
      AND.push(...EntitySearchHelper.constructToggleFilters(
        this.toggleConfigs,
        toggles as Data,
        {
          authUser: payload.authUser,
          schemaCode: payload.schemaCode,
          locale: payload.locale,
        },
      ));
    } else {
      delete this.filter.toggles;
    }

    if (ranges && Object.keys(ranges).length > 0) {
      AND.push(...EntitySearchHelper.constructRangeFilters(
        this.rangeConfigs,
        ranges as Data,
      ));
    } else {
      delete this.filter.ranges;
    }

    if (AND.length > 0) {
      Object.assign(this.filter, { AND });
    }
    if (OR.length > 0) {
      Object.assign(this.filter, { OR });
    }
    if (this.gqlCountConfigs && this.gqlCountConfigs.gqlDefinition.filter) {
      this.gqlCountConfigs.gqlDefinition.cacheable = false;
      this.gqlCountConfigs.gqlDefinition.filter.value = this.filter;
    }
    if (this.gqlDataConfigs && this.gqlDataConfigs.gqlDefinition.filter) {
      this.gqlDataConfigs.gqlDefinition.cacheable = true;
      this.gqlDataConfigs.gqlDefinition.filter.value = this.filter;
    }
  }

  @Mutation
  mapper(): void {
    const data = this.data.length > 0 ? { data: this.data } : {};
    this.mapping = {
      ...this.payload,
      ...data,
      listConfigs: this.listConfigs,
      toggleConfigs: this.toggleConfigs,
      rangeConfigs: this.rangeConfigs,
    };
  }

  @Mutation
  setData(data: {
    values: Array<Data>;
    key: string;
    rootState: RootState;
  }): void {
    const keys = data.key.split('_');
    if (keys.length === 2) {
      if (keys[1] === 'data') {
        this.elements = data.values;
      } else if (keys[1] === 'count') {
        this.nbElements = data.values[0].count as number;
      } else {
        if (!Object.keys(this.filtersData)
          .includes(keys[1])) {
          Object.assign(this.filtersData, { [keys[1]]: [] });
        }
        this.filtersQuery[keys[1]].loaded = true;
        this.filtersData[keys[1]] = data.values;
        const foundListConfig = this.listConfigs.find((lc) => lc.code === keys[1]);
        if (foundListConfig) {
          foundListConfig.dataQuery = data.values.map<SelectedListParams>((d) => ({
            selected: false,
            id: Object.keys(d)
              .includes('_startDate') ? d._startDate.toString() : deepGet(d, foundListConfig.value),
            title: Object.keys(d)
              .includes('_startDate')
              ? format(
                parse(d._startDate.toString(), 'yyyy-MM-dd', new Date()),
                data.rootState && data.rootState.i18n ? `${data.rootState.i18n.t('app.date.entitySearchDateFormat')}` : 'PP',
                { locale: data.rootState.dateLocale },
              )
              : deepGet(d, foundListConfig.text),
          }));
        }
      }
    }
  }

  @Mutation
  private setListConfigs(): void {
    if (this.widget && this.widget.subWidgets) {
      this.listConfigs = this.widget.subWidgets.filter((sub) => {
        const { type } = JSON.parse(sub.payload || '{}');
        return type && [
          EntitySearchFilterType.LIST,
          EntitySearchFilterType.DATE,
          EntitySearchFilterType.SESSION_DATE,
        ].includes(type.toLowerCase());
      })
        .map((sub) => {
          const payload = JSON.parse(sub.payload || '{}');
          let data: SelectedListParams[] = [];
          if (payload.type.toLowerCase() === EntitySearchFilterType.DATE) {
            data = [{
              selected: false,
              id: 'past-week',
              title: 'filter-component.date-filter.past-week',
            },
            {
              selected: false,
              id: 'past-month',
              title: 'filter-component.date-filter.past-month',
            },
            {
              selected: false,
              id: 'today',
              title: 'filter-component.date-filter.today',
            },
            {
              selected: false,
              id: 'this-week',
              title: 'filter-component.date-filter.this-week',
            },
            {
              selected: false,
              id: 'next-week',
              title: 'filter-component.date-filter.next-week',
            },
            {
              selected: false,
              id: 'this-month',
              title: 'filter-component.date-filter.this-month',
            },
            {
              selected: false,
              id: 'next-month',
              title: 'filter-component.date-filter.next-month',
            }];
          }
          return {
            type: EntitySearchType.LIST,
            listType: payload.type.toLowerCase(),
            code: payload.key,
            text: payload.text,
            value: payload.value,
            buttonLabel: payload.label,
            filterQuery: payload.filterQuery,
            dataQuery: data,
            visible: true,
          };
        });
    }
  }

  @Mutation
  private setToggleConfigs(): void {
    if (this.widget && this.widget.subWidgets) {
      this.toggleConfigs = this.widget.subWidgets.filter((sub) => {
        const { type } = JSON.parse(sub.payload || '{}');
        return type && type.toLowerCase() === EntitySearchFilterType.TOGGLE;
      })
        .map((sub) => {
          const payload = JSON.parse(sub.payload || '{}');
          return {
            type: EntitySearchType.TOGGLE,
            code: payload.key,
            isSwitcher: payload.isSwitcher,
            isChecked: payload.isChecked,
            filterQuery: payload.filterQuery,
            buttonLabel: payload.label,
          } as EntityFilterToggle;
        });
    }
  }

  @Mutation
  private setRangeConfigs(): void {
    if (this.widget && this.widget.subWidgets) {
      this.rangeConfigs = this.widget.subWidgets.filter((sub) => {
        const { type } = JSON.parse(sub.payload || '{}');
        return type && type.toLowerCase() === EntitySearchFilterType.RANGE;
      })
        .map((sub) => {
          const payload = JSON.parse(sub.payload || '{}');
          return {
            type: EntitySearchType.RANGE,
            key: payload.key,
            field: payload.field,
            label: payload.label,
            entityType: payload.entityType,
          } as EntityFilterRange;
        });
    }
  }

  @Mutation
  private setItemsPerPage(val: number): void {
    this.itemsPerPage = val;
    if (this.gqlDataConfigs && this.gqlDataConfigs.gqlDefinition.first) {
      this.gqlDataConfigs.gqlDefinition.first.value = this.itemsPerPage;
    }
  }

  @Mutation
  private setPage(val: number): void {
    this.page = val;
    if (this.gqlDataConfigs && this.gqlDataConfigs.gqlDefinition.offset) {
      this.gqlDataConfigs.gqlDefinition.offset.value = (this.page - 1) * this.itemsPerPage;
    }
  }

  @Mutation
  private setFilterQueries(payload: { key: string; query: string; loaded: boolean }): void {
    if (!Object.keys(this.filtersQuery)
      .includes(payload.key)) {
      Object.assign(this.filtersQuery, { [payload.key]: {} });
    }
    this.filtersQuery[payload.key] = {
      query: payload.query,
      loaded: payload.loaded,
    };
  }

  @Mutation
  private setFiltersData(payload: { key: string; data: Data[] }): void {
    if (!Object.keys(this.filtersData)
      .includes(payload.key)) {
      Object.assign(this.filtersData, { [payload.key]: [] });
    }
    this.filtersData[payload.key] = payload.data;
  }
}
