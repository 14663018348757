import { Action, Module, Mutation } from 'vuex-module-decorators';
import WidgetBaseStore from '@/store/widget/WidgetBaseStore';
import GqlComposeQueryDefinitionParams from '@/utils/types/gql/GqlComposeQueryDefinitionParams';
import { Data } from '@/utils/types/WidgetData';
import EntityFilterToggle from '@/utils/types/entity-search/EntityFilterToggle';
import { EntitySearchType } from '@/utils/enums/EntitySearchType';
import EntityFilterList from '@/utils/types/entity-search/EntityFilterList';
import SelectedListParams from '@/utils/types/SelectedListParams';
import { deepGet, deepSearch, mergeDeep } from '@/utils/ObjectHelpers';
import { BasicTypes } from '@/utils/types/BasicTypes';
import WidgetBaseRepository from '@/repositories/widget/WidgetBaseRepository';
import { buildQueryDefinition } from '@/graphql/_Tools/GqlQueryDefinition';
import EntitySearchHelper from '@/utils/helpers/widgets/EntitySearchHelper';
import * as StringHelper from '@/utils/helpers/StringHelper';
import { EntitySearchFilterType } from '@/utils/enums/EntitySearchFilterType';
import { GQL_ENTITY_SEARCH_ALIAS } from '@/utils/constants/Regex';
import parse from 'date-fns/parse';
import RootState from '@/store/states/RootState';
import GraphqlQueryHelper from '@/utils/helpers/GraphqlQueryHelper';
import EntityFilterRange from '@/utils/types/entity-search/EntityFilterRange';
import GqlEntityFilterType from '@/utils/enums/gql/GqlEntityFilterType';
import GqlEntityOrderingType from '@/utils/enums/gql/GqlEntityOrderingType';
import ScheduleEvent from '@/models/graphql/ScheduleEvent';
import ProgramEntitySearchWidgetHelper, {
  GroupedByStartTimestamp,
} from '@/utils/helpers/widgets/ProgramEntitySearchWidgetHelper';
import Session from '@/models/graphql/Session';
import { format } from 'date-fns';

/* eslint-disable @typescript-eslint/camelcase,no-underscore-dangle, prefer-const */
@Module({
  namespaced: true,
  stateFactory: true,
})
export default class ProgramEntitySearchWidgetStore extends WidgetBaseStore {
  private filter: Data = {};

  private scheduleOfEventFilter: Data = {};

  private elements: {
    scheduleOfEvents: ScheduleEvent[];
    sessions: Session[];
  } = {
    sessions: [],
    scheduleOfEvents: [],
  };

  private gqlSessionDateConfigs: GqlComposeQueryDefinitionParams | null = null;

  private gqlSOEDateConfigs: GqlComposeQueryDefinitionParams | null = null;

  private gqlSessionDataConfigs: GqlComposeQueryDefinitionParams | null = null;

  private gqlSOEDataConfigs: GqlComposeQueryDefinitionParams | null = null;

  private toggleConfigs: EntityFilterToggle[] = [];

  private listConfigs: EntityFilterList[] = [];

  private rangeConfigs: EntityFilterRange[] = [];

  private selectedDate: string | null = null;

  private selectedSession: string | null = null;

  private dates: Array<string> = [];

  private filtersQuery: Record<string, { query: string; loaded: boolean }> = {};

  private filtersData: Record<string, Data[]> = {};

  private disableScheduleOfEvents = false;

  private readonly repository = new WidgetBaseRepository();

  get items(): Record<number, GroupedByStartTimestamp> {
    return ProgramEntitySearchWidgetHelper.groupToTimeline(
      this.elements.sessions,
      this.elements.scheduleOfEvents,
      this.context.rootState.selectedTzName,
      this.context.rootState.dateLocale,
    );
  }

  get timeZoneName(): string {
    return this.context.rootState.selectedTzName;
  }

  get startDates(): string[] {
    return this.dates;
  }

  get session(): string | null {
    return this.selectedSession;
  }

  @Action
  setDataConfigs(): void {
    this.context.commit('setListConfigs');
    this.context.commit('setToggleConfigs');
    this.context.commit('setRangeConfigs');
    const args: Record<string, BasicTypes> = this.context.rootGetters['WidgetDispatcherStore/fetchMagicArgs'];
    this.context.commit('constructFilter', {
      ...args,
      tz: this.context.rootState.selectedTzName,
      distinct: true,
    });
    const filterKeysToLoad: string[] = [];
    if (args && 'filters' in args && args.filters && Object.keys(args.filters).length > 0) {
      filterKeysToLoad.push(...Object.keys(args.filters));
    }
    if (this.widget && this.widget.subWidgets && this.widget.subWidgets.length > 0) {
      let queries = '';
      this.widget.subWidgets.forEach((sub) => {
        let {
          graphql,
          type,
          key,
          graphQlFilter,
          orderBy,
        } = JSON.parse(sub.payload || '{}');
        if (type && type.toLowerCase() === EntitySearchFilterType.DATE) {
          return;
        }
        if (type && type.toLowerCase() === EntitySearchFilterType.SESSION_DATE) {
          const sortBy = orderBy && orderBy !== 'default' ? `orderBy: [${orderBy}]` : '';
          const parsedGraphQlFilter = GraphqlQueryHelper.parseJSONToGraphQLFilter(graphQlFilter);
          // eslint-disable-next-line max-len
          graphql = `{ session(_cachable: true ${parsedGraphQlFilter} ${sortBy}) { _startDate(timezone: "${this.timeZoneName}") }}`;
        }
        if (type && graphql && graphql.trim().length > 0) {
          const matches = graphql.trim()
            .replace(/^\{/, '')
            .replace(/\}$/, '')
            .match(GQL_ENTITY_SEARCH_ALIAS);
          if (matches && matches.length === 4 && matches[3].length > 0) {
            if (!matches[3].includes('_cachable')) {
              matches[3] = GraphqlQueryHelper.appendArgumentToQuery(
                matches[3],
                '_cachable',
                'BooleanValue',
                !deepSearch(GraphqlQueryHelper.extractFilterFromQuery(matches[3]), '%authUser%'),
              );
            }
            const query = `${this.widgetStoreName}_${key}: ${matches[3].trim()}`;
            if (filterKeysToLoad.includes(key)) {
              queries = `${queries} ${query}`;
            }
            this.context.commit(
              'setFilterQueries',
              {
                key,
                query,
                loaded: filterKeysToLoad.includes(key),
              },
            );
          }
        }
      });
      if (queries) {
        this.context.commit(
          'WidgetDispatcherStore/setGqlQuery',
          {
            key: this.widgetStoreName,
            value: queries,
          },
          { root: true },
        );
      }
    }
    if (this.widget && this.payload) {
      const {
        categoryDomain,
        sessionFilter,
        scheduleEventFilter,
      } = this.payload;
      let { filter } = this;
      if (categoryDomain && (categoryDomain as string).trim().length > 0) {
        Object.assign(filter, { categories: { domain_in: (categoryDomain as string).split(',') } });
      }
      if (sessionFilter && (sessionFilter as string).trim().length > 0) {
        const obj = JSON.parse(sessionFilter as string || '{}');
        filter = mergeDeep(filter, obj);
      }
      if (scheduleEventFilter && (scheduleEventFilter as string).trim().length > 0) {
        this.context.commit('setScheduleEventFilter', JSON.parse(scheduleEventFilter as string || '{}'));
      }

      this.context.commit('setGqlSessionDateConfigs', filter);
      this.context.commit('setGqlSOEDateConfigs', {
        filter: this.scheduleOfEventFilter,
        search: args.search,
      });

      this.context.commit('setGqlSessionDataConfigs', {
        filter,
        tz: this.context.rootState.selectedTzName,
      });
      this.context.commit('setGqlSOEDataConfigs', {
        search: args.search,
        tz: this.context.rootState.selectedTzName,
        filter: this.scheduleOfEventFilter,
      });

      const definitions = [];
      if (this.gqlSessionDateConfigs) {
        definitions.push(this.gqlSessionDateConfigs);
      }
      if (this.gqlSOEDateConfigs && !this.disableScheduleOfEvents) {
        definitions.push(this.gqlSOEDateConfigs);
      }
      if (definitions.length > 0) {
        super.setDataConfigs(definitions);
      }
    }
  }

  @Action
  loadData(queries: object): Promise<void> {
    this.context.commit('load', true);
    const args: Record<string, BasicTypes> = this.context.rootGetters['WidgetDispatcherStore/fetchMagicArgs'];
    delete args.filters;
    delete args.search;
    delete args.toggles;
    delete args.ranges;
    delete args.dates;
    this.context.commit('constructFilter', {
      ...args,
      ...queries,
      tz: this.context.rootState.selectedTzName,
      distinct: false,
    });
    const definitions = [];
    if (this.gqlSessionDataConfigs) {
      definitions.push(this.gqlSessionDataConfigs);
    }
    if (this.gqlSOEDataConfigs && !this.disableScheduleOfEvents) {
      definitions.push(this.gqlSOEDataConfigs);
    }
    if (definitions.length > 0 && this.selectedDate) {
      return this.repository.load({
        params: {
          operationName: `LoadProgramDataFor${this.selectedDate?.replaceAll('-', '_')}`,
          definitions,
          magicArgs: {
            ...args,
            filter: this.payload && this.payload.categoryDomain
              ? `(filter: {domain_in: ${JSON.stringify((this.payload.categoryDomain as string).split(','))
                .replaceAll('\\', '')}})`
              : '',
          },
        },
        queries: '',
      })
        .then((response) => {
          Object.keys(response)
            .forEach((k) => {
              this.context.commit(
                'setData',
                {
                  key: k,
                  values: response[k],
                  rootState: this.context.rootState,
                },
              );
            });
        })
        .then(() => {
          this.context.commit('load', false);
        });
    }
    this.context.commit('load', false);
    return Promise.resolve();
  }

  @Action
  loadDates(queries: object): Promise<void> {
    this.context.commit('load', true);
    const args: Record<string, BasicTypes> = this.context.rootGetters['WidgetDispatcherStore/fetchMagicArgs'];
    delete args.filters;
    delete args.search;
    delete args.toggles;
    delete args.ranges;
    delete args.dates;
    this.context.commit('constructFilter', {
      ...args,
      ...queries,
      tz: this.context.rootState.selectedTzName,
      distinct: true,
    });
    const definitions = [];
    if (this.gqlSessionDateConfigs) {
      definitions.push(this.gqlSessionDateConfigs);
    }
    if (this.gqlSOEDateConfigs && !this.disableScheduleOfEvents) {
      definitions.push(this.gqlSOEDateConfigs);
    }
    if (definitions.length > 0) {
      return this.repository.load({
        params: {
          operationName: 'LoadProgramDates',
          definitions,
          magicArgs: {
            ...args,
            filter: this.payload && this.payload.categoryDomain
              ? `(filter: {domain_in: ${JSON.stringify((this.payload.categoryDomain as string).split(','))
                .replaceAll('\\', '')}})`
              : '',
          },
        },
        queries: '',
      })
        .then((response) => {
          Object.keys(response)
            .forEach((k) => {
              this.context.commit(
                'setData',
                {
                  key: k,
                  values: response[k],
                  rootState: this.context.rootState,
                },
              );
            });
        })
        .then(() => {
          this.context.commit('load', false);
        });
    }
    this.context.commit('load', false);
    return Promise.resolve();
  }

  @Action
  loadFilterData(code: string): void {
    if (Object.keys(this.filtersQuery)
      .includes(code)
      && !this.filtersQuery[code].loaded) {
      const args: Record<string, BasicTypes> = this.context.rootGetters['WidgetDispatcherStore/fetchMagicArgs'];
      this.repository.load({
        params: {
          operationName: `Load${StringHelper.snakeCaseToPascalCase(code)}FilterData`,
          definitions: [],
          magicArgs: args,
        },
        queries: this.filtersQuery[code].query,
      })
        .then((response) => {
          Object.keys(response)
            .forEach((k) => {
              this.context.commit(
                'setData',
                {
                  key: k,
                  values: response[k],
                  rootState: this.context.rootState,
                },
              );
            });
        });
    }
  }

  @Mutation
  setGqlSessionDateConfigs(filter: object): void {
    this.gqlSessionDateConfigs = {
      gqlDefinition: buildQueryDefinition({
        filter: {
          value: {
            ...filter,
            distinct: true,
          },
          type: GqlEntityFilterType.SESSION_FILTER,
        },
        orderBy: {
          value: ['startTimestamp_asc'],
          type: GqlEntityOrderingType.SESSION_ORDERING,
        },
      }),
      operation: 'session',
      fragmentName: 'sessionStartDateFragment',
      alias: `${this.widgetStoreName}_sessionDates`,
    };
  }

  @Mutation
  setGqlSessionDataConfigs(payload: { filter: object; tz: string }): void {
    this.gqlSessionDataConfigs = {
      gqlDefinition: buildQueryDefinition({
        filter: {
          value: {
            ...payload.filter,
            _inStartDate: {
              dates: [this.selectedDate],
              timezone: payload.tz,
            },
          },
          type: GqlEntityFilterType.SESSION_FILTER,
        },
        orderBy: {
          value: ['startTimestamp_asc'],
          type: GqlEntityOrderingType.SESSION_ORDERING,
        },
      }),
      operation: 'session',
      fragmentName: 'sessionProgramFragment',
      alias: `${this.widgetStoreName}_sessions`,
    };
  }

  @Mutation
  setGqlSOEDateConfigs(payload: { filter: object; search: string }): void {
    const filter = {
      type_in: ['link', 'noLink'],
      distinct: true,
      ...payload.filter,
    };
    if (payload.search) {
      Object.assign(filter, {
        OR: [
          { name_contains: payload.search },
          { description_contains: payload.search },
          { url_contains: payload.search },
          { urlLabel_contains: payload.search },
        ],
      });
    }
    this.gqlSOEDateConfigs = {
      gqlDefinition: buildQueryDefinition({
        filter: {
          value: filter,
          type: GqlEntityFilterType.SCHEDULE_EVENT_FILTER,
        },
        orderBy: {
          value: ['startTimestamp_asc'],
          type: GqlEntityOrderingType.SCHEDULE_EVENT_ORDERING,
        },
      }),
      operation: 'scheduleEvent',
      fragmentName: 'scheduleEventStartDateFragment',
      alias: `${this.widgetStoreName}_scheduleOfEventDates`,
    };
  }

  @Mutation
  setGqlSOEDataConfigs(payload: { filter: object; search: string; tz: string }): void {
    const filter = {
      type_in: ['link', 'noLink'],
      ...payload.filter,
    };
    if (payload.search) {
      Object.assign(filter, {
        OR: [
          { name_contains: payload.search },
          { description_contains: payload.search },
          { url_contains: payload.search },
          { urlLabel_contains: payload.search },
        ],
      });
    }
    this.gqlSOEDataConfigs = {
      gqlDefinition: buildQueryDefinition({
        filter: {
          value: {
            ...filter,
            _inStartDate: {
              dates: [this.selectedDate],
              timezone: payload.tz,
            },
          },
          type: GqlEntityFilterType.SCHEDULE_EVENT_FILTER,
        },
        orderBy: {
          value: ['startTimestamp_asc'],
          type: GqlEntityOrderingType.SCHEDULE_EVENT_ORDERING,
        },
      }),
      operation: 'scheduleEvent',
      fragmentName: 'scheduleEventProgramFragment',
      alias: `${this.widgetStoreName}_scheduleOfEvents`,
    };
  }

  @Mutation
  constructFilter(payload: Data): void {
    const {
      search,
      filters,
      toggles,
      ranges,
      selectedDate,
      tz,
      distinct,
    } = payload;
    if (this.dates.length > 0) {
      if (selectedDate && this.dates.includes(selectedDate as string)) {
        this.selectedDate = selectedDate as string;
      } else {
        // eslint-disable-next-line prefer-destructuring
        this.selectedDate = this.dates[0];
      }
    }
    this.filter = {};
    this.disableScheduleOfEvents = false;
    if (this.payload && this.payload.categoryDomain && (this.payload.categoryDomain as string).trim().length > 0) {
      Object.assign(this.filter, { categories: { domain_in: (this.payload.categoryDomain as string).split(',') } });
    }
    if (this.payload && this.payload.sessionFilter) {
      const obj = JSON.parse((this.payload.sessionFilter as string) || '{}');
      this.filter = mergeDeep(this.filter, obj);
    }
    const AND = [];
    if ('AND' in this.filter && this.filter.AND && (this.filter.AND as Array<Data>).length > 0) {
      AND.push(...this.filter.AND as []);
    }

    const OR = [];
    if ('OR' in this.filter && this.filter.OR && (this.filter.OR as Array<Data>).length > 0) {
      OR.push(...this.filter.OR as []);
    }

    if (search && (search as string).length > 0) {
      Object.assign(this.filter, { entityFts: search });
    } else {
      delete this.filter.entityFts;
    }

    if (filters && Object.keys(filters).length > 0) {
      this.disableScheduleOfEvents = true;
      AND.push(...EntitySearchHelper.constructListFilters(
        this.listConfigs,
        filters as Data,
        {
          authUser: payload.authUser,
          schemaCode: payload.schemaCode,
          locale: payload.locale,
        },
      ));
      const sessionDateFilter = EntitySearchHelper.constructSessionDateFilter(
        this.listConfigs,
        filters as Data,
        tz as string,
      );
      if (sessionDateFilter) {
        Object.assign(this.filter, sessionDateFilter);
      }
      OR.push(...EntitySearchHelper.constructDateFilter(
        this.listConfigs,
        filters as Data,
      ));
    } else {
      delete this.filter.filters;
    }

    if (toggles && Object.keys(toggles).length > 0) {
      this.disableScheduleOfEvents = true;
      AND.push(...EntitySearchHelper.constructToggleFilters(
        this.toggleConfigs,
        toggles as Data,
        {
          authUser: payload.authUser,
          schemaCode: payload.schemaCode,
          locale: payload.locale,
        },
      ));
    } else {
      delete this.filter.toggles;
    }

    if (ranges && Object.keys(ranges).length > 0) {
      this.disableScheduleOfEvents = true;
      AND.push(...EntitySearchHelper.constructRangeFilters(
        this.rangeConfigs,
        ranges as Data,
      ));
    } else {
      delete this.filter.ranges;
    }

    if (AND.length > 0) {
      Object.assign(this.filter, { AND });
    }
    if (OR.length > 0) {
      Object.assign(this.filter, { OR });
    }
    if (this.gqlSessionDateConfigs && this.gqlSessionDateConfigs.gqlDefinition.filter) {
      this.gqlSessionDateConfigs.gqlDefinition.filter.value = {
        ...this.filter,
        ...(distinct ? { distinct } : {}),
      };
    }
    if (this.gqlSessionDataConfigs && this.gqlSessionDataConfigs.gqlDefinition.filter) {
      this.gqlSessionDataConfigs.gqlDefinition.filter.value = {
        ...this.filter,
        _inStartDate: {
          dates: [this.selectedDate],
          timezone: tz,
        },
      };
    }
    const sOEFilter = {
      type_in: ['link', 'noLink'],
      ...this.scheduleOfEventFilter,
    };
    if (this.filter && this.filter.entityFts) {
      Object.assign(sOEFilter, {
        OR: [
          { name_contains: this.filter.entityFts },
          { description_contains: this.filter.entityFts },
          { url_contains: this.filter.entityFts },
          { urlLabel_contains: this.filter.entityFts },
        ],
      });
    }
    if (this.gqlSOEDateConfigs && this.gqlSOEDateConfigs.gqlDefinition.filter) {
      this.gqlSOEDateConfigs.gqlDefinition.filter.value = { ...sOEFilter, ...(distinct ? { distinct } : {}) };
    }
    if (this.gqlSOEDataConfigs && this.gqlSOEDataConfigs.gqlDefinition.filter) {
      this.gqlSOEDataConfigs.gqlDefinition.filter.value = {
        ...sOEFilter,
        _inStartDate: {
          dates: [this.selectedDate],
          timezone: tz,
        },
      };
    }
  }

  @Mutation
  mapper(): void {
    const data = this.data.length > 0 ? { data: this.data } : {};
    this.mapping = {
      ...this.payload,
      ...data,
      startDates: this.dates,
      listConfigs: this.listConfigs,
      toggleConfigs: this.toggleConfigs,
      rangeConfigs: this.rangeConfigs,
    };
  }

  @Mutation
  setData(data: {
    values: Array<Data>;
    key: string;
    rootState: RootState;
  }): void {
    const keys = data.key.split('_');
    if (keys.length === 2) {
      if (data.key.includes('Dates')) {
        if (keys[1] === 'sessionDates') {
          this.dates = Array.from(
            new Set([...this.dates, ...data.values.map((d) => d.startDate as string)]),
          );
        } else if (keys[1] === 'scheduleOfEventDates') {
          this.dates = Array.from(
            new Set([...this.dates, ...data.values.map((d) => d.startDate as string)]),
          );
        }
        this.dates = this.dates
          .sort((a, b) => (parse(a, 'yyyy-MM-dd', new Date()) > parse(b, 'yyyy-MM-dd', new Date()) ? 1 : -1));
      } else if (keys[1] === 'sessions') {
        this.elements.sessions = data.values as unknown as Session[];
      } else if (keys[1] === 'scheduleOfEvents') {
        this.elements.scheduleOfEvents = data.values as unknown as ScheduleEvent[];
      } else {
        if (!Object.keys(this.filtersData)
          .includes(keys[1])) {
          Object.assign(this.filtersData, { [keys[1]]: [] });
        }
        this.filtersQuery[keys[1]].loaded = true;
        this.filtersData[keys[1]] = data.values;
        const foundListConfig = this.listConfigs.find((lc) => lc.code === keys[1]);
        if (foundListConfig) {
          foundListConfig.dataQuery = data.values.map<SelectedListParams>((d) => ({
            selected: false,
            id: Object.keys(d)
              .includes('_startDate') ? d._startDate.toString() : deepGet(d, foundListConfig.value),
            title: Object.keys(d)
              .includes('_startDate')
              ? format(
                parse(d._startDate.toString(), 'yyyy-MM-dd', new Date()),
                data.rootState && data.rootState.i18n ? `${data.rootState.i18n.t('app.date.entitySearchDateFormat')}` : 'PP',
                { locale: data.rootState.dateLocale },
              )
              : deepGet(d, foundListConfig.text),
          }));
        }
      }
    }
  }

  @Mutation initializeDates(): void {
    this.dates = [];
  }

  @Mutation initializeData(): void {
    this.elements.sessions = [];
    this.elements.scheduleOfEvents = [];
  }

  @Mutation
  private setListConfigs(): void {
    if (this.widget && this.widget.subWidgets) {
      this.listConfigs = this.widget.subWidgets.filter((sub) => {
        const { type } = JSON.parse(sub.payload || '{}');
        return type && [
          EntitySearchFilterType.LIST,
          EntitySearchFilterType.DATE,
          EntitySearchFilterType.SESSION_DATE,
        ].includes(type.toLowerCase());
      })
        .map((sub) => {
          const payload = JSON.parse(sub.payload || '{}');
          let data: SelectedListParams[] = [];
          if (payload.type.toLowerCase() === EntitySearchFilterType.DATE) {
            data = [{
              selected: false,
              id: 'past-week',
              title: 'filter-component.date-filter.past-week',
            },
            {
              selected: false,
              id: 'past-month',
              title: 'filter-component.date-filter.past-month',
            },
            {
              selected: false,
              id: 'today',
              title: 'filter-component.date-filter.today',
            },
            {
              selected: false,
              id: 'this-week',
              title: 'filter-component.date-filter.this-week',
            },
            {
              selected: false,
              id: 'next-week',
              title: 'filter-component.date-filter.next-week',
            },
            {
              selected: false,
              id: 'this-month',
              title: 'filter-component.date-filter.this-month',
            },
            {
              selected: false,
              id: 'next-month',
              title: 'filter-component.date-filter.next-month',
            }];
          }
          return {
            type: EntitySearchType.LIST,
            listType: payload.type.toLowerCase(),
            code: payload.key,
            text: payload.text,
            value: payload.value,
            buttonLabel: payload.label,
            filterQuery: payload.filterQuery,
            dataQuery: data,
            visible: true,
          };
        });
    }
  }

  @Mutation
  private setToggleConfigs(): void {
    if (this.widget && this.widget.subWidgets) {
      this.toggleConfigs = this.widget.subWidgets.filter((sub) => {
        const { type } = JSON.parse(sub.payload || '{}');
        return type && type.toLowerCase() === EntitySearchFilterType.TOGGLE;
      })
        .map((sub) => {
          const payload = JSON.parse(sub.payload || '{}');
          return {
            type: EntitySearchType.TOGGLE,
            code: payload.key,
            isSwitcher: payload.isSwitcher,
            isChecked: payload.isChecked,
            filterQuery: payload.filterQuery,
            buttonLabel: payload.label,
          } as EntityFilterToggle;
        });
    }
  }

  @Mutation
  private setRangeConfigs(): void {
    if (this.widget && this.widget.subWidgets) {
      this.rangeConfigs = this.widget.subWidgets.filter((sub) => {
        const { type } = JSON.parse(sub.payload || '{}');
        return type && type.toLowerCase() === EntitySearchFilterType.RANGE;
      })
        .map((sub) => {
          const payload = JSON.parse(sub.payload || '{}');
          return {
            type: EntitySearchType.RANGE,
            key: payload.key,
            field: payload.field,
            label: payload.label,
            entityType: payload.entityType,
          } as EntityFilterRange;
        });
    }
  }

  @Mutation
  private setSelectedDate(val: string | null): void {
    this.selectedDate = val;
  }

  @Mutation
  private setScheduleEventFilter(val: Data): void {
    this.scheduleOfEventFilter = val;
  }

  @Mutation
  private setSelectedSession(val: string | null): void {
    this.selectedSession = val;
  }

  @Mutation
  private setFilterQueries(payload: { key: string; query: string; loaded: boolean }): void {
    if (!Object.keys(this.filtersQuery)
      .includes(payload.key)) {
      Object.assign(this.filtersQuery, { [payload.key]: {} });
    }
    this.filtersQuery[payload.key] = {
      query: payload.query,
      loaded: payload.loaded,
    };
  }

  @Mutation
  private setFiltersData(payload: { key: string; data: Data[] }): void {
    if (!Object.keys(this.filtersData)
      .includes(payload.key)) {
      Object.assign(this.filtersData, { [payload.key]: [] });
    }
    this.filtersData[payload.key] = payload.data;
  }
}
