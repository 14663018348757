import VueRouter, { Route } from 'vue-router';
import EntityType from '@/utils/enums/EntityType';
import CookieService from '@/services/CookieService';
import AUTH_TOKEN from '@/utils/constants/SessionToken';
import ClientStorage from '@/utils/ClientStore';

export default class RouteHelper {
  /**
   * Verify is the given route is an external url and navigates to it in a new tab if so.
   *
   * @param route
   * @param params
   * @param router
   * @param paramsTransformationFn Function to replace params in route (ex: %sessionId%)
   * @param logger function to log the navigation in remote statistics engine
   */
  // eslint-disable-next-line max-params
  static universalNavigation(
    route: string,
    params: Record<string, string>,
    router: { push: (url: string) => void },
    paramsTransformationFn?: (routeItem: string) => string,
    logger?: (url: string) => void,
  ): void {
    const result = RouteHelper.processUrlWithParamTransformFn(
      route,
      paramsTransformationFn || this.standardTransformFn(route, params),
    );

    if (logger) {
      logger(result.url);
    }
    if (result.external) {
      window.open(result.url, '_blank');
    } else {
      router.push(result.url);
    }
  }

  static standardTransformFn(
    route: string,
    params: Record<string, string>,
  ): (item: string) => string {
    return (item: string) => {
      const r = route;
      const prop = item.replaceAll('%', '')
        .trim();
      if (params[prop]) {
        return r.replaceAll(item, params[prop]);
      }

      return r;
    };
  }

  static processUrl(
    route: string,
    params: Record<string, string>,
  ): { url: string; external: boolean } {
    return RouteHelper.processUrlWithParamTransformFn(route, this.standardTransformFn(route, params));
  }

  static processUrlWithParamTransformFn(
    route: string,
    transformFn: (routeItem: string) => string,
  ): { url: string; external: boolean } {
    let url = route;
    // eslint-disable-next-line max-len
    const external = this.isUrlValid(route);
    if (!external) {
      let r = route;
      const matches = route.match(/(%[a-zA-Z-_]+%)/gs);
      if (matches) {
        matches.forEach((m) => {
          r = transformFn(m);
        });
      }
      url = r;
    }

    return {
      url,
      external,
    };
  }

  static isUrlValid(url: string): boolean {
    // eslint-disable-next-line max-len
    const validUrl = /^(?:(?:(?:https?|ftp):)?\/\/)?(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(url);
    return validUrl || /^((http(s)?:)?\/\/)/.test(url);
  }

  static internalDetailRouteNavigation(
    router: VueRouter,
    entityId: string,
    entityType: string,
    returnUrl = false,
  ): Promise<Route | null> | string {
    switch (entityType) {
      case EntityType.USER:
        if (returnUrl) {
          return router.resolve({
            name: 'member-detail',
            params: { memberId: entityId },
          }).href;
        }
        return router.push({
          name: 'member-detail',
          params: { memberId: entityId },
        });
      case EntityType.SPEAKER:
        if (returnUrl) {
          return router.resolve({
            name: 'speaker-detail',
            params: { speakerId: entityId },
          }).href;
        }
        return router.push({
          name: 'speaker-detail',
          params: { speakerId: entityId },
        });
      case EntityType.SESSION:
        if (returnUrl) {
          return router.resolve({
            name: 'session-detail',
            params: { sessionId: entityId },
          }).href;
        }
        return router.push({
          name: 'session-detail',
          params: { sessionId: entityId },
        });
      case EntityType.DEAL:
        if (returnUrl) {
          return router.resolve({
            name: 'deal-detail',
            params: { dealId: entityId },
          }).href;
        }
        return router.push({
          name: 'deal-detail',
          params: { dealId: entityId },
        });
      case EntityType.LARGE_PRODUCT:
        if (returnUrl) {
          return router.resolve({
            name: 'large-product-detail',
            params: { largeProductId: entityId },
          }).href;
        }
        return router.push({
          name: 'large-product-detail',
          params: { largeProductId: entityId },
        });
      case EntityType.PRODUCT:
        if (returnUrl) {
          return router.resolve({
            name: 'product-detail',
            params: { productId: entityId },
          }).href;
        }
        return router.push({
          name: 'product-detail',
          params: { productId: entityId },
        });
      case EntityType.SUB_EDITION:
        if (returnUrl) {
          return router.resolve({
            name: 'event-detail',
            params: { editionCode: entityId },
          }).href;
        }
        return router.push({
          name: 'event-detail',
          params: { editionCode: entityId },
        });
      case EntityType.EXHIBITOR:
        if (returnUrl) {
          return router.resolve({
            name: 'company-detail',
            params: { companyId: entityId },
          }).href;
        }
        return router.push({
          name: 'company-detail',
          params: { companyId: entityId },
        });
      default:
        if (returnUrl) {
          return '';
        }
        return Promise.resolve(null);
    }
  }

  static externalNavigationBetweenPortals(
    domain: string,
    name: string,
    params?: Record<string, string>,
  ): string {
    const version = ClientStorage.getItem('version');
    const authToken = CookieService.getCookie(AUTH_TOKEN);
    const url = new URL('dispatch', domain);
    url.searchParams.append('name', name);
    if (params) {
      Object.keys(params)
        .forEach((key) => {
          url.searchParams.append(key, params[key]);
        });
    }
    if (version) {
      url.searchParams.append('version', version);
    }
    if (authToken) {
      url.searchParams.append('token', authToken);
    }
    return url.href;
  }
}
