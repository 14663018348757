enum SlotTypeEnum {
  TEAM_MEMBER_SLOT = 'team_member_slot',
  PRODUCT_SLOT = 'product_slot',
  HANDOUT_SLOT = 'handout_slot',
  PRODUCT_HANDOUT_SLOT = 'product_handout_slot',
  LARGE_PRODUCT_HANDOUT_SLOT = 'large_product_handout_slot',
  FEATURED_EXHIBITOR = 'featured_exhibitor',
  LOGO = 'logo',
  BANNER = 'banner',
  DESCRIPTION_LENGTH = 'description_length',
  CONTACT_SLOT = 'contact_slot',
  SHOW_DEAL_SLOT = 'show_deal_slot',
  EXHIBITOR_EVENTS = 'exhibitor_events',
  CATEGORY_SLOT = 'category_slot',
  LARGE_PRODUCT_SLOT = 'large_product_slot',
  URL_SLOT = 'url_slot',
  EXTERNAL_COMPONENT = 'external_component',
  AUTO_CATEGORY_ASSIGNMENT = 'auto_category_assignment',
  VIDEO_SLOT = 'video_slot',
  MEETING_FEATURE = 'meeting_feature',
  MESSAGE_FEATURE = 'message_feature',
  SEND_INFORMATION_FEATURE = 'send_information_feature',
  COMPANY_CONTACT_INFO_SLOT = 'company_contact_info_slot',
  COMPANY_VIDEO_PRESENTATION_SLOT = 'company_video_presentation_slot',
  BOAT_CAN_EDIT_SLOT = 'boat_can_edit_slot',
  BOAT_CAN_CREATE_SLOT = 'boat_can_create_slot',
  BOAT_CAN_EDIT_BOOTH_SLOT = 'boat_can_edit_booth_slot',
  BOAT_CAN_IMPORT_FROM_NMMA_SLOT = 'boat_can_import_from_nmma_slot',
  BOAT_FIELD_SLOT = 'boat_field_slot',
  BOAT_EXTRA_PROPERTY_SLOT = 'boat_extra_property_slot',
}

export default SlotTypeEnum;
