




























































































































































































import { Component, Prop, Watch } from 'vue-property-decorator';
import Variant from '@/utils/enums/Variant';
import ButtonComponent from '@/components/ButtonComponent.vue';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import InputSearchComponent from '@/components/InputSearchComponent.vue';
import FilterItemComponent from '@/components/FilterItemComponent.vue';
import PillWidget from '@/components/pill/PillWidget.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import StandardModal from '@/components/modals/StandardModal.vue';
import Category from '@/models/graphql/Category';
import { namespace } from 'vuex-class';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import Exhibitor from '@/models/graphql/Exhibitor';
import EntityType from '@/utils/enums/EntityType';
import CategoryDomainContext from '@/models/graphql/CategoryDomainContext';
import { CategoryFilter } from '@/graphql/_Filters/CategoryFilter';

const categoryStore = namespace('CategoryStore');
const exhibitorStore = namespace('ExhibitorStore');

/* eslint-disable no-underscore-dangle,no-nested-ternary,@typescript-eslint/camelcase */

@Component({
  components: {
    FontAwesomeComponent,
    StandardModal,
    PillWidget,
    FilterItemComponent,
    InputSearchComponent,
    ButtonIconComponent,
    ButtonComponent,
  },
})
export default class CategorySelectorModal extends VueBaseWidget {
  @Prop({ default: '' })
  modalId!: string;

  @Prop({ required: false, default: '' })
  private entityType!: EntityType;

  @exhibitorStore.Getter('fetchAdminPanelExhibitor')
  private adminPanelExhibitor!: Exhibitor;

  @categoryStore.Action
  private loadCategories!: (filter: CategoryFilter) => Promise<Category[]>;

  private categories: Category[] = [];

  @Prop({ required: false })
  private readonly selectedCategories!: Category[];

  @Prop({ required: false, default: [] })
  private readonly selectedIds!: string[];

  @Prop({ required: false })
  private readonly tabList!: CategoryDomainContext[];

  @Prop({ required: false, default: false })
  private readonly saveLoading!: boolean;

  @Prop({ required: false, default: () => [] })
  private readonly exhibitorLimits!: { type: string; limit: number }[];

  private localCategoryList: Category[] = [];

  private allCategoryList: Category[] = [];

  private localSelectedIds: string[] = [];

  private localSelectedList: Category[] = [];

  private entityTypeEnum = EntityType;

  private searchQuery = '';

  private variantEnum = Variant;

  private activeTab = '';

  private activeTabLimit = 0;

  private isSaveBtnDisable = true;

  private get hasLimit(): boolean {
    return this.getLimit() !== -1;
  }

  private get canAdd(): boolean {
    if (this.hasLimit) {
      return this.selectedCategoriesCount(this.activeTab) < this.getLimit();
    }
    return true;
  }

  initModalData(): void {
    this.setTabList();
    this.setCategoryList();
    this.setSelectedIds();
  }

  private limitLabel(domainName: string): string {
    if (this.getLimitByDomain(domainName) > 0 && this.selectedCategoriesCount(domainName) > 0) {
      return `${this.selectedCategoriesCount(domainName)} / ${this.getLimitByDomain(domainName)}`;
    }
    return '';
  }

  private onConfirm(): void {
    this.$emit('on-confirm', {
      selectedIds: this.localSelectedIds,
      selectedList: this.localSelectedList,
    });
  }

  private onCancel(): void {
    this.$bvModal.hide(this.modalId);
    this.setSelectedIds();
  }

  private setTabList(): void {
    if (this.tabList.length > 0) {
      const localTabList = this.tabList
        .filter((value: CategoryDomainContext) => !!value.domain)
        .map((value: CategoryDomainContext) => value.domain as string);
      if (localTabList) {
        this.loadCategories({
          domain_in: localTabList,
          schemaCode: this.community.code,
        }).then((categories) => {
          this.categories = categories;
        });
      }
      this.activeTab = this.tabList[0].domain ? this.tabList[0].domain : '';
      this.activeTabLimit = this.getLimitByDomain(this.activeTab);
    }
  }

  @Watch('categories')
  private setCategoryList(): void {
    this.localCategoryList = this.categories;
    this.allCategoryList = this.categories;
    this.updateSelectedFilterList();
  }

  @Watch('selectedIds')
  private setSelectedIds(): void {
    this.localSelectedIds = [...this.selectedIds];
  }

  private onSearch(payload: { query: string }): void {
    this.localCategoryList = this.categories;
    const search = payload.query;
    if (search.length > 0) {
      this.localCategoryList = this.categories
        .filter((filter: Category) => filter.name
          && filter.name.toLowerCase().includes(search.toLowerCase()));
    }
  }

  @Watch('localSelectedIds')
  private updateSelectedFilterList(): void {
    if (this.categories) {
      this.localSelectedList = [];
      this.localSelectedIds.forEach((selectedId) => {
        const found = this.allCategoryList.filter((filter) => filter.uid === selectedId);
        if (found.length !== 0) {
          this.localSelectedList.push(found[0]);
        }
      });
      this.localSelectedList.sort((a, b) => {
        if (a.displaySequence === null && b.displaySequence === null) {
          return a.name < b.name ? -1 : 1;
        }
        if (a.displaySequence === null && b.displaySequence === null) {
          return a.name < b.name ? -1 : 1;
        }
        if (a.displaySequence !== null && b.displaySequence === null) {
          return -1;
        }
        if (a.displaySequence === null && b.displaySequence !== null) {
          return 1;
        }
        return a.displaySequence !== null
        && b.displaySequence !== null
        && (a.displaySequence as number) < (b.displaySequence as number)
          ? -1
          : a.displaySequence !== null
          && b.displaySequence !== null
          && (a.displaySequence as number) > (b.displaySequence as number)
            ? 1 : a.name < b.name ? -1 : 1;
      });
    }
    if (this.selectedIds.length !== this.localSelectedIds.length) {
      this.isSaveBtnDisable = false;
    } else if (this.localSelectedIds.length === 0) {
      this.isSaveBtnDisable = true;
    } else {
      this.localSelectedIds.some((uidLocal, index) => {
        if (this.selectedIds.find((uid) => uid === uidLocal) === undefined) {
          this.isSaveBtnDisable = false;
          return true;
        }
        if (index === (this.localSelectedIds.length - 1)) {
          this.isSaveBtnDisable = true;
          return true;
        }
        return false;
      });
    }
  }

  private toggleCategoryItem(id: string): void {
    const idIndex = this.localSelectedIds.findIndex((i) => i === id);
    if (idIndex < 0) {
      if (this.canAdd) {
        this.localSelectedIds.push(id);
      }
    } else {
      this.localSelectedIds.splice(idIndex, 1);
    }
  }

  private onClearAll(): void {
    const selectedList = this.localSelectedList;
    selectedList.forEach((category) => {
      if (category.domain === this.activeTab) {
        this.toggleCategoryItem(category.uid);
      }
    });
  }

  private onClearFilter(selectedFilter: Category): void {
    this.toggleCategoryItem(selectedFilter.uid);
  }

  private onActiveTabChange(domain: CategoryDomainContext): void {
    this.activeTab = (domain.domain) ? domain.domain : '';
    this.activeTabLimit = domain.domain ? this.getLimitByDomain(domain.domain) : -1;
  }

  private getLimitByDomain(domain: string): number {
    let limit = -1;
    this.exhibitorLimits.forEach((categoryLimit) => {
      if (categoryLimit.type === domain) {
        limit = categoryLimit.limit;
      }
    });
    return limit;
  }

  private selectedCategoriesCount(domain: string): number {
    const selectedCateForTab = [];
    if (this.localSelectedList) {
      this.localSelectedList.forEach((category) => {
        if (category.domain === domain) {
          selectedCateForTab.push(category);
        }
      });
    }
    return selectedCateForTab.length;
  }

  private getLimit(): number {
    if (this.entityType === EntityType.EXHIBITOR) {
      return this.activeTabLimit;
    }
    return -1;
  }
}
