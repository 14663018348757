import { Action, Module, Mutation } from 'vuex-module-decorators';
import WidgetBaseStore from '@/store/widget/WidgetBaseStore';
import GqlComposeQueryDefinitionParams from '@/utils/types/gql/GqlComposeQueryDefinitionParams';
import { Data } from '@/utils/types/WidgetData';
import EntityFilterToggle from '@/utils/types/entity-search/EntityFilterToggle';
import { EntitySearchType } from '@/utils/enums/EntitySearchType';
import EntityFilterList from '@/utils/types/entity-search/EntityFilterList';
import SelectedListParams from '@/utils/types/SelectedListParams';
import { deepGet, deepSearch } from '@/utils/ObjectHelpers';
import { BasicTypes } from '@/utils/types/BasicTypes';
import WidgetBaseRepository from '@/repositories/widget/WidgetBaseRepository';
import GqlEntityFilterType from '@/utils/enums/gql/GqlEntityFilterType';
import GqlEntityOrderingType from '@/utils/enums/gql/GqlEntityOrderingType';
import { buildQueryDefinition } from '@/graphql/_Tools/GqlQueryDefinition';
import EntitySearchHelper from '@/utils/helpers/widgets/EntitySearchHelper';
import { HallType } from '@/components/map/FloorplanComponent.vue';
import Geozone from '@/models/graphql/Geozone';
import * as StringHelper from '@/utils/helpers/StringHelper';
import { EntitySearchFilterType } from '@/utils/enums/EntitySearchFilterType';
import { GQL_ENTITY_SEARCH_ALIAS } from '@/utils/constants/Regex';
import GraphqlQueryHelper from '@/utils/helpers/GraphqlQueryHelper';
import qs from 'qs';

/* eslint-disable @typescript-eslint/camelcase,no-underscore-dangle,prefer-const */
@Module({
  namespaced: true,
  stateFactory: true,
})
export default class MapWidgetStore extends WidgetBaseStore {
  private filter: Data = {};

  private gqlDataConfigs: GqlComposeQueryDefinitionParams | null = null;

  private halls: HallType[] = [];

  private geozones: Geozone[] = [];

  private toggleConfigs: EntityFilterToggle[] = [];

  private listConfigs: EntityFilterList[] = [];

  private filtersQuery: Record<string, { query: string; loaded: boolean }> = {};

  private filtersData: Record<string, Data[]> = {};

  private readonly repository = new WidgetBaseRepository();

  get exhibitHalls(): HallType[] {
    return this.halls;
  }

  get filteredGeozones(): Geozone[] {
    return this.geozones;
  }

  get timeZoneName(): string {
    return this.context.rootState.selectedTzName;
  }

  @Action
  setDataConfigs(): void {
    this.context.commit('setListConfigs');
    this.context.commit('setToggleConfigs');
    const args = qs.parse(window.location.search.slice(1));
    this.context.commit('constructFilter', {
      ...args,
      edition: this.payload?.schemaCode,
      schemaCode: this.context.rootState.community?.code || '',
      authUser: this.context.rootState.authUser?.uid || '',
      locale: this.context.rootState.i18n?.locale || '',
      timezone: this.timeZoneName,
    });
    const filterKeysToLoad: string[] = [];
    if (args && 'filters' in args && args.filters && Object.keys(args.filters).length > 0) {
      filterKeysToLoad.push(...Object.keys(args.filters));
    }
    if (this.widget && this.widget.subWidgets && this.widget.subWidgets.length > 0) {
      let queries = '';
      this.widget.subWidgets.forEach((sub) => {
        let {
          graphql,
          type,
          key,
          graphQlFilter,
          orderBy,
        } = JSON.parse(sub.payload || '{}');
        if (type && type.toLowerCase() === EntitySearchFilterType.DATE) {
          return;
        }
        if (type && type.toLowerCase() === EntitySearchFilterType.SESSION_DATE) {
          const sortBy = orderBy && orderBy !== 'default' ? `orderBy: [${orderBy}]` : '';
          const parsedGraphQlFilter = GraphqlQueryHelper.parseJSONToGraphQLFilter(graphQlFilter);
          // eslint-disable-next-line max-len
          graphql = `{ session(_cachable: true ${parsedGraphQlFilter} ${sortBy}) { _startDate(timezone: "${this.timeZoneName}") }}`;
        }
        if (type && graphql && graphql.trim().length > 0) {
          const matches = graphql.trim()
            .replace(/^\{/, '')
            .replace(/\}$/, '')
            .match(GQL_ENTITY_SEARCH_ALIAS);
          if (matches && matches.length === 4 && matches[3].length > 0) {
            if (!matches[3].includes('_cachable')) {
              matches[3] = GraphqlQueryHelper.appendArgumentToQuery(
                matches[3],
                '_cachable',
                'BooleanValue',
                !deepSearch(GraphqlQueryHelper.extractFilterFromQuery(matches[3]), '%authUser%'),
              );
            }
            const query = `${this.widgetStoreName}_${key}: ${matches[3].trim()}`;
            if (filterKeysToLoad.includes(key)) {
              queries = `${queries} ${query}`;
            }
            this.context.commit(
              'setFilterQueries',
              {
                key,
                query,
                loaded: filterKeysToLoad.includes(key),
              },
            );
          }
        }
      });
      if (queries) {
        this.context.commit(
          'WidgetDispatcherStore/setGqlQuery',
          {
            key: this.widgetStoreName,
            value: queries,
          },
          { root: true },
        );
      }
    }
    if (this.widget && this.payload && this.payload.schemaCode) {
      const configs = [{
        gqlDefinition: buildQueryDefinition({
          cacheable: true,
          filter: {
            value: {
              visible: true,
              schemaCode: this.payload.schemaCode,
            },
            type: GqlEntityFilterType.EXHIBIT_HALL_FILTER,
          },
          orderBy: {
            value: ['defaultHall_desc'],
            type: GqlEntityOrderingType.EXHIBIT_HALL_ORDERING,
          },
        }),
        operation: 'exhibitHall',
        fragmentName: 'exhibitHallBaseFragment',
        alias: `${this.widgetStoreName}_halls`,
      }];
      const gqlDataConfig = {
        operation: 'geozone',
        fragmentName: 'geozoneWithExhibitHallFragment',
        alias: `${this.widgetStoreName}_geozones`,
        gqlDefinition: buildQueryDefinition({
          cacheable: true,
          filter: {
            value: this.filter,
            type: GqlEntityFilterType.GEOZONE_FILTER,
          },
        }),
      };
      if (Object.keys(this.filter).length > 0) {
        configs.push(gqlDataConfig);
      }
      this.context.commit('setGqlDataConfig', gqlDataConfig);
      this.context.commit(
        'WidgetDispatcherStore/setGqlQueryConfigs',
        {
          key: this.widgetStoreName,
          values: configs,
        },
        { root: true },
      );
    }
  }

  @Action
  loadFilteredGeozones(routeQueries = {}): Promise<void> {
    this.context.commit('load', true);
    const args: Record<string, BasicTypes> = this.context.rootGetters['WidgetDispatcherStore/fetchMagicArgs'];
    this.context.commit('constructFilter', {
      ...routeQueries,
      edition: this.payload?.schemaCode,
      schemaCode: this.context.rootState.community?.code || '',
      authUser: this.context.rootState.authUser?.uid || '',
      locale: this.context.rootState.i18n?.locale || '',
      timezone: this.timeZoneName,
    });
    if (this.gqlDataConfigs) {
      return this.repository.load({
        params: {
          operationName: 'LoadFilteredGeozones',
          definitions: [this.gqlDataConfigs],
          magicArgs: args,
        },
        queries: '',
      })
        .then((response) => {
          Object.keys(response)
            .forEach((k) => {
              this.context.commit(
                'setData',
                {
                  key: k,
                  values: response[k],
                },
              );
            });
        })
        .then(() => {
          this.context.commit('load', false);
          return Promise.resolve();
        });
    }
    this.context.commit('load', false);
    return Promise.resolve();
  }

  @Mutation
  setGqlDataConfig(config: GqlComposeQueryDefinitionParams): void {
    this.gqlDataConfigs = config;
  }

  @Mutation
  constructFilter(payload: Data): void {
    const {
      search,
      filters,
      toggles,
      geozones,
      schemaCode,
      edition,
      authUser,
      locale,
      timezone,
    } = payload;
    this.filter = {
      objectType_in: ['Booth', 'Room', 'LargeProduct'],
      exhibitHall: { visible: true },
      schemaCode: edition as string,
    };
    const AND = [];
    const OR = [];
    if (search && (search as string).length > 0) {
      Object.assign(this.filter, {
        OR: [
          {
            name_contains: search,
          },
          {
            booth_some: {
              exhibitors_some: {
                name_contains: search,
              },
            },
          },
          {
            booth_some: {
              largeProducts_some: {
                name_contains: search,
              },
            },
          },
          {
            sessions_some: {
              name_contains: search,
            },
          },
        ],
      });
    }

    if (geozones && (geozones as string[]).length > 0) {
      Object.assign(this.filter, { id_in: (geozones as string[]).map((g) => parseInt(g, 10)) });
    }

    if (filters && Object.keys(filters).length > 0) {
      if ('geozones' in (filters as Data) && ((filters as Data).geozones as string[]).length > 0) {
        Object.assign(this.filter, { id_in: ((filters as Data).geozones as string[]).map((g) => parseInt(g, 10)) });
      }

      AND.push(...EntitySearchHelper.constructListFilters(
        this.listConfigs,
        filters as Data,
        {
          authUser,
          schemaCode,
          locale,
        },
      ));
      const sessionDateFilter = EntitySearchHelper.constructSessionDateFilter(
        this.listConfigs,
        filters as Data,
        timezone as string,
      );
      if (sessionDateFilter) {
        Object.assign(this.filter, sessionDateFilter);
      }
      OR.push(...EntitySearchHelper.constructDateFilter(
        this.listConfigs,
        filters as Data,
      ));
    } else {
      delete this.filter.filters;
    }

    if (toggles && Object.keys(toggles).length > 0) {
      AND.push(...EntitySearchHelper.constructToggleFilters(
        this.toggleConfigs,
        toggles as Data,
        {
          authUser,
          schemaCode,
          locale,
        },
      ));
    } else {
      delete this.filter.toggles;
    }
    if (AND.length > 0) {
      Object.assign(this.filter, { AND });
    }
    if (OR.length > 0) {
      Object.assign(this.filter, { OR });
    }
    if (this.gqlDataConfigs && this.gqlDataConfigs.gqlDefinition.filter) {
      this.gqlDataConfigs.gqlDefinition.cacheable = !authUser
        || (!deepSearch(this.filter, authUser)
          && !deepSearch(this.filter, '%authUser%'));
      this.gqlDataConfigs.gqlDefinition.filter.value = this.filter;
    }
  }

  @Action
  loadFilterData(code: string): Promise<void> {
    if (Object.keys(this.filtersQuery)
      .includes(code)
      && !this.filtersQuery[code].loaded) {
      const args: Record<string, BasicTypes> = this.context.rootGetters['WidgetDispatcherStore/fetchMagicArgs'];
      return this.repository.load({
        params: {
          operationName: `Load${StringHelper.snakeCaseToPascalCase(code)}FilterData`,
          definitions: [],
          magicArgs: args,
        },
        queries: this.filtersQuery[code].query,
      })
        .then((response) => {
          Object.keys(response)
            .forEach((k) => {
              this.context.commit(
                'setData',
                {
                  key: k,
                  values: response[k],
                },
              );
            });
        })
        .then(() => Promise.resolve());
    }
    return Promise.resolve();
  }

  @Mutation
  mapper(): void {
    this.mapping = {
      ...this.payload,
      halls: this.halls,
      listConfigs: this.listConfigs,
      toggleConfigs: this.toggleConfigs,
    };
  }

  @Mutation
  setData(data: { values: Array<Data>; key: string }): void {
    const keys = data.key.split('_');
    if (keys.length === 2) {
      if (keys[1] === 'geozones') {
        this.geozones = data.values as unknown as Geozone[];
      } else if (keys[1] === 'halls') {
        this.halls = data.values.map((h, index) => ({
          ...h,
          selected: index === 0,
          resultFilterCount: 0,
        } as HallType));
        if (this.payload && this.payload.overview) {
          this.halls.unshift({
            foreignId: 'overview',
            id: -1,
            name: 'Overview',
            selected: true,
            resultFilterCount: 0,
          });
        }
      } else {
        if (!Object.keys(this.filtersData)
          .includes(keys[1])) {
          Object.assign(this.filtersData, { [keys[1]]: [] });
        }
        this.filtersQuery[keys[1]].loaded = true;
        this.filtersData[keys[1]] = data.values;
        const foundListConfig = this.listConfigs.find((lc) => lc.code === keys[1]);
        if (foundListConfig) {
          foundListConfig.dataQuery = data.values.map<SelectedListParams>((d) => ({
            selected: false,
            id: Object.keys(d)
              .includes('_startDate') ? d._startDate.toString() : deepGet(d, foundListConfig.value),
            title: Object.keys(d)
              .includes('_startDate') ? d._startDate.toString() : deepGet(d, foundListConfig.text),
          }));
        }
      }
    }
  }

  @Mutation
  setExhibitHalls(halls: HallType[]): void {
    this.halls = halls;
  }

  @Mutation
  private setListConfigs(): void {
    if (this.widget && this.widget.subWidgets) {
      this.listConfigs = this.widget.subWidgets.filter((sub) => {
        const { type } = JSON.parse(sub.payload || '{}');
        return type && [
          EntitySearchFilterType.LIST,
          EntitySearchFilterType.DATE,
          EntitySearchFilterType.SESSION_DATE,
        ].includes(type.toLowerCase());
      })
        .map((sub) => {
          const payload = JSON.parse(sub.payload || '{}');
          let data: SelectedListParams[] = [];
          if (payload.type.toLowerCase() === EntitySearchFilterType.DATE) {
            data = [{
              selected: false,
              id: 'past-week',
              title: 'filter-component.date-filter.past-week',
            },
            {
              selected: false,
              id: 'past-month',
              title: 'filter-component.date-filter.past-month',
            },
            {
              selected: false,
              id: 'today',
              title: 'filter-component.date-filter.today',
            },
            {
              selected: false,
              id: 'this-week',
              title: 'filter-component.date-filter.this-week',
            },
            {
              selected: false,
              id: 'next-week',
              title: 'filter-component.date-filter.next-week',
            },
            {
              selected: false,
              id: 'this-month',
              title: 'filter-component.date-filter.this-month',
            },
            {
              selected: false,
              id: 'next-month',
              title: 'filter-component.date-filter.next-month',
            }];
          }
          return {
            type: EntitySearchType.LIST,
            listType: payload.type.toLowerCase(),
            code: payload.key,
            text: payload.text,
            value: payload.value,
            buttonLabel: payload.label,
            filterQuery: payload.filterQuery,
            dataQuery: data,
            visible: true,
          };
        });
    }
  }

  @Mutation
  private setToggleConfigs(): void {
    if (this.widget && this.widget.subWidgets) {
      this.toggleConfigs = this.widget.subWidgets.filter((sub) => {
        const { type } = JSON.parse(sub.payload || '{}');
        return type && type.toLowerCase() === EntitySearchFilterType.TOGGLE;
      })
        .map((sub) => {
          const payload = JSON.parse(sub.payload || '{}');
          return {
            type: EntitySearchType.TOGGLE,
            code: payload.key,
            isSwitcher: payload.isSwitcher,
            isChecked: payload.isChecked,
            filterQuery: payload.filterQuery,
            buttonLabel: payload.label,
          } as EntityFilterToggle;
        });
    }
  }

  @Mutation
  private setFilterQueries(payload: { key: string; query: string; loaded: boolean }): void {
    if (!Object.keys(this.filtersQuery)
      .includes(payload.key)) {
      Object.assign(this.filtersQuery, { [payload.key]: {} });
    }
    this.filtersQuery[payload.key] = {
      query: payload.query,
      loaded: payload.loaded,
    };
  }

  @Mutation
  private setFiltersData(payload: { key: string; data: Data[] }): void {
    if (!Object.keys(this.filtersData)
      .includes(payload.key)) {
      Object.assign(this.filtersData, { [payload.key]: [] });
    }
    this.filtersData[payload.key] = payload.data;
  }
}
