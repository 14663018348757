import { ApolloQueryResult, FetchResult } from '@apollo/client';
import Session from '@/models/graphql/Session';
import GraphQlRepository from '@/repositories/base/GraphQlRepository';
import GqlPayloadParams from '@/utils/types/gql/GqlPayloadParams';
import {
  buildComposeMutationGql,
  buildMutationGql,
  buildQueryGql,
} from '@/graphql/_Tools/GqlGeneric';
import GqlMutationDefinition from '@/graphql/_Tools/GqlMutationDefinition';
import GqlQueryDefinition from '@/graphql/_Tools/GqlQueryDefinition';
import UpdateInterface from '@/repositories/base/UpdateInterface';
import ReadInterface from '@/repositories/base/ReadInterface';
import GqlComposeMutationPayloadParams from '@/utils/types/gql/GqlComposeMutationPayloadParams';

export default class SessionRepository extends GraphQlRepository<Session>
  implements ReadInterface<Session>, UpdateInterface<Session> {
  filter(params: GqlPayloadParams): Promise<Session[]> {
    return buildQueryGql({
      operationName: params.operationName || 'FilterSession',
      operation: 'session',
      gqlDefinition: params.definition as GqlQueryDefinition,
      fragmentName: params.fragmentName || 'sessionBaseFragment',
      authUser: params.authUser,
      params: params.params,
    }).then((query) => this.query<{ session: Array<Session> }, {}>(query, params.definition.variables, false)
      .then((response: ApolloQueryResult<{ session: Array<Session> }>) => response.data.session));
  }

  filterStartDate(params: GqlPayloadParams): Promise<string[]> {
    return buildQueryGql({
      operationName: params.operationName || 'LoadSessionsStartDate',
      operation: 'session',
      gqlDefinition: params.definition as GqlQueryDefinition,
      fragmentName: params.fragmentName || 'sessionStartDateFragment',
      authUser: params.authUser,
      magicArgs: params.magicArgs,
      params: params.params,
    }).then((query) => this.query<{ session: Array<Session> }, {}>(query, params.definition.variables, false)
      .then((response: ApolloQueryResult<{ session: Array<Session> }>) => response.data.session
        .filter((s) => s.startDate)
        .map((s) => s.startDate as string)));
  }

  get(params: GqlPayloadParams): Promise<Session | undefined> {
    return buildQueryGql({
      operationName: params.operationName || 'GetSession',
      operation: 'session',
      gqlDefinition: params.definition as GqlQueryDefinition,
      fragmentName: params.fragmentName || 'sessionBaseFragment',
      authUser: params.authUser,
    }).then((query) => this.query<{ session: Array<Session> }, {}>(query, params.definition.variables, false)
      .then((response: ApolloQueryResult<{ session: Array<Session> }>) => response.data.session[0]));
  }

  update(params: GqlPayloadParams): Promise<Session | undefined> {
    return buildMutationGql({
      operationName: 'UpdateSession',
      operation: 'SessionUpdate',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'sessionBaseFragment',
    }).then((mutation) => this.mutate<{ SessionUpdate: Session }, {}>(mutation, params.definition.variables)
      .then((response: FetchResult<{ SessionUpdate: Session }>) => response.data?.SessionUpdate));
  }

  delete(params: GqlPayloadParams): Promise<Session | undefined> {
    return buildMutationGql({
      operationName: 'DeleteSession',
      operation: 'SessionDelete',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'sessionBaseFragment',
    }).then((mutation) => this.mutate<{ SessionDelete: Session }, {}>(mutation, params.definition.variables)
      .then((response: FetchResult<{ SessionDelete: Session }>) => response.data?.SessionDelete));
  }

  createSessionForExhibitor(params: GqlPayloadParams): Promise<Session | undefined> {
    return buildMutationGql({
      operationName: 'CreateSessionForExhibitor',
      operation: 'SessionCreateForExhibitor',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: 'sessionBaseFragment',
    }).then((mutation) => this.mutate<{ SessionCreateForExhibitor: Session }, {}>(mutation, params.definition.variables)
      .then((response: FetchResult<{ SessionCreateForExhibitor: Session }>) => response.data?.SessionCreateForExhibitor));
  }

  addCategoriesToSession(params: GqlComposeMutationPayloadParams): Promise<Record<string, Session>[]> {
    return buildComposeMutationGql({
      operationName: 'AddCategoriesToSession',
      definitions: params.definitions,
    }).then((mutations) => this.mutate<Record<string, Session>[], {}>(mutations))
      .then((response: FetchResult<Record<string, Session>[]>) => response.data as Record<string, Session>[]);
  }

  removeCategoriesFromSession(params: GqlComposeMutationPayloadParams): Promise<Record<string, Session>[]> {
    return buildComposeMutationGql({
      operationName: 'removeCategoriesFromSession',
      definitions: params.definitions,
    }).then((mutations) => this.mutate<Record<string, Session>[], {}>(mutations))
      .then((response: FetchResult<Record<string, Session>[]>) => response.data as Record<string, Session>[]);
  }

  updateByLocale(params: GqlPayloadParams, locale: string): Promise<Session | undefined> {
    return buildMutationGql({
      operationName: 'SessionUpdateByLocale',
      operation: 'SessionUpdate',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'sessionBaseFragment',
    }).then((mutation) => this.mutate<{ SessionUpdate: Session }, {}>(mutation, {
      ...params.definition.variables,
      _locale: locale,
    })
      .then((response: FetchResult<{ SessionUpdate: Session }>) => response.data?.SessionUpdate));
  }
}
