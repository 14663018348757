


















































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter, namespace, State } from 'vuex-class';
import Community from '@/models/graphql/Community';
import CommunityUser from '@/models/graphql/CommunityUser';
import Notification from '@/models/graphql/Notification';
import AvatarSoloWidget from '@/components/AvatarSoloWidget.vue';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import EntityType from '@/utils/enums/EntityType';
import { RawLocation } from 'vue-router';
import DateTimeHelper from '@utils/helpers/DateTimeHelper';
import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
  differenceInYears,
  format,
  fromUnixTime,
} from 'date-fns';
import Meeting from '@/models/graphql/Meeting';
import MeetingParticipant from '@/models/graphql/MeetingParticipant';
import MeetingParticipantState from '@/utils/enums/MeetingParticipantState';
import { ToolbarMenuActions } from '@/utils/enums/ToolbarMenuActions';
import ViewMode from '@/utils/enums/agenda/ViewMode';
import AgendaStoreHelper from '@/utils/helpers/AgendaStoreHelper';
import Event from '@/utils/types/Event';
import NotificationActionType from '@/utils/enums/notification/NotificationActionType';
import StatLoggerActions from '@/utils/enums/StatLoggerActions';
import StatLoggerCategories from '@/utils/enums/StatLoggerCategories';

const agendaStore = namespace('AgendaStore');

@Component({
  components: {
    FontAwesomeComponent,
    ButtonIconComponent,
    AvatarSoloWidget,
  },
})
export default class NotificationListMeetingItemComponent extends Vue {
  @Getter
  private readonly community!: Community;

  @Getter
  private readonly authUser!: CommunityUser;

  @State
  private dateLocale!: Locale;

  @State
  private selectedTzName!: string;

  @Prop({ required: true })
  private notification!: Notification;

  @Prop({ required: true })
  private lastMeetingNotification!: Record<string, string>;

  @agendaStore.Action
  private updateMeetingParticipantState!: (payload: {
    meetingParticipantUid: string;
    state: string;
  }) => Promise<void>;

  @agendaStore.Mutation
  private setViewMode!: (string: ViewMode) => void;

  @agendaStore.Mutation
  private setEventViewed!: (e: Event) => void;

  private NotificationActionType = NotificationActionType;

  private get isCancelled(): boolean {
    if (this.notification
      && this.notification.triggered
      // eslint-disable-next-line no-underscore-dangle
      && this.notification.triggered.__typename === EntityType.MEETING_PARTICIPANT) {
      const meetingParticipant = this.notification.triggered as MeetingParticipant;
      return !!(meetingParticipant
        && meetingParticipant.meeting
        && meetingParticipant.meeting.isCancelled);
    }

    if (this.notification
      && this.notification.triggered
      // eslint-disable-next-line no-underscore-dangle
      && this.notification.triggered.__typename === EntityType.MEETING) {
      const meeting = this.notification.triggered as Meeting;
      return !!(meeting && meeting.isCancelled);
    }

    return false;
  }

  private get isAccepted(): boolean {
    if (this.notification
      && this.notification.triggered
      // eslint-disable-next-line no-underscore-dangle
      && this.notification.triggered.__typename === EntityType.MEETING_PARTICIPANT) {
      const meetingParticipant = this.notification.triggered as MeetingParticipant;
      return !!(meetingParticipant
        && meetingParticipant.state
        && meetingParticipant.state === MeetingParticipantState.ACCEPTED);
    }

    if (this.notification
      && this.notification.triggered
      // eslint-disable-next-line no-underscore-dangle
      && this.notification.triggered.__typename === EntityType.MEETING) {
      const meeting = this.notification.triggered as Meeting;
      return !!(meeting
        && meeting.participants
        && meeting.participants.length > 0
        && meeting.participants.findIndex((p) => p.state === MeetingParticipantState.ACCEPTED
          && p.user && p.user.uid === this.authUser.uid) > -1);
    }

    return false;
  }

  private get isDeclined(): boolean {
    if (this.notification
      && this.notification.triggered
      // eslint-disable-next-line no-underscore-dangle
      && this.notification.triggered.__typename === EntityType.MEETING_PARTICIPANT) {
      const meetingParticipant = this.notification.triggered as MeetingParticipant;
      return !!(meetingParticipant
        && meetingParticipant.state
        && meetingParticipant.state === MeetingParticipantState.DECLINED);
    }

    if (this.notification
      && this.notification.triggered
      // eslint-disable-next-line no-underscore-dangle
      && this.notification.triggered.__typename === EntityType.MEETING) {
      const meeting = this.notification.triggered as Meeting;
      return !!(meeting
        && meeting.participants
        && meeting.participants.length > 0
        && meeting.participants.findIndex((p) => p.state === MeetingParticipantState.DECLINED
          && p.user && p.user.uid === this.authUser.uid) > -1);
    }

    return false;
  }

  private get isItForYou(): boolean {
    return !!(this.notification
      && this.notification.triggered
      // eslint-disable-next-line no-underscore-dangle
      && this.notification.triggered.__typename
      && this.notification.action
      && [EntityType.MEETING, EntityType.MEETING_PARTICIPANT]
        // eslint-disable-next-line no-underscore-dangle
        .includes(this.notification.triggered.__typename as EntityType)
      && this.notification.notifiedUsers.findIndex((n) => n.uid === this.authUser.uid) > -1);
  }

  private get fullName(): string {
    if (this.notification
      && this.notification.initiator
      // eslint-disable-next-line no-underscore-dangle
      && this.notification.initiator.__typename === EntityType.USER) {
      const hydrated = CommunityUser.hydrate(this.notification.initiator);
      if (hydrated) {
        return hydrated.fullName;
      }
    }
    return '';
  }

  private get meetingName(): string {
    if (this.notification
      && this.notification.triggered
      // eslint-disable-next-line no-underscore-dangle
      && this.notification.triggered.__typename === EntityType.MEETING) {
      const hydrated = this.notification.triggered as Meeting;
      if (hydrated && hydrated.subject) {
        return hydrated.subject;
      }
    }
    if (this.notification
      && this.notification.triggered
      // eslint-disable-next-line no-underscore-dangle
      && this.notification.triggered.__typename === EntityType.MEETING_PARTICIPANT) {
      const hydrated = this.notification.triggered as MeetingParticipant;
      if (hydrated && hydrated.meeting && hydrated.meeting.subject) {
        return hydrated.meeting.subject;
      }
    }
    return '';
  }

  private get navigateTo(): RawLocation {
    if (this.notification
      && this.notification.initiator
      // eslint-disable-next-line no-underscore-dangle
      && this.notification.initiator.__typename === EntityType.USER) {
      const hydrated = CommunityUser.hydrate(this.notification.initiator);
      if (hydrated) {
        return {
          name: 'member-detail',
          params: { memberId: hydrated.uid },
        };
      }
    }
    return '';
  }

  private get image(): string {
    if (this.notification
      && this.notification.initiator
      // eslint-disable-next-line no-underscore-dangle
      && this.notification.initiator.__typename === EntityType.USER) {
      const hydrated = CommunityUser.hydrate(this.notification.initiator);
      if (hydrated) {
        return hydrated.mainPicture;
      }
    }
    return '';
  }

  private get names(): { firstName: string; lastName: string } {
    if (this.notification
      && this.notification.initiator
      // eslint-disable-next-line no-underscore-dangle
      && this.notification.initiator.__typename === EntityType.USER) {
      const hydrated = CommunityUser.hydrate(this.notification.initiator);
      if (hydrated) {
        return {
          firstName: hydrated.firstName || '',
          lastName: hydrated.lastName || '',
        };
      }
    }
    return {
      lastName: '',
      firstName: '',
    };
  }

  private get content(): string {
    switch (this.notification.action) {
      case NotificationActionType.MEETING_CANCELLED:
        return `${this.$t('my-notification-list-component.meeting.meeting-cancel')}`;
      case NotificationActionType.MEETING_UPDATED:
        return `${this.$t('my-notification-list-component.meeting.meeting-update')}`;
      case NotificationActionType.MEETING_ACCEPTED:
        return `${this.$t('my-notification-list-component.meeting.meeting-accepted')}`;
      case NotificationActionType.MEETING_DECLINED:
        return `${this.$t('my-notification-list-component.meeting.meeting-declined')}`;
      default:
        return '';
    }
  }

  private get dateReceived(): string {
    const today = DateTimeHelper.getCurrentDateTime();
    const createdTimeDate = fromUnixTime(this.notification.createdTimestamp as number);
    if (differenceInSeconds(today, createdTimeDate) < 60) {
      return `${this.$t('my-notification-list-component.time-ago.now')}`;
    }

    const diffInMinutes = differenceInMinutes(today, createdTimeDate);
    if (diffInMinutes < 60) {
      return `${this.$t('my-notification-list-component.time-ago.minutes', {
        number: diffInMinutes,
      })}`;
    }

    const diffInDays = differenceInDays(today, createdTimeDate);
    if (diffInDays === 1) {
      return ` ${this.$t('my-notification-list-component.time-ago.yesterday')}`;
    }
    if (diffInDays < 1) {
      return `${this.$t('my-notification-list-component.time-ago.hours', {
        number: differenceInHours(today, createdTimeDate),
      })}`;
    }
    if (diffInDays < 7) {
      return `${this.$t('my-notification-list-component.time-ago.days', { number: diffInDays })}`;
    }

    if (differenceInYears(today, createdTimeDate) === 0) {
      return format(createdTimeDate, `${this.$t('app.date.monthDayShort')}`, {
        locale: this.dateLocale,
      });
    }
    return format(createdTimeDate, `${this.$t('app.date.defaultDateFormat')}`, {
      locale: this.dateLocale,
    });
  }

  private get isActionVisible(): boolean {
    return !!(Object.values(this.lastMeetingNotification)
      .find((n) => n === this.notification.uid));
  }

  private closeNotificationModal(): void {
    this.$emit('on-close-notification');
  }

  private onInteractedWith(close = true): void {
    if (close) {
      this.closeNotificationModal();
    }
    if (!this.notification.interactedWith) {
      this.$emit('on-interacted-with', this.notification.uid);
    }
  }

  private onDelete(): void {
    this.$emit('on-delete', this.notification.uid);
  }

  private onAccept(): void {
    this.onInteractedWith(false);
    if (!this.isAccepted
      && this.notification
      && this.notification.triggered) {
      // eslint-disable-next-line no-underscore-dangle
      if (this.notification.triggered.__typename === EntityType.MEETING_PARTICIPANT) {
        const { meeting } = (this.notification.triggered as MeetingParticipant);
        if (meeting && meeting.participants && meeting.participants.length > 0) {
          const participant = meeting.participants.find((p) => p.user && p.user.uid === this.authUser.uid);
          if (participant) {
            this.$set(participant, 'state', MeetingParticipantState.ACCEPTED);
            this.$root.$emit('on-root-meeting-accepted', {
              uid: participant.uid,
              state: MeetingParticipantState.ACCEPTED,
              meeting,
            });
            this.updateMeetingParticipantState({
              meetingParticipantUid: participant.uid,
              state: MeetingParticipantState.ACCEPTED,
            })
              .catch(() => {
                this.$set(participant, 'state', MeetingParticipantState.INVITED);
              });
            this.$logger.logMatomoStats(
              this.authUser,
              this.community.code as string,
              EntityType.MEETING,
              StatLoggerActions.ACCEPT_TO_AGENDA,
              'acceptAppointment',
              -1,
              meeting.uid || '',
              StatLoggerCategories.ACCEPT,
              this.$i18n.locale,
            );
          }
        }
      }
      // eslint-disable-next-line no-underscore-dangle
      if (this.notification.triggered.__typename === EntityType.MEETING) {
        const meeting = this.notification.triggered as Meeting;
        if (meeting && meeting.participants && meeting.participants.length > 0) {
          const participant = meeting.participants.find((p) => p.user && p.user.uid === this.authUser.uid);
          if (participant) {
            this.$set(participant, 'state', MeetingParticipantState.ACCEPTED);
            this.$root.$emit('on-root-meeting-accepted', {
              uid: participant.uid,
              state: MeetingParticipantState.ACCEPTED,
              meeting,
            });
            this.updateMeetingParticipantState({
              meetingParticipantUid: participant.uid,
              state: MeetingParticipantState.ACCEPTED,
            })
              .catch(() => {
                if (this.notification
                  && this.notification.triggered) {
                  this.$set(participant, 'state', MeetingParticipantState.INVITED);
                }
              });
            this.$logger.logMatomoStats(
              this.authUser,
              this.community.code as string,
              EntityType.MEETING,
              StatLoggerActions.ACCEPT_TO_AGENDA,
              'acceptAppointment',
              -1,
              meeting.uid,
              StatLoggerCategories.ACCEPT,
              this.$i18n.locale,
            );
          }
        }
      }
    }
  }

  private onDecline(): void {
    this.onInteractedWith(false);
    if (!this.isDeclined
      && this.notification
      && this.notification.triggered) {
      // eslint-disable-next-line no-underscore-dangle
      if (this.notification.triggered.__typename === EntityType.MEETING_PARTICIPANT) {
        const { meeting } = (this.notification.triggered as MeetingParticipant);
        if (meeting && meeting.participants && meeting.participants.length > 0) {
          const participant = meeting.participants.find((p) => p.user && p.user.uid === this.authUser.uid);
          if (participant) {
            this.$set(participant, 'state', MeetingParticipantState.DECLINED);
            this.$root.$emit('on-root-meeting-declined', {
              uid: participant.uid,
              state: MeetingParticipantState.DECLINED,
              meeting: (this.notification.triggered as MeetingParticipant).meeting,
            });
            this.updateMeetingParticipantState({
              meetingParticipantUid: participant.uid,
              state: MeetingParticipantState.DECLINED,
            })
              .catch(() => {
                this.$set(participant, 'state', MeetingParticipantState.DECLINED);
              });
            this.$logger.logMatomoStats(
              this.authUser,
              this.community.code as string,
              EntityType.MEETING,
              StatLoggerActions.REJECT_FROM_AGENDA,
              'rejectAppointment',
              -1,
              meeting.uid || '',
              StatLoggerCategories.REJECT,
              this.$i18n.locale,
            );
          }
        }
      }
      // eslint-disable-next-line no-underscore-dangle
      if (this.notification.triggered.__typename === EntityType.MEETING) {
        const meeting = this.notification.triggered as Meeting;
        if (meeting && meeting.participants && meeting.participants.length > 0) {
          const participant = meeting.participants.find((p) => p.user && p.user.uid === this.authUser.uid);
          if (participant) {
            this.$set(participant, 'state', MeetingParticipantState.DECLINED);
            this.$root.$emit('on-root-meeting-declined', {
              uid: participant.uid,
              state: MeetingParticipantState.DECLINED,
              meeting,
            });
            this.updateMeetingParticipantState({
              meetingParticipantUid: participant.uid,
              state: MeetingParticipantState.DECLINED,
            })
              .catch(() => {
                if (this.notification
                  && this.notification.triggered) {
                  this.$set(participant, 'state', MeetingParticipantState.INVITED);
                }
              });
            this.$logger.logMatomoStats(
              this.authUser,
              this.community.code as string,
              EntityType.MEETING,
              StatLoggerActions.REJECT_FROM_AGENDA,
              'rejectAppointment',
              -1,
              meeting.uid,
              StatLoggerCategories.REJECT,
              this.$i18n.locale,
            );
          }
        }
      }
    }
  }

  private openMeetingInToolbox(): void {
    this.onInteractedWith(true);
    if (this.notification && this.notification.triggered) {
      // eslint-disable-next-line no-underscore-dangle
      if (this.notification.triggered.__typename === EntityType.MEETING) {
        this.setEventViewed(AgendaStoreHelper.convertMeetingToEvent(this.notification.triggered as Meeting, this.selectedTzName));
      }
      // eslint-disable-next-line no-underscore-dangle
      if (this.notification.triggered.__typename === EntityType.MEETING_PARTICIPANT) {
        this.setEventViewed(AgendaStoreHelper.convertMeetingToEvent(
          (this.notification.triggered as MeetingParticipant).meeting as Meeting,
          this.selectedTzName,
        ));
      }
      this.setViewMode(ViewMode.DETAIL);
      this.$eventsBus.emit('ontoolbox', { view: ToolbarMenuActions.TOOLBAR_AGENDA });
    }
  }
}
