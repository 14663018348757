import { Action, Module } from 'vuex-module-decorators';
import LoadableState from '@/store/states/LoadableState';
import { buildMutationDefinition } from '@/graphql/_Tools/GqlMutationDefinition';
import LoadableStore from '@/store/LoadableStore';
import UiOnboardingRepository from '@/repositories/UiOnboardingRepository';
import UiOnboarding from '@/models/graphql/UiOnboarding';
import ClientStorage from '@/utils/ClientStore';

@Module({ namespaced: true })
export default class UiOnboardingStore extends LoadableStore<LoadableState> {
  private readonly uiOnboardingRepository = new UiOnboardingRepository();

  private get repository(): UiOnboardingRepository {
    return this.uiOnboardingRepository;
  }

  @Action
  addSeenOnBoarding(onBoarding: string): Promise<UiOnboarding | undefined> {
    const { authUser } = this.context.rootState;
    const onBoardings = (JSON.parse(ClientStorage.getItem('onboardings') || '[]'));
    onBoardings.push(onBoarding);
    ClientStorage.setItem('onboardings', JSON.stringify(Array.from(new Set(onBoardings))));

    if (authUser && authUser.uid) {
      return this.repository.addSeenOnBoarding({
        definition: buildMutationDefinition([{
          fieldName: 'usersHaveSeen_CommunityUserUid',
          type: 'ID',
          value: authUser.uid,
        },
        {
          fieldName: 'uid',
          type: 'ID!',
          value: onBoarding,
        },
        ]),
      });
    }
    return Promise.resolve(undefined);
  }
}
