










































































































































import { Component, Prop, Watch } from 'vue-property-decorator';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import FileResource from '@/models/graphql/FileResource';
import FileType from '@/utils/enums/FileType';
import StandardModal from '@/components/modals/StandardModal.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import InputText from '@/components/InputText.vue';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';
import { BModal } from 'bootstrap-vue';
import EditFileResourceModal from '@/components/modals/EditFileResourceModal.vue';

@Component({
  components: {
    ButtonComponent,
    StandardModal,
    ButtonIconComponent,
    FontAwesomeComponent,
    InputText,
    EditFileResourceModal,
  },
})
export default class UploadAssetComponent extends BreakpointWrapper {
  @Prop({
    required: false,
    default: '',
  })
  optimalDimensions!: string;

  @Prop({
    required: false,
    default: '',
  })
  private acceptedType!: string;

  @Prop({
    required: false,
    default: null,
  })
  private defaultAssetFileResource!: FileResource | null;

  @Prop({
    required: false,
    default: null,
  })
  private newAssetFileResource!: FileResource | null;

  @Prop({
    required: false,
    default: false,
  })
  private hasError!: boolean;

  @Prop({
    required: false,
    default: 'medium',
  })
  private size!: string;

  @Prop({
    required: false,
    default: '',
  })
  private fileName!: string;

  @Prop({
    required: false,
    default: false,
  })
  private isHandoutAsset!: boolean;

  @Prop({
    required: false,
    default: 0,
  })
  private index!: number;

  @Prop({ required: false })
  private maxFileSize!: number;

  @Prop({
    required: false,
    default: false,
  })
  private customPicker!: number;

  @Prop({
    required: false,
    default: false,
  })
  private disabled!: boolean;

  @Prop({
    required: false,
    default: false,
  })
  private resetElement!: number;

  private isActive = false;

  private isAnImageThumb = false;

  private isAFileThumb = false;

  private file: File | null = null;

  private isError = this.hasError;

  private inputElement: HTMLInputElement | null = null;

  private dropZoneElement: HTMLElement | null = null;

  private localHandoutName = this.fileName;

  private uploadingImage = false;

  private imagePayloadTooLarge = false;

  private imagePath = '';

  private get fileThumb(): { icon: string; iconColor: string; name: string; type: string } | null {
    let config: { icon: string; iconColor: string; name: string; type: string } | null = null;
    if (this.file) {
      switch (this.file.type) {
        case FileType.PDF:
          config = {
            icon: 'fa-file-pdf',
            iconColor: 'text-red-r-3-primary-red',
            name: this.file.name,
            type: 'PDF',
          };
          break;
        case FileType.PPT:
          config = {
            icon: 'fa-file-chart-pie',
            iconColor: 'text-yellow-y-3-primary-yellow',
            name: this.file.name,
            type: 'PPT',
          };
          break;
        case FileType.PPTX:
          config = {
            icon: 'fa-file-chart-pie',
            iconColor: 'text-yellow-y-3-primary-yellow',
            name: this.file.name,
            type: 'PPTX',
          };
          break;
        case FileType.DOC:
          config = {
            icon: 'fa-file-lines',
            iconColor: 'text-blue-b-3-primary-blue',
            name: this.file.name,
            type: 'DOC',
          };
          break;
        case FileType.DOCX:
          config = {
            icon: 'fa-file-lines',
            iconColor: 'text-blue-b-3-primary-blue',
            name: this.file.name,
            type: 'DOCX',
          };
          break;
        case FileType.XLS:
          config = {
            icon: 'fa-file-spreadsheet',
            iconColor: 'text-green-g-3-primary-green',
            name: this.file.name,
            type: 'XLS',
          };
          break;
        case FileType.XLSX:
          config = {
            icon: 'fa-file-spreadsheet',
            iconColor: 'text-green-g-3-primary-green',
            name: this.file.name,
            type: 'XLSX',
          };
          break;
        case FileType.ZIP:
          config = {
            icon: 'fa-file-zipper',
            iconColor: 'text-neutral-n-6-label',
            name: this.file.name,
            type: 'ZIP',
          };
          break;
        case FileType.GZ:
          config = {
            icon: 'fa-file-zipper',
            iconColor: 'text-neutral-n-6-label',
            name: this.file.name,
            type: 'GZ',
          };
          break;
        case FileType.RAR:
          config = {
            icon: 'fa-file-zipper',
            iconColor: 'text-neutral-n-6-label',
            name: this.file.name,
            type: 'RAR',
          };
          break;
        case FileType.TAR:
          config = {
            icon: 'fa-file-zipper',
            iconColor: 'text-neutral-n-6-label',
            name: this.file.name,
            type: 'TAR',
          };
          break;
        case FileType.CSV:
          config = {
            icon: 'fa-file-csv',
            iconColor: 'text-neutral-n-6-label',
            name: this.file.name,
            type: 'CSV',
          };
          break;
        case FileType.TXT:
          config = {
            icon: 'fa-file-lines',
            iconColor: 'text-neutral-n-6-label',
            name: this.file.name,
            type: 'TXT',
          };
          break;
        case FileType.MP4:
          config = {
            icon: 'fa-file',
            iconColor: 'text-neutral-n-6-label',
            name: this.file.name,
            type: 'MP4',
          };
          break;
        case FileType.WEBM:
          config = {
            icon: 'fa-file',
            iconColor: 'text-neutral-n-6-label',
            name: this.file.name,
            type: 'WEBM',
          };
          break;
        default:
          config = {
            icon: 'fa-file',
            iconColor: 'text-neutral-n-6-label',
            name: this.file.name,
            type: this.file.name.slice(this.file.name.lastIndexOf('.') + 1, this.file.name.length),
          };
      }
    }
    return config;
  }

  onHandoutNameCancel(): void {
    this.isError = false;
    this.file = null;
    this.isAFileThumb = false;
    this.isAnImageThumb = false;
    if (this.inputElement && this.inputElement.files) {
      this.inputElement.value = '';
    }

    // eslint-disable-next-line no-unused-expressions
    this.dropZoneElement?.querySelector('.drop-zone__thumb')
        ?.remove();
    if (this.defaultAssetFileResource && this.dropZoneElement) {
      this.updateDefaultFileResource();
    }
    this.$bvModal.hide(`${this.index}-name-document`);
  }

  onHandoutNameConfirm(): void {
    this.getBase64();
    this.$bvModal.hide(`${this.index}-name-document`);
  }

  onSave(): void {
    if (this.isHandoutAsset) {
      this.localHandoutName = this.file?.name ?? '';
      this.$bvModal.show(`${this.index}-name-document`);
    } else {
      this.getBase64();
    }
  }

  mounted(): void {
    this.$nextTick(() => {
      this.inputElement = this.$el.querySelector('.drop-zone__input');
      if (this.inputElement) {
        this.dropZoneElement = this.inputElement.closest('.drop-zone');
        if (this.dropZoneElement) {
          this.updateDefaultFileResource();
          this.dropZoneElement.addEventListener('click', () => {
            if (this.inputElement) {
              this.inputElement.click();
            }
          });

          this.inputElement.addEventListener('change', () => {
            if (this.inputElement && this.inputElement.files && this.inputElement.files.length) {
              // eslint-disable-next-line prefer-destructuring
              this.file = this.inputElement.files[0];
              if (this.file) {
                this.onSave();
              }
              this.updateThumbnail(this.dropZoneElement as HTMLElement);
            }
          });

          this.dropZoneElement.addEventListener('dragover', (e) => {
            e.preventDefault();
            if (this.dropZoneElement) {
              this.isActive = true;
            }
          });

          ['dragleave', 'dragend'].forEach((type) => {
            if (this.dropZoneElement) {
              this.dropZoneElement.addEventListener(type, () => {
                if (this.dropZoneElement) {
                  this.isActive = false;
                }
              });
            }
          });

          this.dropZoneElement.addEventListener('drop', (e) => {
            e.preventDefault();
            const { dataTransfer } = e as unknown as { dataTransfer: DataTransfer };
            if (this.inputElement && dataTransfer.files.length) {
              this.inputElement.files = dataTransfer.files;
              // eslint-disable-next-line prefer-destructuring
              this.file = dataTransfer.files[0];
              if (this.file) {
                this.onSave();
              }
              this.updateThumbnail(this.dropZoneElement as HTMLElement);
            }
            if (this.dropZoneElement) {
              this.dropZoneElement.classList.remove('drop-zone--over');
            }
          });
        }
      }
    });
  }

  // eslint-disable-next-line class-methods-use-this
  async createFile(resource: FileResource): Promise<File> {
    const fileResource = FileResourceHelper.getFullPath(resource);
    const response = await fetch(fileResource);
    const data = await response.blob();
    return new File([data], resource.fileName as string, { type: resource.fileType });
  }

  clear(): void {
    this.isError = false;
    this.file = null;
    this.isAFileThumb = false;
    this.isAnImageThumb = false;
    this.imagePath = '';
    if (this.inputElement && this.inputElement.files) {
      this.inputElement.value = '';
    }

    // eslint-disable-next-line no-unused-expressions
    this.dropZoneElement?.querySelector('.drop-zone__thumb')
        ?.remove();
    this.$emit('on-file-upload', {
      readerResult: null,
      fileName: '',
      fileType: '',
    });
    this.uploadingImage = false;
    this.$bvModal.hide(`${this.index}-edit-image-asset`);
  }

  onLocalImageSave(): void {
    this.uploadingImage = true;
    const ref = `${this.index}_uploadCustomImage`;
    const dataURL = (this.$refs[ref] as EditFileResourceModal).showImage();
    fetch(dataURL)
      .then((res) => res.blob())
      .then((blob) => {
        const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];
        this.file = new File([blob], 'image.jpg', { type: mimeString });
        const reader = new FileReader();
        reader.readAsDataURL(this.file);
        reader.onload = (): void => {
          this.imagePath = (reader.result) ? reader.result as string : '';
        };
        this.updateThumbnail(this.dropZoneElement as HTMLElement);
        this.onSave();
      });
  }

  onLocalImageDelete(): void {
    this.uploadingImage = true;
    this.clear();
  }

  hasFile(): boolean {
    return !!this.file;
  }

  @Watch('resetElement')
  private resetAsset(): void {
    this.clear();
  }

  @Watch('defaultAssetFileResource')
  private updateDefaultFileResource(): void {
    if (this.defaultAssetFileResource && this.dropZoneElement) {
      this.createFile(this.defaultAssetFileResource)
        .then((file) => {
          this.file = file;
          const reader = new FileReader();
          reader.readAsDataURL(this.file);
          reader.onload = (): void => {
            this.imagePath = (reader.result) ? reader.result as string : '';
          };
          this.updateThumbnail(this.dropZoneElement as HTMLElement);
        });
    } else {
      this.clear();
    }
  }

  @Watch('fileName')
  private updateHandoutName(): void {
    this.localHandoutName = this.fileName;
  }

  @Watch('newAssetFileResource')
  private resetFileResource(): void {
    if (!this.newAssetFileResource && !this.defaultAssetFileResource) {
      this.clear();
    }
  }

  private openModal(): void {
    if (!this.disabled) {
      const ref = `${this.index}_uploadCustomImage`;
      ((this.$refs[ref] as EditFileResourceModal).$children[0] as BModal).show();
    }
  }

  private updateThumbnail(dropZoneElement: HTMLElement): void {
    if (this.file && this.maxFileSize && this.maxFileSize < this.file.size) {
      return;
    }

    let thumbnailElement = dropZoneElement.querySelector('.drop-zone__thumb') as HTMLElement;
    if (dropZoneElement && dropZoneElement.querySelector('.drop-zone__prompt')) {
      // eslint-disable-next-line no-unused-expressions
      dropZoneElement.querySelector('.drop-zone__prompt')
          ?.remove();
    }
    if (!thumbnailElement) {
      thumbnailElement = document.createElement('div');
      thumbnailElement.classList.add('drop-zone__thumb');
      dropZoneElement.appendChild(thumbnailElement);
    }
    if (thumbnailElement && this.file) {
      thumbnailElement.dataset.label = this.file.name;
    }
    this.isAnImageThumb = false;
    this.isAFileThumb = false;
    if (this.file && this.file.type.startsWith('image/')) {
      this.isAnImageThumb = true;
      const reader = new FileReader();
      reader.readAsDataURL(this.file);
      reader.onload = () => {
        thumbnailElement.style.display = 'block';
        thumbnailElement.style.backgroundImage = `url('${reader.result}')`;
      };
    } else if (thumbnailElement) {
      this.isAFileThumb = true;
      thumbnailElement.style.display = 'none';
    }
  }

  private getBase64(): void {
    if (this.file
        && (this.acceptedType.length === 0 || (this.acceptedType.length > 0
            && this.acceptedType.indexOf(this.file.type) > -1))) {
      if (this.maxFileSize !== 0 && this.file.size > this.maxFileSize) {
        this.isError = true;
        this.$emit('on-file-size-error');
      } else {
        const reader = new FileReader();
        reader.readAsDataURL(this.file);
        reader.onload = (): void => {
          this.$emit('on-file-upload', {
            readerResult: reader.result,
            fileName: this.file?.name,
            fileType: this.file?.type,
            name: this.localHandoutName,
          });
        };
        this.uploadingImage = false;
        this.$bvModal.hide(`${this.index}-edit-image-asset`);
        reader.onerror = () => {
          this.isError = true;
        };
      }
    } else {
      this.isError = true;
    }
  }
}
