/* eslint-disable @typescript-eslint/camelcase,no-underscore-dangle */
import ActionButtonType from '@/utils/enums/ActionButtonType';
import GqlEntityFilterType from '@/utils/enums/gql/GqlEntityFilterType';
import UiPageWidget from '@/models/graphql/UiPageWidget';
import { Data } from '@/utils/types/WidgetData';
import BaseModel from '@/models/BaseModel';
import CommunityUser from '@/models/graphql/CommunityUser';
import Geozone from '@/models/graphql/Geozone';
import Exhibitor from '@/models/graphql/Exhibitor';
import Booth from '@/models/graphql/Booth';
import Product from '@/models/graphql/Product';
import LargeProduct from '@/models/graphql/LargeProduct';
import Deal from '@/models/graphql/Deal';
import Article from '@/models/graphql/Article';
import Session from '@/models/graphql/Session';
import Speaker from '@/models/graphql/Speaker';
import Survey from '@/models/graphql/Survey';
import ActionRule from '@/utils/enums/ActionRule';
import EntityType from '@/utils/enums/EntityType';
import * as StringHelper from '@/utils/helpers/StringHelper';
import { replaceMagicArgs } from '@/graphql/_Tools/GqlGeneric';
import { fragments } from '@/graphql/_Fragments/FragmentMapping';
import { buildQueryDefinition } from '@/graphql/_Tools/GqlQueryDefinition';
import { ActionContext } from 'vuex';
import GqlComposeQueryDefinitionParams from '@/utils/types/gql/GqlComposeQueryDefinitionParams';
import LoadableState from '@/store/states/LoadableState';
import RootState from '@/store/states/RootState';
import { GQL_ALIAS } from '@/utils/constants/Regex';
import { deepSearch } from '@/utils/ObjectHelpers';
import Slot from '@/models/graphql/Slot';
import * as Sentry from '@sentry/browser';
import { Severity } from '@sentry/browser';
import GroupType from '@/utils/enums/chat/GroupType';

export class ActionButtonWidgetHelper {
  private static entityToActionButtonFilterMap: {
    [entityType: string]: {
      defaultFilter: {};
      defaultFilterType: GqlEntityFilterType;
      customFilter?: {
        [actionButtonType: string]: {
          operation?: string;
          filter: {};
          filterType: GqlEntityFilterType;
        };
      };
    };
  } = {
    exhibitor: {
      defaultFilter: { uid: '%uid%' },
      defaultFilterType: GqlEntityFilterType.EXHIBITOR_FILTER,
      customFilter: {
        viewOnMap: {
          filter: {
            uid: '%uid%',
          },
          filterType: GqlEntityFilterType.EXHIBITOR_FILTER,
        },
        feedback: {
          operation: 'survey',
          filter: {
            strategy: 'exhibitorSurvey',
          },
          filterType: GqlEntityFilterType.SURVEY_FILTER,
        },
        message: {
          filter: {
            uid: '%uid%',
          },
          filterType: GqlEntityFilterType.EXHIBITOR_FILTER,
        },
      },
    },
    product: {
      defaultFilter: { uid: '%uid%' },
      defaultFilterType: GqlEntityFilterType.PRODUCT_FILTER,
      customFilter: {
        viewOnMap: {
          operation: 'exhibitor',
          filter: {
            products: { uid: '%uid%' },
          },
          filterType: GqlEntityFilterType.PRODUCT_FILTER,
        },
        feedback: {
          operation: 'survey',
          filter: {
            strategy: 'productSurvey',
          },
          filterType: GqlEntityFilterType.SURVEY_FILTER,
        },
        message: {
          filter: {
            uid: '%uid%',
          },
          filterType: GqlEntityFilterType.PRODUCT_FILTER,
        },
      },
    },
    largeProduct: {
      defaultFilter: { uid: '%uid%' },
      defaultFilterType: GqlEntityFilterType.LARGE_PRODUCT_FILTER,
      customFilter: {
        viewOnMap: {
          filter: {
            uid: '%uid%',
            editionMappings_some: {
              editionLargeProduct_some: {
                schemaCode: '%schemaCodeViewMap%',
              },
            },
          },
          filterType: GqlEntityFilterType.LARGE_PRODUCT_FILTER,
        },
      },
    },
    deal: {
      defaultFilter: { uid: '%uid%' },
      defaultFilterType: GqlEntityFilterType.DEAL_FILTER,
      customFilter: {
        viewOnMap: {
          operation: 'exhibitor',
          filter: {
            deals: { uid: '%uid%' },
          },
          filterType: GqlEntityFilterType.EXHIBITOR_FILTER,
        },
      },
    },
    session: {
      defaultFilter: { uid: '%uid%' },
      defaultFilterType: GqlEntityFilterType.SESSION_FILTER,
    },
    speaker: {
      defaultFilter: { uid: '%uid%' },
      defaultFilterType: GqlEntityFilterType.SPEAKER_FILTER,
      customFilter: {
        feedback: {
          filter: { strategy: 'speakerSurvey' },
          filterType: GqlEntityFilterType.SURVEY_FILTER,
        },
      },
    },
    communityUser: {
      defaultFilter: { uid: '%uid%' },
      defaultFilterType: GqlEntityFilterType.COMMUNITY_USER_FILTER,
    },
    subEdition: {
      defaultFilter: { code: '%uid%' },
      defaultFilterType: GqlEntityFilterType.SUB_EDITION_FILTER,
    },
    channel: {
      defaultFilter: { uid: '%uid%' },
      defaultFilterType: GqlEntityFilterType.CHANNEL_FILTER,
    },
    article: {
      defaultFilter: { uid: '%uid%' },
      defaultFilterType: GqlEntityFilterType.ARTICLE_FILTER,
    },
    magazineArticle: {
      defaultFilter: { uid: '%uid%' },
      defaultFilterType: GqlEntityFilterType.ARTICLE_FILTER,
    },
  };

  public static mapActionButton(widget: UiPageWidget, rawData: {
    value: Data;
    key: string;
  }[]): Data[] {
    const actionButtons: Data[] = [];
    let largeProduct = null;
    let messageGroupData: Data | undefined;
    let communityUser = null;
    let exhibitor = null;
    let data = null;
    let actions: { key: string; value: string }[] = [];
    if (widget && widget.subWidgets && widget.subWidgets.length > 0) {
      widget.subWidgets.forEach((w, index) => {
        let result = {
          typename: '',
        } as unknown as BaseModel;
        let actionResult: string | CommunityUser | Geozone[] | Exhibitor | Booth[]
          | Product | LargeProduct | Deal | Article | Session | Speaker | Survey
          | null | Record<string, Data[]> = null;
        let filterSlotActionResult: { type: string; limit: number; code: string }[] | null = null;
        const genericActionResult: Record<string, Data[]> = {};
        let autoFollow = false;
        let component: object | null = null;
        let { actionType } = JSON.parse(w.payload || '{}');
        actionType = (actionType as string)[0].toLowerCase() + (actionType as string).substr(1);
        switch (actionType) {
          case ActionButtonType.BOOKMARK:
            data = rawData.find(({ key }) => key.endsWith('bookmark'))?.value;
            if (data && '_actions' in data) {
              actions = data._actions as { key: string; value: string }[];

              if (data && actions && actions.length > 0) {
                const canBookmarkAction = actions.find((action) => action.key === ActionRule.CAN_BOOKMARK);
                if (canBookmarkAction && canBookmarkAction.value === 'true') {
                  actionResult = data._isBookmarked as string | null;
                  component = () => import('@/components/action-buttons/ActionButtonBookmark.vue');
                }
              }
            } else if (data) {
              actionResult = data._isBookmarked as string | null;
              component = () => import('@/components/action-buttons/ActionButtonBookmark.vue');
            }
            break;
          case ActionButtonType.URL:
          case ActionButtonType.LARGE_PRODUCT_URL:
            component = () => import('@/components/action-buttons/ActionButtonLink.vue');
            break;
          case ActionButtonType.VISIT:
            data = rawData.find(({ key }) => key.endsWith('visit'))?.value;
            actionResult = (data as Data)._isVisited as string | null;
            component = () => import('@/components/action-buttons/ActionButtonVisit.vue');
            break;
          case ActionButtonType.AGENDA:
            data = rawData.find(({ key }) => key.endsWith('addToAgenda'))?.value;
            actionResult = (data as Data)._isInAgenda as string | null;
            component = () => import('@/components/action-buttons/ActionButtonAgenda.vue');
            break;
          case ActionButtonType.MAP:
            data = rawData.find(({ key }) => key.endsWith('viewOnMap'))?.value;
            result = data as unknown as BaseModel;
            if (!result) {
              break;
            }
            if ((result as unknown as { typename: string }).typename === EntityType.LARGE_PRODUCT) {
              largeProduct = data as unknown as LargeProduct;
              actionResult = largeProduct?.editionMappings?.[0].editionLargeProduct?.booths
                ? largeProduct.editionMappings[0].editionLargeProduct?.booths as unknown as Booth[]
                : [];
            } else {
              actionResult = (data as Data)._geozonesOfEdition as unknown as Geozone[];
            }
            component = () => import('@/components/action-buttons/ActionButtonViewOnMap.vue');
            break;
          case ActionButtonType.CONNECT:
            data = rawData.find(({ key }) => key.endsWith('connect'))?.value;
            communityUser = data as unknown as CommunityUser;
            if (communityUser && communityUser._actions) {
              const canConnectAction = communityUser._actions.find((action) => action.key === ActionRule.CAN_CONNECT);
              if (canConnectAction && canConnectAction.value === 'true') {
                actionResult = communityUser;
                component = () => import('@/components/action-buttons/ActionButtonConnect.vue');
              }
            }
            break;
          case ActionButtonType.FOLLOW:
            data = rawData.find(({ key }) => key.endsWith('follow'))?.value;
            if (!data) {
              ActionButtonWidgetHelper.logError('GQL Data for Follow Action Button not found!');
              break;
            }
            autoFollow = (data as Data).autoFollow as boolean;
            actionResult = (data as Data)._isFollowed as string | null;
            component = () => import('@/components/action-buttons/ActionButtonFollow.vue');
            break;
          case ActionButtonType.MEET:
            data = rawData.find(({ key }) => key.endsWith('createMeeting'))?.value;
            result = data as unknown as BaseModel;
            if (!result) {
              break;
            }
            if ((result as unknown as { typename: string }).typename === EntityType.USER) {
              communityUser = data as unknown as CommunityUser;
              if (communityUser && communityUser._actions) {
                const canMeetAction = communityUser._actions.find((action) => action.key === ActionRule.CAN_MEET);
                if (canMeetAction && canMeetAction.value === 'true') {
                  actionResult = communityUser;
                  component = () => import('@/components/action-buttons/ActionButtonMeet.vue');
                }
              }
            }
            if ((result as unknown as { typename: string }).typename === EntityType.COMPANY
              || (result as unknown as { typename: string }).typename === EntityType.EXHIBITOR) {
              exhibitor = data as unknown as Exhibitor;
              actionResult = exhibitor;
              component = () => import('@/components/action-buttons/ActionButtonMeet.vue');
            }
            data = rawData.find(({ key }) => key.endsWith('createMeeting_filterSlots'))?.value;
            filterSlotActionResult = ActionButtonWidgetHelper.assignFilterSlots(data);
            break;
          case ActionButtonType.COMPANY_INVITE:
            component = () => import('@/components/action-buttons/ActionButtonCompanyInvite.vue');
            break;
          case ActionButtonType.MESSAGE:
            messageGroupData = rawData.find(({ key }) => key.endsWith('messageGroup'))?.value;
            data = rawData.find(({ key }) => key.endsWith('message'))?.value;
            actionResult = data as unknown as CommunityUser | Exhibitor;
            component = () => import('@/components/action-buttons/ActionButtonMessage.vue');
            data = rawData.find(({ key }) => key.endsWith('message_filterSlots'))?.value;
            filterSlotActionResult = ActionButtonWidgetHelper.assignFilterSlots(data);
            break;
          case ActionButtonType.LINK:
            component = () => import('@/components/action-buttons/ActionButtonLink.vue');
            break;
          case ActionButtonType.BLOCK:
            data = rawData.find(({ key }) => key.endsWith('block'))?.value;
            actionResult = data as unknown as CommunityUser;
            component = () => import('@/components/action-buttons/ActionButtonBlock.vue');
            break;
          case ActionButtonType.ADD_NOTE:
            data = rawData.find(({ key }) => key.endsWith('addNote'))?.value;
            actionResult = data as unknown as CommunityUser | Product | LargeProduct
              | Deal | Article | Exhibitor | Session | Speaker;
            component = () => import('@/components/action-buttons/ActionButtonNote.vue');
            break;
          case ActionButtonType.SHARE:
            component = () => import('@/components/action-buttons/ActionButtonShare.vue');
            break;
          case ActionButtonType.LINK_PROFILE_SPEAKER:
            data = rawData.find(({ key }) => key.endsWith('linkUserSpeaker'))?.value;
            actionResult = data as unknown as CommunityUser | Speaker;
            component = () => import('@/components/action-buttons/ActionButtonLinkUserSpeaker.vue');
            break;
          case ActionButtonType.FEEDBACK:
            data = rawData.find(({ key }) => key.endsWith('feedback'))?.value;
            actionResult = data as unknown as Session;
            component = () => import('@/components/action-buttons/ActionButtonFeedback.vue');
            break;
          case ActionButtonType.TAGS:
            data = rawData.find(({ key }) => key.endsWith('entityTags'))?.value;
            actionResult = data as unknown as CommunityUser | Product | LargeProduct
              | Deal | Article | Exhibitor | Session | Speaker;
            component = () => import('@/components/action-buttons/ActionButtonTags.vue');
            break;
          case ActionButtonType.GENERIC:
            data = rawData.filter(({ key }) => {
              const keys = key.split('_');
              return (keys.length > 1 && keys[1] === 'generic');
            })
              .map(({ value }) => value);
            data.forEach((d) => {
              Object.assign(genericActionResult as Record<string, Data[]>, d);
            });
            component = () => import('@/components/action-buttons/ActionButtonGeneric.vue');
            break;
          case ActionButtonType.SHARE_CONTACT:
            data = rawData.find(({ key }) => key.endsWith('shareContact_filterSlots'))?.value;
            filterSlotActionResult = ActionButtonWidgetHelper.assignFilterSlots(data);
            component = () => import('@/components/action-buttons/ActionButtonShareInformation.vue');
            break;
          default:
            break;
        }
        if (result && ActionButtonWidgetHelper.isValidActionButtonType(actionType as string)) {
          const widgetId = index + 1;
          actionButtons.push({
            ...JSON.parse(widget.payload || '{}'),
            ...JSON.parse(w.payload || '{}'),
            actionResult,
            filterSlotActionResult,
            genericActionResult,
            autoFollow,
            component,
            messageGroupData,
            index: widgetId,
            entityTypeName: (result as unknown as { typename: string }).typename,
          });
        }
      });
    }

    return actionButtons;
  }

  // eslint-disable-next-line max-params
  public static constructActionButtonsQueries(
    actionButtonWidgets: Array<UiPageWidget>,
    entityType: string, entityCode: string,
    widgetStoreName: string,
    communityCode?: string,
    context?: ActionContext<LoadableState, RootState>,
  ): Array<GqlComposeQueryDefinitionParams> {
    const configs: Array<GqlComposeQueryDefinitionParams> = [];
    actionButtonWidgets.forEach((subWidget) => {
      const {
        actionType,
        graphql,
      } = JSON.parse(subWidget.payload || '{}');
      if (ActionButtonWidgetHelper.doesActionButtonNeedGqlData(actionType) && communityCode) {
        const actionButtonDataConfig = ActionButtonWidgetHelper.createActionButtonDataConfig(
          actionType, entityType, entityCode, communityCode,
        );
        if (actionButtonDataConfig && !fragments[actionButtonDataConfig.fragmentName]) {
          ActionButtonWidgetHelper.logError(`Action button ${actionButtonDataConfig.actionType} does not
            have a fragment "${actionButtonDataConfig.fragmentName}"`);
        }
        if (actionButtonDataConfig && fragments[actionButtonDataConfig.fragmentName]) {
          if (actionType === ActionButtonType.MAP) {
            const {
              schemaCode,
            } = JSON.parse(subWidget.payload || '{}');
            configs.push({
              gqlDefinition: buildQueryDefinition({
                cacheable: !deepSearch(actionButtonDataConfig.variables.filter, '%authUser%'),
                magicArgs: { schemaCodeViewMap: schemaCode },
                filter: {
                  value: actionButtonDataConfig.variables.filter,
                  type: actionButtonDataConfig.filterType,
                },
              }),
              fragmentName: actionButtonDataConfig.fragmentName,
              operation: actionButtonDataConfig.operation,
              alias: `${widgetStoreName}_${actionButtonDataConfig.actionType}`,
            });
          } else {
            if (actionType === ActionButtonType.MESSAGE && entityType === 'session') {
              configs.push({
                gqlDefinition: buildQueryDefinition({
                  cacheable: !deepSearch(actionButtonDataConfig.variables.filter, '%authUser%'),
                  filter: {
                    value: {
                      target: {
                        uid: entityCode,
                      },
                      type: GroupType.GROUP,
                    },
                    type: GqlEntityFilterType.MESSAGE_GROUP_FILTER,
                  },
                }),
                fragmentName: 'sessionActionButtonMessageGroupFragment',
                operation: 'messageGroup',
                alias: `${widgetStoreName}_messageGroup`,
              });
            }
            configs.push({
              gqlDefinition: buildQueryDefinition({
                cacheable: !deepSearch(actionButtonDataConfig.variables.filter, '%authUser%'),
                filter: {
                  value: actionButtonDataConfig.variables.filter,
                  type: actionButtonDataConfig.filterType,
                },
              }),
              fragmentName: actionButtonDataConfig.fragmentName,
              operation: actionButtonDataConfig.operation,
              alias: `${widgetStoreName}_${actionButtonDataConfig.actionType}`,
            });
          }
        }
      } else if (graphql && actionType === ActionButtonType.GENERIC) {
        const rawGraphQLQueries = (graphql as string).split('|');
        let genericQuery = '';
        rawGraphQLQueries.forEach((rawGraphQLQuery, index) => {
          if (GQL_ALIAS.test(rawGraphQLQuery)) {
            const alias = rawGraphQLQuery.match(GQL_ALIAS);
            if (alias && alias.length > 0) {
              genericQuery += ` ${rawGraphQLQuery
                .replaceAll(GQL_ALIAS, `${widgetStoreName}_generic_${alias[0]}`)}`;
            }
          } else {
            genericQuery += ` ${widgetStoreName}_generic_data${index}: ${rawGraphQLQuery}`;
          }
        });
        if (genericQuery.trim().length > 0) {
          if (context) {
            context.commit(
              'WidgetDispatcherStore/setGqlQuery',
              {
                key: widgetStoreName,
                value: genericQuery,
              },
              { root: true },
            );
          }
        }
      }
      if (ActionButtonWidgetHelper.doesActionButtonHasFilterSlot(actionType, entityType as EntityType)
        && communityCode) {
        const variables = ActionButtonWidgetHelper.createActionButtonDataVariables(
          actionType, entityType, entityCode, communityCode,
        );
        if (variables) {
          const fragmentName = ActionButtonWidgetHelper.generateFilterSlotFragmentName(entityType as EntityType,
            actionType as ActionButtonType);
          configs.push({
            gqlDefinition: buildQueryDefinition({
              cacheable: true,
              filter: {
                value: variables.filter,
                type: GqlEntityFilterType.EXHIBITOR_FILTER,
              },
            }),
            fragmentName,
            operation: ActionButtonWidgetHelper.convertFirstLetterToLowerCase(entityType),
            alias: `${widgetStoreName}_${actionType}_filterSlots`,
          });
        }
      }
    });
    if (context) {
      context.commit(
        'WidgetDispatcherStore/setMagicArgs',
        { schemaCode: communityCode },
        { root: true },
      );
    }
    return configs;
  }

  private static logError(message: string): void {
    Sentry.withScope((scope) => {
      scope.setLevel(Severity.Error);
      Sentry
        .captureException(message);
    });
  }

  private static createActionButtonDataConfig(
    actionType: ActionButtonType,
    entityType: string,
    entityCode: string,
    schemaCode: string,
  ): {
    actionType: string;
    fragmentName: string;
    operation: string;
    variables: { filter: {} };
    filterType: string;
  } | null {
    let fragmentName = '';
    let operation = '';
    let filterType = '';
    let variables = { filter: {} };
    if (ActionButtonWidgetHelper.doesActionButtonNeedGqlData(actionType)) {
      const entityTypeName = entityType === 'magazineArticle' ? 'article' : entityType;
      fragmentName = `${entityTypeName}ActionButton${StringHelper.capitalize(actionType)}Fragment`;
      operation = entityTypeName;
      if (!this.entityToActionButtonFilterMap[entityType]) {
        ActionButtonWidgetHelper.logError(`Entity type "${entityType}" does not have action buttons support!`);
        return null;
      }
      const customFilter = this.entityToActionButtonFilterMap[entityType].customFilter?.[actionType];
      if (customFilter) {
        filterType = customFilter.filterType;
        operation = customFilter.operation || entityType;
      } else {
        filterType = this.entityToActionButtonFilterMap[entityType].defaultFilterType;
      }
      variables = ActionButtonWidgetHelper.createActionButtonDataVariables(
        actionType, entityType, entityCode, schemaCode,
      ) || { filter: {} };
    }
    return {
      actionType,
      fragmentName,
      operation,
      variables,
      filterType,
    };
  }

  private static createActionButtonDataVariables(actionType: ActionButtonType, entityType: string,
    entityCode: string, schemaCode: string): { filter: {} } | null {
    let variables = { filter: {} };
    if (!this.entityToActionButtonFilterMap[entityType]) {
      ActionButtonWidgetHelper.logError(`Entity type "${entityType}" does not have action buttons support!`);
      return null;
    }
    const customFilter = this.entityToActionButtonFilterMap[entityType].customFilter?.[actionType];
    if (customFilter) {
      variables = {
        filter: customFilter.filter,
      };
    } else {
      variables = {
        filter: this.entityToActionButtonFilterMap[entityType].defaultFilter,
      };
    }
    if (entityCode) {
      variables.filter = JSON.parse(replaceMagicArgs(JSON.stringify(variables.filter), {
        uid: entityCode,
        schemaCode,
      }));
    }
    return variables;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private static assignFilterSlots(data: any): {
    type: string;
    limit: number;
    code: string;
  }[] | null {
    if (data && data.slots) {
      const slots = data.slots as unknown as Slot[];
      if (slots && slots[0]) {
        return [{
          type: slots[0].strategy || '',
          code: slots[0].code || '',
          limit: slots.map((s) => s.quantity || 0)
            .reduce((a, b) => a + b),
        }];
      }
    }
    return null;
  }

  private static convertFirstLetterToLowerCase(inputString: string): string {
    if (inputString.length === 0) {
      return inputString;
    }
    return inputString.charAt(0)
      .toLowerCase() + inputString.slice(1);
  }

  private static doesActionButtonNeedGqlData(actionType: ActionButtonType): boolean {
    return [
      ActionButtonType.BOOKMARK,
      ActionButtonType.VISIT,
      ActionButtonType.AGENDA,
      ActionButtonType.MAP,
      ActionButtonType.CONNECT,
      ActionButtonType.MEET,
      ActionButtonType.BLOCK,
      ActionButtonType.ADD_NOTE,
      ActionButtonType.LINK_PROFILE_SPEAKER,
      ActionButtonType.MESSAGE,
      ActionButtonType.FOLLOW,
      ActionButtonType.FEEDBACK,
    ].includes(actionType);
  }

  private static doesActionButtonHasFilterSlot(actionType: ActionButtonType, entityType: string): boolean {
    switch (entityType.toLocaleLowerCase()) {
      case EntityType.LARGE_PRODUCT.toLocaleLowerCase():
      case EntityType.PRODUCT.toLocaleLowerCase():
      case EntityType.DEAL.toLocaleLowerCase():
        return [
          ActionButtonType.MESSAGE,
        ].includes(actionType);
      case EntityType.EXHIBITOR.toLocaleLowerCase():
        return [
          ActionButtonType.MEET,
          ActionButtonType.MESSAGE,
          ActionButtonType.SHARE_CONTACT,
        ].includes(actionType);
      default:
        return false;
    }
  }

  private static isValidActionButtonType(value: string): boolean {
    return Object.values(ActionButtonType)
      .includes(value as ActionButtonType);
  }

  private static generateFilterSlotFragmentName(entityType: EntityType, actionType: ActionButtonType): string {
    return `slot${entityType.charAt(0)
      .toUpperCase()
      + entityType.slice(1)}${actionType.charAt(0)
      .toUpperCase()
      + actionType.slice(1)}Fragment`;
  }
}
