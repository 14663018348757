










































import { Component, Prop, Watch } from 'vue-property-decorator';
import { Quill, quillEditor } from 'vue-quill-editor';
import { SizeClass } from '@/utils/text-editor/FontSizesAndStyles';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';
import SegmentedControlsComponent from '@/components/SegmentedControlsComponent.vue';
import EntityTranslationParams from '@/utils/types/EntityTranslationParams';
import SegmentedElementParams from '@/utils/types/SegmentedElementParams';
import SizeDigit from '@/utils/enums/SizeDigit';
import { State } from 'vuex-class';
import LocaleModel from '@/models/LocaleModel';
import ClientStorage from '@/utils/ClientStore';

@Component({
  components: {
    SegmentedControlsComponent,
    quillEditor,
  },
})

/* eslint-disable max-len */
export default class TextEditorComponent extends BreakpointWrapper {
  @Prop({ default: false })
  readonly translatable!: boolean;

  @Prop({ default: () => [] })
  readonly translations!: EntityTranslationParams[];

  @Prop({ required: false })
  private fontSizes!: string[];

  @Prop({ required: true })
  private configs!: object;

  @Prop({ required: false, default: '' })
  private initialContent!: string;

  @Prop({ required: false, default: -1 })
  private limit!: number;

  @Prop({ default: '' })
  private readonly variant!: string;

  @Prop({ default: '' })
  private readonly label!: string;

  @Prop({ required: false, default: false })
  private readonly disabled!: boolean;

  @Prop({ required: false, default: true })
  private readonly showToolbar!: boolean;

  @Prop({ required: false, default: true })
  private readonly autofocus!: boolean;

  @State
  private readonly locales!: LocaleModel[];

  private localTabs: SegmentedElementParams[] = [];

  private selectedLocale = ClientStorage.getItem('locale');

  private localContentLength = 0;

  private selectedTranslation: EntityTranslationParams | undefined = this.translations.find((translation) => translation.locale === this.selectedLocale);

  private content = this.initialContent;

  private get maxLimitExceed(): boolean {
    return this.localContentLength > this.limit && !(this.limit === -1);
  }

  mounted(): void {
    Quill.register(SizeClass, false);
    if (this.$refs.editor) {
      this.localContentLength = (this.$refs.editor as unknown as { quill: { root: { textContent: string }} })
        .quill.root.textContent.length;
    }
    if (this.autofocus) {
      this.$nextTick(() => {
        if (this.content === '' && this.$refs.editor) {
          (this.$refs.editor as unknown as { quill: { focus: () => void } }).quill.focus();
        }
      });
    }
    this.buildTabConfig();
  }

  @Watch('initialContent')
  private setContent(): void {
    this.content = this.initialContent;
  }

  private onBlur(data: { root: { innerHTML: string } }): void {
    if (!this.maxLimitExceed && data.root.innerHTML !== this.initialContent) {
      if (this.selectedTranslation) {
        this.selectedTranslation.value = data.root.innerHTML.length > 0 ? data.root.innerHTML : null;
        this.content = data.root.innerHTML;
        this.buildTabConfig();
        this.$emit('on-translation-update', this.selectedTranslation);
      }
      this.$emit('on-blur', { ...data, locale: this.selectedLocale ?? undefined });
    }
  }

  private onChange(data: { html: string; text: string }): void {
    this.localContentLength = data.text.length - 1;
    if (!this.maxLimitExceed) {
      if (this.selectedTranslation) {
        this.selectedTranslation.value = data.html.length > 0 ? data.html : null;
        this.content = data.html;
        this.buildTabConfig();
        this.$emit('on-translation-update', this.selectedTranslation);
      }
      this.$emit('on-change', { ...data, locale: this.selectedLocale ?? undefined });
    }
  }

  private switchTab(tab: SegmentedElementParams): void {
    this.selectedLocale = tab.key;
    this.selectedTranslation = this.translations.find((t) => t.locale === tab.key);
    if (this.selectedTranslation) {
      this.content = this.selectedTranslation.value || '';
    }
    this.localTabs.forEach((v) => {
      v.activeState = tab.key === v.key;
    });
    this.buildTabConfig();
    this.selectedTranslation = this.translations.find((t) => t.locale === this.selectedLocale);
    if (this.selectedTranslation) {
      this.content = this.selectedTranslation.value || '';
    }
  }

  private onFocus(): void {
    this.$emit('on-focus');
  }

  private buildTabConfig(): void {
    if (this.translatable && this.translations.length > 0) {
      this.localTabs = this.locales.map((l) => {
        const name = (l.locale as string).split('_')[0];
        const isTranslationMissing = this.translations.findIndex((t) => t.locale === l.locale && t.value && t.value !== '\n' && t.value !== '<p><br></p>') === -1;
        return {
          text: name.charAt(0).toUpperCase() + name.slice(1),
          key: l.locale as string,
          activeState: l.locale === this.selectedLocale,
          icon: isTranslationMissing ? 'far fa-warning' : '',
          iconColor: isTranslationMissing ? 'text-yellow-y-3-primary-yellow' : '',
          size: SizeDigit.S_24,
        };
      });
    }
  }
}
