const mergeDeep = function (...objects) {
  const isObject = (obj) => obj && typeof obj === 'object';

  return objects.reduce((prev, obj) => {
    Object.keys(obj)
      .forEach((key) => {
      const pVal = prev[key];
      const oVal = obj[key];

      if (Array.isArray(pVal) && Array.isArray(oVal)) {
        prev[key] = pVal.concat(...oVal);
      } else if (isObject(pVal) && isObject(oVal)) {
        prev[key] = mergeDeep(pVal, oVal);
      } else {
        prev[key] = oVal;
      }
    });

    return prev;
  }, {});
};


const unflatten = function (flatObject) {
  const result = {};
  for (const key in flatObject) {
    const value = flatObject[key];
    const keys = key.split('.'); // Split keys by '.'
    let current = result;

    keys.forEach((part, index) => {
      if (index === keys.length - 1) {
        // Assign value at the deepest level
        current[part] = value;
      } else {
        // Create object if not present
        current[part] = current[part] || {};
        current = current[part];
      }
    });
  }

  return result;
};

const pathToArray = path =>
  Array.from(path.matchAll(/\[(.*?)\]|[^.[]+/g) || [],
    ([prop, inbrackets]) => inbrackets || prop);

function deepGet(obj, path, defaultVal = null) {
  for (let prop of pathToArray(path)) {
    if (!obj) return null;

    if (!(prop in obj)) return defaultVal;
    obj = obj[prop];
  }
  return obj;
}

const deepEqual = function (x, y) {
  if (x === y) {
    return true;
  } else if (isObject(x) && isObject(y)) {
    if (Object.keys(x).length !== Object.keys(y).length) {
      return false;
    }

    for (let prop in x) {
      if (y.hasOwnProperty(prop)) {
        if (!deepEqual(x[prop], y[prop])) {
          return false;
        }
      } else {
        return false;
      }
    }
    return true;
  } else {
    return false;
  }
}

const deepSearch = function (obj, targetValue) {
  if (obj === targetValue) {
    return true;
  }

  if (Array.isArray(obj)) {
    return obj.some((element) => deepSearch(element, targetValue));
  }

  if (typeof obj === 'object' && obj !== null) {
    return Object.values(obj).some((value) => deepSearch(value, targetValue));
  }

  return false;
}

function isObject(object) {
  return object != null && typeof object === 'object';
}

export { mergeDeep, unflatten, deepGet, isObject, deepEqual, deepSearch };
