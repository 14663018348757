import DateTimeHelper from '@utils/helpers/DateTimeHelper';
import {
  ArgumentNode,
  DocumentNode,
  FieldNode,
  OperationDefinitionNode,
  parse,
  ValueNode,
  visit,
} from 'graphql';
import { BasicTypes } from '@/utils/types/BasicTypes';
import { print } from 'graphql/language/printer';

type GraphQLPrimitive = string | number | boolean | null;
type GraphQLValue = GraphQLPrimitive | GraphQLObject | GraphQLValue[];

interface GraphQLObject {
  [key: string]: GraphQLValue;
}

export default class GraphqlQueryHelper {
  static parseQueryElements(
    query: string,
    params: Record<string, string>,
    authUserUid = '',
    communityCode = '',
  ): string {
    let parseQuery = query.replaceAll(/"*%authUser%"*/g,
      `"${authUserUid}"`);
    parseQuery = parseQuery.replaceAll(/"*%community%"*/g,
      `"${communityCode}"`);
    parseQuery = parseQuery.replaceAll(/"*%now%"*/g, `${DateTimeHelper.currentTimestamp}`);
    const propKeys = Object.keys(params);
    propKeys.forEach((key) => {
      if (params[key]) {
        const regex = new RegExp(`"*%${key}%"*`, 'gm');
        parseQuery = parseQuery.replaceAll(regex, `"${params[key]}"`);
      }
    });
    if (parseQuery.trim()
      .startsWith('{')) {
      parseQuery = parseQuery.trim()
        .slice(1, -1)
        .trim();
    }
    return parseQuery;
  }

  static extractFilterValues(node: ValueNode): Record<string, BasicTypes> | null {
    if (node.kind === 'ObjectValue') {
      const result: Record<string, BasicTypes> = {};
      node.fields.forEach((field) => {
        result[field.name.value] = GraphqlQueryHelper.extractFilterValues(field.value);
      });
      return result;
    }
    if (node.kind === 'ListValue') {
      return node.values.map((value) => GraphqlQueryHelper.extractFilterValues(value)) as unknown as Record<string, BasicTypes>;
    }
    return 'value' in node ? node.value as unknown as Record<string, BasicTypes> : null;
  }

  static extractFilterFromQuery(queryString: string): Record<string, BasicTypes> | null {
    // Parse the GraphQL query string into a DocumentNode
    const parsedQuery: DocumentNode = parse(`{ ${queryString} }`);

    let filter: Record<string, BasicTypes> | null = null;

    // Use the visit function to traverse the query
    visit(parsedQuery, {
      Field: {
        enter(node: FieldNode) {
          const filterArgument = node.arguments?.find((arg) => arg.name.value === 'filter');
          if (filterArgument && filterArgument.value.kind === 'ObjectValue') {
            filter = GraphqlQueryHelper.extractFilterValues(filterArgument.value);
          }
        },
      },
    });

    return filter;
  }

  static appendArgumentToQuery(
    queryString: string,
    argumentName: string,
    argumentType: string,
    argumentValue: BasicTypes,
  ): string {
    const ast = parse(`{ ${queryString} }`);
    const visitor = {
      OperationDefinition(node: OperationDefinitionNode) {
        if (node.operation === 'query'
          && node.selectionSet
          && node.selectionSet.selections.length > 0) {
          const newArgument = {
            kind: 'Argument',
            name: {
              kind: 'Name',
              value: argumentName,
            },
            value: {
              kind: argumentType,
              value: argumentValue,
            },
          } as ArgumentNode;
          if ((node.selectionSet.selections[0] as FieldNode).arguments) {
            ((node.selectionSet.selections[0] as FieldNode).arguments as ArgumentNode[]).push(newArgument);
          } else {
            ((node.selectionSet.selections[0] as FieldNode).arguments as unknown as ArgumentNode[]) = [newArgument];
          }
        }
      },
    };

    const modifiedAst = visit(ast, visitor);
    const modifiedQueryString = print(modifiedAst)
      .trim();

    return modifiedQueryString
      .substr(1, modifiedQueryString.length - 2)
      .trim();
  }

  static parseJSONToGraphQLFilter(jsonString: string): string {
    if (!jsonString.trim()) {
      return '';
    }
    try {
      const jsonObject: GraphQLObject = JSON.parse(jsonString);

      const formatObjectToGraphQL = (obj: GraphQLObject): string => Object.entries(obj)
        .map(([key, value]) => {
          if (value === null) {
            return `${key}: null`;
          }
          if (typeof value === 'object' && !Array.isArray(value)) {
            return `${key}: { ${formatObjectToGraphQL(value)} }`;
          }
          if (Array.isArray(value)) {
            return `${key}: [${value.map((item) => JSON.stringify(item))
              .join(', ')}]`; // Handle arrays
          }
          return `${key}: ${JSON.stringify(value)}`;
        })
        .join(', ');

      return `filter: { ${formatObjectToGraphQL(jsonObject)} }`;
    } catch (error) {
      throw new Error(`Invalid JSON string: ${error.message}`);
    }
  }
}
