var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.menuList && _vm.menuList.length > 0)?_c('div',{staticClass:"toolbar-wrapper"},[(_vm.isDesktop)?_c('transition',{attrs:{"name":"fade"}},[_c('div',{staticClass:"tool-bar-container d-flex h-100"},[_c('div',{staticClass:"list d-flex flex-column justify-content-center"},[_vm._l((_vm.menuList),function(menu){return [_c('div',_vm._b({key:menu.uid,staticClass:"tool-item rounded-circle d-flex align-items-center justify-content-center\n                    icon-container position-relative",class:(_vm.currentToolboxModal === menu.action) ? 'active': '',attrs:{"id":'tool-item-'+menu.uid},on:{"click":function($event){return _vm.doAction(menu.action)}}},'div',_vm.useTestDataAttribute(menu.dataCy || ''),false),[((_vm.unReadCount > 0 && menu.action === _vm.ToolbarMenuActions.TOOLBAR_CHAT)
                || (_vm.fetchPendingFilterCount > 0 && menu.action === _vm.ToolbarMenuActions.TOOLBAR_AGENDA)
                || (_vm.noteLinkedEntityCount > 0 && menu.action === _vm.ToolbarMenuActions.TOOLBAR_NOTES)
                || (_vm.stateTagCount > 0 && menu.action === _vm.ToolbarMenuActions.TOOLBAR_TAGS))?_c('span',{staticClass:"unread-pill ml-2 label label-5 bg-red-r-3-primary-red text-white\n              rounded-pill position-absolute top-0"},[(menu.action === _vm.ToolbarMenuActions.TOOLBAR_CHAT)?_c('span',[_vm._v(" "+_vm._s(_vm.unReadCount > 99 ? '99+' : _vm.unReadCount)+" ")]):_vm._e(),(menu.action === _vm.ToolbarMenuActions.TOOLBAR_AGENDA)?_c('span',[_vm._v(" "+_vm._s(_vm.fetchPendingFilterCount > 99 ? '99+' : _vm.fetchPendingFilterCount)+" ")]):_vm._e(),(menu.action === _vm.ToolbarMenuActions.TOOLBAR_NOTES)?_c('span',[_vm._v(" "+_vm._s(_vm.noteLinkedEntityCount > 99 ? '99+' : _vm.noteLinkedEntityCount)+" ")]):_vm._e(),(menu.action === _vm.ToolbarMenuActions.TOOLBAR_TAGS)?_c('span',[_vm._v(" "+_vm._s(_vm.stateTagCount > 99 ? '99+' : _vm.stateTagCount)+" ")]):_vm._e()]):_vm._e(),_c('font-awesome-component',{attrs:{"icon":("far " + (menu.icon)),"icon-classes":_vm.currentToolboxModal === menu.action
                ? 'text-blue-b-3-primary-blue'
                : 'text-neutral-n-8-dark-ink',"container-classes":"icon-24"}}),_c('b-tooltip',{attrs:{"target":('tool-item-'+menu.uid),"title":_vm.$t(menu.name),"custom-class":"tooltip-style","placement":"left"}})],1)]})],2)])]):_c('transition',{attrs:{"name":"fade"}},[(_vm.toolboxOpen)?_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(function () { return _vm.toolboxOpen = false; }),expression:"() => toolboxOpen = false"}],staticClass:"toolbox-overlay d-flex justify-content-end align-items-end",on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }_vm.toolboxOpen = false}}},[_c('div',{staticClass:"toolbox-list d-flex flex-column"},_vm._l((_vm.menuList),function(menu){return _c('div',_vm._b({key:menu.uid,staticClass:"d-flex align-items-center justify-content-end cursor-pointer",on:{"click":function($event){return _vm.doAction(menu.action)}}},'div',_vm.useTestDataAttribute(menu.dataCy || ''),false),[_c('p',{staticClass:"m-0 mr-3 paragraph-2 text-neutral-n-8-dark-ink text-right"},[_vm._v(" "+_vm._s(_vm.$t(menu.name))+" ")]),_c('div',{class:['toolbox-item rounded-circle d-flex align-items-center',
                     'justify-content-center shadow-sm position-relative',
                     { 'active': _vm.currentToolboxModal === menu.action }],attrs:{"id":'tool-item-'+menu.uid}},[((_vm.unReadCount > 0 && menu.action === _vm.ToolbarMenuActions.TOOLBAR_CHAT)
                || (_vm.fetchPendingFilterCount > 0 && menu.action === _vm.ToolbarMenuActions.TOOLBAR_AGENDA)
                || (_vm.noteLinkedEntityCount > 0 && menu.action === _vm.ToolbarMenuActions.TOOLBAR_NOTES)
                || (_vm.stateTagCount > 0 && menu.action === _vm.ToolbarMenuActions.TOOLBAR_TAGS))?_c('span',{staticClass:"unread-pill ml-2 label label-5 bg-red-r-3-primary-red text-white\n              rounded-pill position-absolute top-0"},[(menu.action === _vm.ToolbarMenuActions.TOOLBAR_CHAT)?_c('span',[_vm._v(" "+_vm._s(_vm.unReadCount > 99 ? '99+' : _vm.unReadCount || _vm.localUnreadChatCount)+" ")]):_vm._e(),(menu.action === _vm.ToolbarMenuActions.TOOLBAR_AGENDA)?_c('span',[_vm._v(" "+_vm._s(_vm.fetchPendingFilterCount > 99 ? '99+' : _vm.fetchPendingFilterCount)+" ")]):_vm._e(),(menu.action === _vm.ToolbarMenuActions.TOOLBAR_NOTES)?_c('span',[_vm._v(" "+_vm._s(_vm.noteLinkedEntityCount > 99 ? '99+' : _vm.noteLinkedEntityCount)+" ")]):_vm._e(),(menu.action === _vm.ToolbarMenuActions.TOOLBAR_TAGS)?_c('span',[_vm._v(" "+_vm._s(_vm.stateTagCount > 99 ? '99+' : _vm.stateTagCount)+" ")]):_vm._e()]):_vm._e(),_c('font-awesome-component',{attrs:{"icon":("far " + (menu.icon)),"icon-classes":_vm.currentToolboxModal === menu.action
                ? 'text-blue-b-3-primary-blue'
                : 'text-neutral-n-8-dark-ink',"container-classes":"icon-24"}})],1)])}),0)]):_vm._e()]),(!_vm.isDesktop)?_c('div',{class:['toolbox-mobile-button d-flex align-items-center justify-content-center shadow-sm',
             {'icon-text': !_vm.isScrolled && !_vm.isMobile}, 'p-12 p-md-3'],on:{"click":_vm.toggleOpenToolbox}},[_c('font-awesome-component',{attrs:{"icon":_vm.toolboxOpen ? 'far fa-xmark' : 'far fa-briefcase',"container-classes":"icon-24","icon-classes":"text-white-white-100"}}),(!_vm.isScrolled && !_vm.isMobile)?_c('span',{staticClass:"text-left m-0 button regular text-white-white-100"},[_vm._v(" "+_vm._s(_vm.$t('actions.toolbox'))+" ")]):_vm._e()],1):_vm._e(),(_vm.openToolboxModal)?_c('toolbox-modal',{attrs:{"toolbox-action":_vm.currentToolboxModal,"toolbox-query-params":_vm.toolboxPayload},on:{"hidden":function($event){_vm.openToolboxModal = false;
             _vm.toolboxPayload.data = undefined;
             _vm.currentToolboxModal = '';}}}):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }