import { Action, Module, Mutation } from 'vuex-module-decorators';
import ConfigurationState from '@/store/states/ConfigurationState';
import LoadableStore from '@/store/LoadableStore';
import ClientStorage from '@/utils/ClientStore';
import LocaleModel from '@/models/LocaleModel';
import { buildQueryDefinition } from '@/graphql/_Tools/GqlQueryDefinition';
import GqlEntityFilterType from '@/utils/enums/gql/GqlEntityFilterType';
import WidgetBaseRepository from '@/repositories/widget/WidgetBaseRepository';
import GqlComposeQueryDefinitionParams from '@/utils/types/gql/GqlComposeQueryDefinitionParams';
import { BasicTypes } from '@/utils/types/BasicTypes';
import PortalType from '@/utils/enums/PortalType';
import CookieService from '@/services/CookieService';
import AUTH_TOKEN from '@/utils/constants/SessionToken';
import jwtDecode from 'jwt-decode';
import JwtParams from '@/utils/types/JwtParams';
import GqlEntityOrderingType from '@/utils/enums/gql/GqlEntityOrderingType';

/* eslint-disable @typescript-eslint/camelcase,class-methods-use-this */

@Module({ namespaced: true })
export default class ConfigurationStore extends LoadableStore<ConfigurationState> {
  private repository = new WidgetBaseRepository();

  private gqlConfigs: GqlComposeQueryDefinitionParams[] = [];

  protected get rootLoading(): boolean {
    return false;
  }

  private get isLocalDomain(): boolean {
    return ['localhost', 'portal.local'].includes(window.location.hostname);
  }

  @Action
  loadConfigs(community: string): Promise<void> {
    const pagePortalTypeFilter = this.context.rootState.portalType === PortalType.UNIFY_EXHIBITOR_PORTAL
      ? { portalType_in: [PortalType.UNIFY_EXHIBITOR_PORTAL, PortalType.ALL] }
      : { portalType_in: [PortalType.UNIFY, PortalType.ALL] };
    const menuPortalTypeFilter = this.context.rootState.portalType !== PortalType.ALL
      ? { portalType: this.context.rootState.portalType }
      : { portalType: PortalType.UNIFY };
    if (!this.isLocalDomain) {
      this.context.commit('setGqlMasterDomainConfig');
    }
    const onboardingPortalTypeFilter = this.context.rootState.portalType === PortalType.UNIFY_EXHIBITOR_PORTAL
      ? { portalType_in: [PortalType.UNIFY_EXHIBITOR_PORTAL, PortalType.ALL] }
      : { portalType_in: [PortalType.UNIFY, PortalType.ALL] };
    this.context.commit('setGqlCommunityConfig');
    this.context.commit('setGqlPagesConfig', pagePortalTypeFilter);
    this.context.commit('setGqlEpRootPageConfig');
    this.context.commit('setGqlMenusConfig', menuPortalTypeFilter);
    this.context.commit('setGqlLocalesConfig');
    this.context.commit('setGqlOnBoardingsConfig', onboardingPortalTypeFilter);
    this.context.commit('setGqlFeaturesConfig');
    return this.repository.load({
      queries: '',
      params: {
        operationName: 'LoadAppConfigs',
        definitions: this.gqlConfigs,
        magicArgs: {
          community,
        },
      },
    })
      .then((configs) => {
        if ('community' in configs && configs.community && configs.community.length > 0) {
          this.context.commit('setCommunity', configs.community[0], { root: true });
        }
        if (!this.isLocalDomain && 'masterDomain' in configs && configs.masterDomain && configs.masterDomain.length > 0) {
          this.context.commit('setMasterDomain', configs.masterDomain, { root: true });
        }
        if ('communityFeature' in configs && configs.communityFeature) {
          this.context.commit('setFeatures', configs.communityFeature, { root: true });
        }
        if ('uiPage' in configs && configs.uiPage) {
          this.context.commit('setPages', configs.uiPage, { root: true });
        }
        if ('uiMenu' in configs && configs.uiMenu) {
          this.context.commit('setMenus', configs.uiMenu, { root: true });
        }
        if ('uiOnboarding' in configs && configs.uiOnboarding) {
          this.context.commit('setOnBoardings', configs.uiOnboarding, { root: true });
        }
        if ('epRootPage' in configs && configs.epRootPage) {
          this.context.commit('setEpRootPage', configs.epRootPage, { root: true });
        }
        if ('locale' in configs && configs.locale) {
          const result = configs.locale as unknown as LocaleModel[];
          this.context.commit('setLocales', result, { root: true });
          // eslint-disable-next-line max-len
          this.context.commit('setDefaultLocale',
            ClientStorage.getItem('locale')
            || result.find((l) => l.isDefault)?.locale
            || result[0].locale || 'en_US',
            { root: true });
          this.context.commit('setFallbackLocale',
            result.find((l) => l.isDefault)?.locale
            || result[0].locale || 'en_US', { root: true });
        }
        this.context.commit('initSelectedTimeZone', null, { root: true });
      });
  }

  @Mutation
  setGqlCommunityConfig(): void {
    this.gqlConfigs.push({
      gqlDefinition: buildQueryDefinition({
        cacheable: true,
        filter: {
          type: GqlEntityFilterType.COMMUNITY_FILTER,
          value: { code: '%community%' },
        },
      }),
      operation: 'community',
      fragmentName: 'communityFullFragment',
    });
  }

  @Mutation
  setGqlMasterDomainConfig(): void {
    this.gqlConfigs.push({
      gqlDefinition: buildQueryDefinition({
        cacheable: true,
        filter: {
          type: GqlEntityFilterType.MASTER_DOMAIN_FILTER,
          value: {
            defaultModule_in: ['Community', 'CommunityExhibitorPortal'],
            active: true,
            isDefault: true,
            requireHttps: true,
            masterCommunity: {
              code: '%community%',
            },
          },
        },
      }),
      operation: 'masterDomain',
      fragmentName: 'masterDomainBaseFragment',
    });
  }

  @Mutation
  setGqlFeaturesConfig(): void {
    const filter = {
      masterCommunity: {
        code: '%community%',
      },
      enabled: true,
      feature: {
        type: 'feature',
        product: 'Unify',
        key_not_starts_with: 'auto_mapping_to',
      },
    };
    this.gqlConfigs.push({
      gqlDefinition: buildQueryDefinition({
        cacheable: true,
        filter: {
          type: GqlEntityFilterType.COMMUNITY_FEATURE_FILTER,
          value: filter,
        },
      }),
      operation: 'communityFeature',
      fragmentName: 'communityFeatureFullFragment',
    });
  }

  @Mutation
  setGqlLocalesConfig(): void {
    const filter = {
      schemaCode: '%community%',
      active: true,
    };
    this.gqlConfigs.push({
      gqlDefinition: buildQueryDefinition({
        cacheable: true,
        filter: {
          type: GqlEntityFilterType.LOCALE_FILTER,
          value: filter,
        },
      }),
      operation: 'locale',
      fragmentName: 'localeFullFragment',
    });
  }

  @Mutation
  setGqlMenusConfig(portalTypeFilter: object): void {
    const filter = {
      schemaCode: '%community%',
      active: true,
      ...portalTypeFilter,
    };
    if (ClientStorage.getItem('version')) {
      Object.assign(filter, {
        OR: [
          { production: true },
          { version: ClientStorage.getItem('version') },
        ],
      });
    } else {
      Object.assign(filter, { production: true });
    }
    this.gqlConfigs.push({
      gqlDefinition: buildQueryDefinition({
        cacheable: true,
        filter: {
          type: GqlEntityFilterType.UI_MENU_FILTER,
          value: filter,
        },
        orderBy: {
          value: ['production_asc'],
          type: GqlEntityOrderingType.UI_MENU_ORDERING,
        },
      }),
      operation: 'uiMenu',
      fragmentName: 'uiMenuBaseFragment',
    });
  }

  @Mutation
  setGqlOnBoardingsConfig(portalTypeFilter: object): void {
    const token = CookieService.getCookie(AUTH_TOKEN);
    let authFilter = {};
    if (token) {
      const decodedToken = jwtDecode(token) as JwtParams;
      if (decodedToken && decodedToken.r === 'USER' && decodedToken.u) {
        ClientStorage.setItem('onboardings', '[]');
        authFilter = {
          usersHaveSeen_none: {
            uid: decodedToken.u,
          },
        };
      } else {
        const onBoardings = JSON.parse(ClientStorage.getItem('onboardings') || '[]');
        authFilter = onBoardings.length > 0
          ? {
            uid_not_in: onBoardings,
            guest: true,
          }
          : { guest: true };
      }
    }
    const filter = {
      schemaCode: '%community%',
      enabled: true,
      inUserGroups: true,
      ...authFilter,
      ...portalTypeFilter,
    };
    if (ClientStorage.getItem('version')) {
      Object.assign(filter, {
        OR: [
          { production: true },
          { version: ClientStorage.getItem('version') },
        ],
      });
    } else {
      Object.assign(filter, { production: true });
    }
    this.gqlConfigs.push({
      gqlDefinition: buildQueryDefinition({
        cacheable: false,
        filter: {
          type: GqlEntityFilterType.UI_ONBOARDING_FILTER,
          value: filter,
        },
        orderBy: {
          value: ['production_desc'],
          type: GqlEntityOrderingType.UI_ONBOARDING_ORDERING,
        },
      }),
      operation: 'uiOnboarding',
      fragmentName: 'uiOnboardingFullFragment',
    });
  }

  @Mutation
  setGqlPagesConfig(portalTypeFilter: object): void {
    const filter = {
      schemaCode: '%community%',
      parentPage: null,
      active: true,
      ...portalTypeFilter,
    };

    if (ClientStorage.getItem('version')) {
      Object.assign(filter, {
        OR: [
          { production: true },
          { version: ClientStorage.getItem('version') },
        ],
      });
    } else {
      Object.assign(filter, { production: true });
    }
    this.gqlConfigs.push({
      gqlDefinition: buildQueryDefinition({
        cacheable: true,
        filter: {
          type: GqlEntityFilterType.UI_PAGE_FILTER,
          value: filter,
        },
        orderBy: {
          value: ['production_desc'],
          type: GqlEntityOrderingType.UI_PAGE_ORDERING,
        },
      }),
      operation: 'uiPage',
      fragmentName: 'uiPageFullFragment',
    });
  }

  @Mutation
  setGqlEpRootPageConfig(): void {
    const filter = {
      schemaCode: '%community%',
      parentPage: null,
      active: true,
      portalType: PortalType.UNIFY_EXHIBITOR_PORTAL,
      path_starts_with: ':',
      path_not_contains: '/',
    };
    if (ClientStorage.getItem('version')) {
      Object.assign(filter, {
        OR: [
          { production: true },
          { version: ClientStorage.getItem('version') },
        ],
      });
    } else {
      Object.assign(filter, { production: true });
    }
    this.gqlConfigs.push({
      gqlDefinition: buildQueryDefinition({
        cacheable: true,
        filter: {
          type: GqlEntityFilterType.UI_PAGE_FILTER,
          value: filter,
        },
        first: 1,
        orderBy: {
          value: ['production_desc'],
          type: GqlEntityOrderingType.UI_PAGE_ORDERING,
        },
      }),
      alias: 'epRootPage',
      operation: 'uiPage',
      fragmentName: 'uiPageNameFragment',
    });
  }

  @Action
  loadBreadcrumbForStatLogger(val: {
    type: string;
    uid: string;
  }): Promise<Record<string, BasicTypes[]>> {
    const query = `${val.type === 'edition'
      ? 'subEdition'
      : val.type}(_cachable: true, ${val.type === 'edition' ? 'code' : 'uid'}: "${val.uid}") {
        ${['poll', 'survey'].includes(val.type) ? 'title' : 'name'},
        ${['session', 'sponsor', 'exhibitor', 'product', 'largeProduct'].includes(val.type) ? 'description' : ''}
      }`;
    return this.repository.load({
      params: {
        definitions: [],
        operationName: 'BreadcrumbForStatLogger',
      },
      queries: query,
    });
  }
}
