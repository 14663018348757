import Agenda from '@/models/graphql/Agenda';
import EntityType from '@/utils/enums/EntityType';
import Meeting from '@/models/graphql/Meeting';
import Event from '@/utils/types/Event';
import Session from '@/models/graphql/Session';
import CommunityUser from '@/models/graphql/CommunityUser';
import MeetingParticipant from '@/models/graphql/MeetingParticipant';
import MeetingParticipantState from '@/utils/enums/MeetingParticipantState';
import { format, utcToZonedTime } from 'date-fns-tz';
import ScheduleEvent from '@/models/graphql/ScheduleEvent';
import Geozone from '@/models/graphql/Geozone';
import * as StringHelper from '@/utils/helpers/StringHelper';

/* eslint-disable max-params */
/* eslint-disable no-underscore-dangle */
export default class AgendaStoreHelper {
  static newDictionary(
    agenda: Agenda,
    dictionary: { [x: string]: Array<Event> } = {},
    tz: string,
  ): { [x: string]: Array<Event> } {
    if (agenda.meetings && agenda.meetings.length > 0) {
      agenda.meetings.forEach((meeting) => {
        const event = this.convertMeetingToEvent(meeting, tz);
        this.addEventToAgenda(event, dictionary);
      });
    }

    if (agenda.sessions && agenda.sessions.length > 0) {
      agenda.sessions.forEach((session) => {
        const event = this.convertSessionToEvent(session, tz);
        this.addEventToAgenda(event, dictionary);
      });
    }

    if (agenda.scheduleEvents && agenda.scheduleEvents.length > 0) {
      agenda.scheduleEvents.forEach((session) => {
        const event = this.convertScheduleOfEventsToEvent(session, tz);
        this.addEventToAgenda(event, dictionary);
      });
    }
    return dictionary;
  }

  static convertSessionToEvent(s: Session, tz: string): Event {
    return {
      uid: s.uid,
      entityId: s.uid,
      entityType: EntityType.SESSION,
      title: s.name || '',
      location: AgendaStoreHelper.location(s.geozones) || '',
      logo: null,
      participants: s.speakerRoles,
      message: s.description || '',
      startTime: `${s.startTime}Z`,
      endTime: `${s.endTime}Z`,
      tzStartTime: utcToZonedTime(`${s.startTime}Z`, tz),
      tzEndTime: utcToZonedTime(`${s.endTime}Z`, tz),
      timeZoneName: '',
      isRecommended: false,
      isInAgenda: s._isInAgenda,
      bookmarkCount: s._bookmarkCount,
      exhibitor: s.exhibitor,
    };
  }

  static location(geozones: Geozone[]): string | null {
    if (geozones && geozones.length > 0) {
      let result: string[] = [];

      const geozone = geozones[0];
      if (geozone.exhibitHall && geozone.exhibitHall.name) {
        if (geozone.name) {
          result = [geozone.exhibitHall.name, geozone.name];
        } else {
          result = [geozone.exhibitHall.name];
        }

        result[0] = StringHelper.formatStringToWords(result[0]);
        return result.join(' · ');
      }

      if (geozone && geozone.name) {
        return geozone.name;
      }
    }
    return null;
  }

  static convertScheduleOfEventsToEvent(s: ScheduleEvent, tz: string): Event {
    let message = s.description || '';
    if (s.url && s.urlLabel) {
      message += `<br/><br/><a href="${s.url}" class="text-decoration-none text-blue-b-3-primary-blue">${s.urlLabel}</a>`;
    }
    return {
      uid: s.uid,
      entityId: s.uid,
      entityType: EntityType.SCHEDULE_EVENT,
      title: s.name || '',
      location: '',
      logo: null,
      participants: [],
      message,
      startTime: `${s.startTime}Z`,
      endTime: `${s.endTime}Z`,
      tzStartTime: utcToZonedTime(`${s.startTime}Z`, tz),
      tzEndTime: utcToZonedTime(`${s.endTime}Z`, tz),
      timeZoneName: '',
      isRecommended: false,
      isInAgenda: s._isInAgenda,
    };
  }

  static convertMeetingToEvent(m: Meeting, tz: string): Event {
    return {
      uid: m.uid,
      entityId: m.uid,
      entityType: EntityType.MEETING,
      title: m.subject || '',
      location: m.location || '',
      logo: null,
      participants: m.participants,
      message: m.description || '',
      startTime: `${m.startTime}Z`,
      endTime: `${m.endTime}Z`,
      tzStartTime: utcToZonedTime(`${m.startTime}Z`, tz),
      tzEndTime: utcToZonedTime(`${m.endTime}Z`, tz),
      timeZoneName: m.timeZoneName || '',
      isRecommended: false,
      creator: m.creatorUser,
    };
  }

  static addEventToAgenda(e: Event, dictionary: { [x: string]: Array<Event> }): {
    [x: string]: Array<Event>;
  } {
    const key = this.formatDictionaryKey(e.tzStartTime);
    if (key in dictionary) {
      if (dictionary[key].find((event) => event.uid === e.uid)) {
        return dictionary;
      }
      const events = dictionary[key];
      events.push(e);
      dictionary[key] = events;
    } else {
      dictionary[key] = [e];
    }
    return dictionary;
  }

  static removeEventFromAgenda(e: Partial<Event>, dictionary: { [x: string]: Array<Event> }): {
    [x: string]: Array<Event>;
  } {
    if (e.tzStartTime) {
      const key = this.formatDictionaryKey(e.tzStartTime);
      if (key in dictionary && dictionary[key].find((event) => event.uid === e.uid)) {
        const events = dictionary[key];
        const indexOfEvent = events.findIndex((event: Event) => event.uid === e.uid);
        events.splice(indexOfEvent, 1);

        if (dictionary[key].length === 0) {
          delete dictionary[key];
        }
      }
    } else if (e.uid) {
      const keysToBeDeleted: string[] = [];
      Object.keys(dictionary)
        .forEach((key) => {
          dictionary[key] = dictionary[key].filter((event) => event.uid !== e.uid);
          if (dictionary[key].length === 0) {
            keysToBeDeleted.push(key);
          }
        });
      keysToBeDeleted.forEach((k) => delete dictionary[k]);
    }
    return dictionary;
  }

  static updateMeetingParticipantState(
    dictionary: { [x: string]: Array<Event> } = {},
    e: Event, authUser: CommunityUser,
    state: MeetingParticipantState,
  ): void {
    const key = this.formatDictionaryKey(e.tzStartTime);
    if (key in dictionary) {
      const events = dictionary[key];
      const event = events.find((ev: Event) => ev.uid === e.uid);
      if (event && event.participants) {
        const participant = (event.participants as Array<MeetingParticipant>)
          .find((meetingParticipant: MeetingParticipant) => {
            if (meetingParticipant && meetingParticipant.user) {
              return meetingParticipant.user.uid === authUser.uid;
            }
            return false;
          });
        if (participant) {
          participant.state = state;
        }
      }
    }
  }

  static formatDictionaryKey(time: Date): string {
    return format(time, 'yyyy-MM-dd');
  }

  static changeAgendaTimeZone(
    dictionary: { [x: string]: Array<Event> },
    tz: string,
  ): { [x: string]: Array<Event> } {
    Object.keys(dictionary)
      .forEach((key) => {
        dictionary[key] = dictionary[key]
          .map((item) => this.convertEventTimezone(item, tz));
      });
    return dictionary;
  }

  static convertEventTimezone(e: Event, tz: string): Event {
    e.tzStartTime = utcToZonedTime(e.startTime, tz);
    e.tzEndTime = utcToZonedTime(e.endTime, tz);
    return e;
  }
}
