














import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { IconDefinition, library } from '@fortawesome/fontawesome-svg-core';
import * as StringHelper from '@/utils/helpers/StringHelper';

@Component({
  components: {
    FontAwesomeIcon,
  },
})
export default class FontAwesomeComponent extends Vue {
  @Prop({ required: true })
  private readonly icon!: string | string[];

  @Prop({
    required: false,
    default: false,
  })
  private readonly stopPropagation!: boolean;

  @Prop({ default: '' })
  private readonly containerClasses!: string | string[];

  @Prop({ default: '' })
  private readonly iconClasses!: string | string[];

  @Prop({ default: false })
  private readonly spin!: boolean;

  private localIcon: IconDefinition | string | string[] | null = null;

  @Watch('icon', { immediate: true })
  private async iconLazyLoading(): Promise<void> {
    let iconParam = this.icon;
    if (!iconParam) {
      return;
    }

    if (!Array.isArray(this.icon)) {
      iconParam = (iconParam as string).split(' ');
    }
    if (iconParam.length > 1) {
      const prefix = iconParam[0].trim();
      const iconName = StringHelper.kebabToCamel(iconParam[1].trim());
      if (['far', 'fa-regular'].includes(prefix)) {
        const { far } = await import('@fortawesome/pro-regular-svg-icons');
        if (far[iconName]) {
          library.add(far[iconName]);
          this.localIcon = far[iconName];
        }
      }
      if (['fas', 'fa-solid'].includes(prefix)) {
        const { fas } = await import('@fortawesome/pro-solid-svg-icons');
        if (fas[iconName]) {
          library.add(fas[iconName]);
          this.localIcon = fas[iconName];
        }
      }
      if (['fab', 'fa-brands'].includes(prefix)) {
        const { fab } = await import('@fortawesome/free-brands-svg-icons');
        if (fab[iconName]) {
          library.add(fab[iconName]);
          this.localIcon = fab[iconName];
        }
      }
    } else {
      this.localIcon = this.icon;
    }
  }

  private onClick(e: Event): void {
    if (this.stopPropagation) {
      e.preventDefault();
      e.stopPropagation();
      this.$emit('on-click');
    } else {
      this.$emit('on-click');
    }
  }
}
