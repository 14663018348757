import { Action, Module, Mutation } from 'vuex-module-decorators';
import LoadableState from '@/store/states/LoadableState';
import Session from '@/models/graphql/Session';
import SessionRepository from '@/repositories/SessionRepository';
import LoadableStore from '@/store/LoadableStore';
import { buildMutationDefinition } from '@/graphql/_Tools/GqlMutationDefinition';
import { buildQueryDefinition } from '@/graphql/_Tools/GqlQueryDefinition';
import GqlEntityFilterType from '@/utils/enums/gql/GqlEntityFilterType';
import GqlEntityInputType from '@/utils/enums/gql/GqlEntityInputType';
import { SessionFilter } from '@/graphql/_Filters/SessionFilter';
import GqlEntityOrderingType from '@/utils/enums/gql/GqlEntityOrderingType';
import Category from '@/models/graphql/Category';

interface SessionState extends LoadableState {
  sessions: Session[];
  stateSession: Session;
  scheduleEventSessions: Session[];
}

@Module({ namespaced: true })
export default class SessionStore extends LoadableStore<SessionState> {
  sessions: Session[] = [] as Session[];

  stateSession: Session = {} as Session;

  scheduleEventSessions: Session[] = [] as Session[];

  private readonly sessionRepository = new SessionRepository();

  get session(): Session | null {
    return this.stateSession;
  }

  get fetchSessions(): Session[] {
    return this.sessions;
  }

  get fetchScheduleEventSessions(): Session[] {
    return this.scheduleEventSessions;
  }

  protected get repository(): SessionRepository {
    return this.sessionRepository;
  }

  @Action
  getSessionUidByCode(code: string): Promise<string | null> {
    return this.repository.get({
      operationName: 'GetSessionUidByCode',
      definition: buildQueryDefinition({
        filter: {
          value: { foreignId: code },
          type: GqlEntityFilterType.SESSION_FILTER,
        },
      }),
      fragmentName: 'sessionUidFragment',
    })
      .then((response) => {
        if (response && response.uid) {
          return response.uid;
        }
        return null;
      });
  }

  @Action
  get(payload: { filter: SessionFilter }): Promise<Session | undefined> {
    this.context.commit('load', true);
    return this.repository.get({
      definition: buildQueryDefinition({
        cacheable: true,
        filter: {
          value: payload.filter,
          type: GqlEntityFilterType.SESSION_FILTER,
        },
      }),
      fragmentName: 'sessionBaseFragment',
    })
      .then((response) => {
        this.context.commit('setElement', response);
        this.context.commit('load', false);
        return response;
      });
  }

  @Action
  loadLiveSession(payload: { uid: string }): Promise<Session | undefined> {
    return this.repository.get({
      definition: buildQueryDefinition({
        cacheable: true,
        filter: {
          value: { uid: payload.uid },
          type: GqlEntityFilterType.SESSION_FILTER,
        },
      }),
      fragmentName: 'sessionForLiveVideoFragment',
      authUser: this.context.rootState.authUser?.uid,
    });
  }

  @Action
  updateSession(payload: Partial<Session>): Promise<Session | undefined> {
    return this.repository.update({
      definition: buildMutationDefinition([
        {
          fieldName: 'entity',
          type: GqlEntityInputType.SESSION_INPUT,
          value: payload,
        },
      ]),
    });
  }

  /* eslint-disable @typescript-eslint/camelcase */
  @Action
  loadMapSession(payload: {
    geozoneId: number; edition: string; authUser: string;
  }): Promise<Session[] | undefined> {
    return this.repository.filter({
      operationName: 'GetMapSession',
      definition: buildQueryDefinition({
        filter: {
          value: {
            parentSession: null,
            editionMappings_some: {
              editionSession_some: {
                schemaCode: payload.edition,
                geozone_some: {
                  id: payload.geozoneId,
                },
              },
            },
          },
          type: GqlEntityFilterType.SESSION_FILTER,
        },
      }),
      fragmentName: 'sessionMapFragment',
      authUser: payload.authUser,
    });
  }

  /* eslint-disable @typescript-eslint/camelcase */
  @Action
  loadScheduleEventSubSession(payload: {
    filter: string;
    orderBy: string;
  }): Promise<Session[] | undefined> {
    this.context.commit('load', true);
    const order = {
      orderBy: {
        value: payload.orderBy ? payload.orderBy.split(',') : ['startTimestamp_asc'],
        type: GqlEntityOrderingType.SESSION_ORDERING,
      },
    };
    return this.repository.filter({
      definition: buildQueryDefinition({
        filter: {
          value: JSON.parse(payload.filter || '{}'),
          type: GqlEntityFilterType.SESSION_FILTER,
        },
        ...order,
      }),
      fragmentName: 'sessionForLiveVideoFragment',
      authUser: this.context.rootState.authUser?.uid,
    })
      .then((response) => {
        this.context.commit('setData', response);
        this.context.commit('load', false);
        return response;
      });
  }

  @Action
  deleteSession(uid: string): Promise<boolean> {
    return this.repository.delete({
      definition: buildMutationDefinition([{
        fieldName: 'uid',
        type: 'ID',
        value: uid,
      }]),
    }).then((response) => !!response);
  }

  @Action
  editSession(payload: {
    exhibitorId: string;
    session: {
      uid: string;
      name: string;
      activityType: string;
      description: string;
      price: string;
      startTime: string;
      endTime: string;
      speakers: string;
      oldCategories: [];
      newCategories: [];
    };
  }): Promise<Session | undefined> {
    const finalSession = { ...payload.session };
    if ('oldCategories' in finalSession) {
      delete finalSession.oldCategories;
    }
    if ('newCategories' in finalSession) {
      delete finalSession.newCategories;
    }
    if (payload.session.uid) {
      return this.repository.update({
        definition: buildMutationDefinition([
          {
            fieldName: 'entity',
            type: GqlEntityInputType.SESSION_INPUT,
            value: finalSession,
          },
        ]),
      });
    }
    return this.repository.createSessionForExhibitor({
      definition: buildMutationDefinition([
        {
          fieldName: 'exhibitor_ExhibitorUid',
          type: 'ID',
          value: payload.exhibitorId,
        },
        {
          fieldName: 'entity',
          type: GqlEntityInputType.SESSION_INPUT,
          value: finalSession,
        },
      ]),
    });
  }

  @Action
  updateSessionCategories(payload: {
    session: {
      uid: string;
      oldCategories: Category[];
      newCategories: Category[];
    };
  }): Promise<void> {
    let categoriesToDelete: string[] = [];
    let categoriesToAdd: string[] = [];
    if (payload.session.newCategories && payload.session.oldCategories) {
      const newCategoriesUid = payload.session.newCategories.map((c) => c.uid);
      categoriesToDelete = payload.session.oldCategories
        .filter((old) => !newCategoriesUid.includes(old.uid)).map((c) => c.uid);

      const oldCategoriesUid = payload.session.oldCategories.map((c) => c.uid);
      categoriesToAdd = payload.session.newCategories
        .filter((newCat) => !oldCategoriesUid.includes(newCat.uid)).map((c) => c.uid);
    }

    let categoriesToAddPromise: Promise<Record<string, Session>[]> | null = null;
    if (categoriesToAdd.length > 0) {
      categoriesToAddPromise = this.repository.addCategoriesToSession({
        definitions: categoriesToAdd.map((category, index) => ({
          operation: 'SessionAddCategory',
          gqlDefinition: buildMutationDefinition([
            {
              fieldName: 'uid',
              type: 'ID',
              value: payload.session.uid,
            },
            {
              fieldName: 'category_CategoryUid',
              type: 'ID',
              value: category,
            },
          ]),
          fragmentName: 'sessionBaseFragment',
          alias: `add${index}`,
        })),
      });
    }

    let categoriesToDeletePromise: Promise<Record<string, Session>[]> | null = null;
    if (categoriesToDelete.length > 0) {
      categoriesToDeletePromise = this.repository.removeCategoriesFromSession({
        definitions: categoriesToDelete.map((category, index) => ({
          operation: 'SessionRemoveCategory',
          gqlDefinition: buildMutationDefinition([
            {
              fieldName: 'uid',
              type: 'ID',
              value: payload.session.uid,
            },
            {
              fieldName: 'category_CategoryUid',
              type: 'ID',
              value: category,
            },
          ]),
          fragmentName: 'sessionBaseFragment',
          alias: `delete${index}`,
        })),
      });
    }

    const allPromises = [];
    if (categoriesToAddPromise) {
      allPromises.push(categoriesToAddPromise);
    }
    if (categoriesToDeletePromise) {
      allPromises.push(categoriesToDeletePromise);
    }

    return Promise.all(allPromises)
      .then(() => Promise.resolve());
  }

  @Action
  updateSessionByLocale(payload: {
    session: {
      uid: string;
      translations: Record<string, string>;
    };
  }): Promise<void> {
    let translationsToUpdatePromise: Promise<Session | undefined>[] = [];
    if (Object.keys(payload.session.translations).length > 0) {
      translationsToUpdatePromise = Object.keys(payload.session.translations).map((key) => {
        const infos = key.split('-');
        return this.repository.updateByLocale({
          definition: buildMutationDefinition([
            {
              fieldName: 'entity',
              type: GqlEntityInputType.SESSION_INPUT,
              value: {
                uid: payload.session.uid,
                [infos[0]]: payload.session.translations[key],
              },
            },
          ]),
        }, infos[1]);
      });
    }
    return Promise.all(translationsToUpdatePromise).then(() => Promise.resolve());
  }

  @Mutation
  setData(list: Session[]): void {
    this.scheduleEventSessions = list;
  }

  @Mutation
  setElement(session: Session): void {
    this.stateSession = session;
  }
}
