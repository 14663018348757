





















































































































import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import { Getter, namespace } from 'vuex-class';
import InputSearchComponent from '@/components/InputSearchComponent.vue';
import MessageBoxListItemComponent from '@/components/chat/MessageBoxListItemComponent.vue';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import MessageGroup from '@/models/graphql/MessageGroup';
import CommunityUser from '@/models/graphql/CommunityUser';
import MessageBoxActions from '@/utils/enums/chat/MessageBoxActions';
import ChatErrorList from '@/utils/types/chat/ChatErrorList';
import { ChatRequestType } from '@/utils/types/chat/ChatError';
import IllustrationType from '@/utils/enums/IllustrationType';
import IllustrationComponent from '@/components/IllustrationComponent.vue';
import GroupType from '@/utils/enums/chat/GroupType';
import useTestDataAttribute from '@/utils/TestDataAttribute';
import { StateChanger } from 'vue-infinite-loading';
import LoadingSpinnerComponent from '@/components/LoadingSpinnerComponent.vue';
import { runMathJax } from '@/utils/helpers/LatexHelper';
import { BasicTypes } from '@/utils/types/BasicTypes';

const communityUserConnectionStore = namespace('CommunityUserConnectionStore');

@Component({
  methods: { useTestDataAttribute },
  components: {
    LoadingSpinnerComponent,
    IllustrationComponent,
    FontAwesomeComponent,
    ButtonIconComponent,
    InputSearchComponent,
    MessageBoxListItemComponent,
  },
})
/* eslint-disable max-len */
export default class MessageBoxListView extends Vue {
  @Getter
  private readonly authUser!: CommunityUser;

  @Prop({
    required: false,
    default: 'ChatStore',
  })
  private readonly storeName!: string;

  @Prop({
    required: false,
    default: null,
  })
  private readonly overrideBasicFilter!: object;

  @Prop({
    required: false,
    default: false,
  })
  private readonly disableGroupCreation!: boolean;

  @Prop({
    required: false,
    default: false,
  })
  private readonly isBasicLayout!: boolean;

  @Prop({
    required: false,
    default: true,
  })
  private readonly triggerFirstLoadEvent!: boolean;

  @Prop({
    required: false,
    default: () => [],
  })
  private chatReps!: string[];

  @Prop({
    required: false,
    default: () => [],
  })
  private overrideOrderBy!: string[];

  @communityUserConnectionStore.Action
  private loadConnectionsCount!: () => Promise<number | undefined>;

  private nbConnections = 0;

  private infiniteId = +new Date();

  private resultConversations: MessageGroup[] = [];

  private isLoading = true;

  private basicFilter: object | null = null;

  private firstLoad = true;

  private componentKey = +new Date();

  private listContainerId = `list-${this.componentKey}`;

  private MessageBoxActions = MessageBoxActions;

  private IllustrationType = IllustrationType;

  private get chatErrors(): ChatErrorList {
    return this.state<ChatErrorList>('chatErrors');
  }

  private get loadMoreGroups(): boolean {
    return this.state<boolean>('loadMoreGroups');
  }

  private get groupsSearchQuery(): string {
    return this.state<string>('groupsSearchQuery');
  }

  private get selectedGroup(): MessageGroup | null {
    return this.getter<MessageGroup | null>('selectedGroup');
  }

  private get conversations(): MessageGroup[] {
    return this.getter<MessageGroup[]>('conversationsList');
  }

  created(): void {
    if (this.overrideOrderBy.length > 0) {
      this.setOrderBy(this.overrideOrderBy);
    }
    if (this.resultConversations.length > 0 && this.triggerFirstLoadEvent) {
      this.$eventsBus.emit('first-groups-page-loaded');
    }
    this.loadConnectionsCount()
      .then((count) => {
        if (count) {
          this.nbConnections = count;
        }
      });
  }

  // eslint-disable-next-line class-methods-use-this
  updated(): void {
    runMathJax();
  }

  @Watch('overrideBasicFilter', { immediate: true })
  private updateBasicFilter(): void {
    if (this.overrideBasicFilter && Object.keys(this.overrideBasicFilter).length > 0) {
      this.basicFilter = this.overrideBasicFilter;
    } else {
      this.basicFilter = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        type_not: GroupType.QNA,
        users: { uid: this.authUser.uid },
      };
    }
  }

  private changeStatus(status: MessageBoxActions): void {
    this.commit('changeStatus', status);
  }

  private setGroupsSearchQuery(val: string): void {
    this.commit('setGroupsSearchQuery', val);
  }

  private setGroupsOffset(val: number): void {
    this.commit('setGroupsOffset', val);
  }

  private setLoadMoreGroups(val: boolean): void {
    this.commit('setLoadMoreGroups', val);
  }

  private setOrderBy(values: string[]): void {
    this.commit('setOrderBy', values);
  }

  private paginatedGroups(filter: object): Promise<void> {
    return this.dispatch<void>('paginatedGroups', filter);
  }

  private reloadLastRequest(request?: ChatRequestType): Promise<void> {
    return this.dispatch<void>('reloadLastRequest', request);
  }

  private commit(name: string, payload?: BasicTypes): void {
    this.$store.commit(`ChatDispatcherStore/${this.storeName}/${name}`, payload);
  }

  private getter<T>(name: string): T {
    return this.$store.getters[`ChatDispatcherStore/${this.storeName}/${name}`];
  }

  private state<T>(name: string): T {
    return this.$store.state.ChatDispatcherStore[this.storeName][name];
  }

  private dispatch<T>(name: string, payload?: BasicTypes): Promise<T> {
    return this.$store.dispatch(`ChatDispatcherStore/${this.storeName}/${name}`, payload);
  }

  private infiniteHandler($state: StateChanger): void {
    this.isLoading = true;
    if (!this.loadMoreGroups) {
      $state.complete();
      this.isLoading = false;
      return;
    }
    let searchFilter = {};
    if (this.groupsSearchQuery) {
      searchFilter = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        users_some: {
          // eslint-disable-next-line @typescript-eslint/camelcase
          name_contains: this.groupsSearchQuery,
        },
      };
    }
    this.paginatedGroups({
      ...this.basicFilter,
      ...searchFilter,
    })
      .then(() => {
        $state.loaded();
        this.isLoading = false;
      })
      .catch(() => {
        $state.complete();
        this.isLoading = false;
      })
      .finally(() => {
        if (this.firstLoad && this.triggerFirstLoadEvent) {
          this.$eventsBus.emit('first-groups-page-loaded');
          this.firstLoad = false;
        }
      });
  }

  @Watch('conversations', { immediate: true })
  private setLoadedConversation(): void {
    this.resultConversations = this.conversations;
    const { group } = this.$route.query;
    if (group && this.conversations.findIndex((conv) => conv.uid === group) > -1) {
      this.scrollToItemInContainer(`group-${this.componentKey}-${group}`);
    }
  }

  private scrollToItemInContainer(itemId: string): void {
    const container = document.getElementById(this.listContainerId);
    const item = document.getElementById(itemId);
    if (container && item) {
      this.$nextTick(() => {
        container.scrollTop = item.offsetTop - container.offsetTop;
      });
    }
  }

  private retry(): void {
    if (this.chatErrors.haveErrors) {
      const error = this.chatErrors.findFetchConversationsError;
      if (error && error.request) {
        this.reloadLastRequest(error.request);
        this.chatErrors.removeErrors(error);
      }
    }
  }

  private onSearch(payload: { query: string }): void {
    this.setGroupsOffset(0);
    this.setLoadMoreGroups(true);
    this.setGroupsSearchQuery(payload.query);
    this.resultConversations = [];
    this.infiniteId += 1;
  }

  private openToConnect(): void {
    this.$router.push({
      name: 'members_browser',
    })
      .catch((error) => {
        if (error.name !== 'NavigationDuplicated') {
          throw error;
        }
      })
      .finally(() => {
        this.$eventsBus.emit('close-conversation-option-modal');
        this.$eventsBus.emit('close-toolbox');
      });
  }
}
