import { Action, Module, Mutation } from 'vuex-module-decorators';
import LoadableState from '@/store/states/LoadableState';
import CategoryDomainContextRepository from '@/repositories/CategoryDomainContextRepository';
import CategoryDomainContext from '@/models/graphql/CategoryDomainContext';
import { buildQueryDefinition } from '@/graphql/_Tools/GqlQueryDefinition';
import GqlEntityFilterType from '@/utils/enums/gql/GqlEntityFilterType';
import LoadableStore from '@/store/LoadableStore';

interface CategoryDomainContextState extends LoadableState {
  contexts: CategoryDomainContext[];
}

@Module({ namespaced: true })
export default class CategoryDomainContextStore extends LoadableStore<CategoryDomainContextState> {
  contexts: CategoryDomainContext[] = [];

  private readonly repository = new CategoryDomainContextRepository();

  get fetchContexts(): CategoryDomainContext[] {
    return this.contexts;
  }

  @Action
  loadDomainsByMultipleContexts(contexts?: string[]): Promise<CategoryDomainContext[]> {
    this.context.commit('load', true);
    const community = this.context.rootGetters.communityCode;
    if (contexts) {
      return this.repository.filter({
        definition: buildQueryDefinition({
          cacheable: true,
          filter: {
            type: GqlEntityFilterType.CATEGORY_DOMAIN_CONTEXT_FILTER,
            value: {
              // eslint-disable-next-line @typescript-eslint/camelcase
              context_in: contexts,
              schemaCode: community,
            },
          },
        }),
        fragmentName: 'categoryDomainContextBaseFragment',
      })
        .then((categoryDomainContexts) => {
          if (categoryDomainContexts) {
            this.context.commit(
              'setElements',
              categoryDomainContexts,
            );
          }
          this.context.commit('load', false);
          return categoryDomainContexts;
        });
    }
    this.context.commit('load', false);
    return Promise.resolve([]);
  }

  @Action
  loadDomains(filter?: object): Promise<CategoryDomainContext[]> {
    this.context.commit('load', true);
    const community = this.context.rootGetters.communityCode;
    if (filter) {
      return this.repository.filter({
        definition: buildQueryDefinition({
          cacheable: true,
          filter: {
            type: GqlEntityFilterType.CATEGORY_DOMAIN_CONTEXT_FILTER,
            value: {
              ...filter,
              schemaCode: community,
            },
          },
        }),
        fragmentName: 'categoryDomainContextBaseFragment',
      })
        .then((categoryDomainContexts) => {
          if (categoryDomainContexts) {
            this.context.commit(
              'setElements',
              categoryDomainContexts,
            );
          }
          this.context.commit('load', false);
          return categoryDomainContexts;
        });
    }
    this.context.commit('load', false);
    return Promise.resolve([]);
  }

  @Mutation
  setElements(contexts: CategoryDomainContext[]): void {
    this.contexts = contexts;
  }
}
