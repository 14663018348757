type strProcessorFn = (
  ((t: string) => string) | ((t: string[]) => string[]) | ((t: string[]) => string) | ((t: string) => string[])
)[];

type strProcesorResult = (target: string | string[]) => string | string[];

export const capitalize = (word: string): string => `${word.slice(0, 1).toUpperCase()}${word.slice(1).toLowerCase()}`;

export const snakeCaseToPascalCase = (input: string): string => input
  .split('_')
  .reduce((res, word) => `${res}${capitalize(word)}`, '');

export const kebabToCamel = (str: string): string => (str.includes('-')
  ? str.replace(/-([a-z])/g, (match, group1) => group1.toUpperCase())
  : str);

export const strMap = (fn: (s: string) => string) => (list: string[]) => list.map(fn);

export const strSplit = (pattern: string | RegExp, limit?: number) => (str: string) => str.split(pattern, limit);

export const strJoin = (separator?: string) => (str: string[]) => str.join(separator);

export const strTrim = (str: string): string => str.trim();

export const strReplace = (pattern: string | RegExp, replacement: string) => (str: string) => str.replace(pattern, replacement);

/**
 * Transforms the target string by executing the processor functions in order from left to right
 * @param processors functions to process the input string
 * @returns curried function (target: string | sting[]) => string | string[]
 */
export const strTransform = (...processors: strProcessorFn): strProcesorResult => (
  (target: string | string[]): string | string[] => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let result: any = target;
    processors.forEach((processor) => {
      result = processor(result);
    });

    return result;
  });

/**
 * Convert string from kebab_Case, PascalCase, camelCase and snake-case to words separated by space
 * @param input string
 * @returns string
*/
export const convertToWords = (input: string): string => strTransform(
  strReplace(/[_-]/g, ' '),
  strReplace(/(^|[a-z])([A-Z]{1,})([a-z]|$)/g, '$1 $2$3'),
  strReplace(/(\.*)([0-9]{1,})(\.*)/g, '$1 $2 $3'),
  strReplace(/ {2,}/, ' '),
)(input) as string;

/**
 * Convert string from kebab_Case, PascalCase, camelCase and snake-case to words separated by space and capitalized each word
 * @param input string
 * @returns string
*/
export const formatStringToWords = (input: string): string => (
  strTransform(convertToWords, strSplit(/ {1,}/), strMap(capitalize), strJoin(' '), strTrim)(input) as string
);
